import React, { Component, useEffect, useState, useRef } from 'react';
import { default as ReactSelect } from "react-select";
import {
    Container, Row, Col, Button, Form, Label, Input, FormGroup, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardColumns,
    Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, Pagination, PaginationItem, PaginationLink, UncontrolledCollapse
} from 'reactstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Apiurl, CandidateId, CandidateIdGet, GetPortalTitle, GetToken, RemoveUserSession, XApiKey, SetLocalStorage, GetLocalStorage, RemoveLocalStorage } from '../../utils/utils';
import axios from 'axios';
import moment from 'moment';
import Loader from '../../components/Loader/loader';
import Option from '../../components/Option';

const Internships = () => {
    const selectLocationInputRef = useRef();
    const selectStakeholderInputRef = useRef();

    const [loading, setLoading] = useState(false);
    const [internships, setInternships] = useState([]);
    const [internshipsLength, setInternshipsLength] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [sortBy, setSortBy] = useState("");
    const [keyword, setKeyword] = useState('');
    const [perPage, setPerPage] = useState(20);
    const [showingFrom, setShowingFrom] = useState(1);
    const [showingTo, setShowingTo] = useState(perPage);
    const [datePosted, setDatePosted] = useState("");
    const [allFilterData, setAllFilterData] = useState([])
    const [searchLocation, setSearchLocation] = useState();
    const [filterLocation, setFilterLocation] = useState();
    const [filterLocationArray, setFilterLocationArray] = useState([]);

    const [searchStakeholders, setSearchStakeholders] = useState();

    const [pageNumber, setPageNumber] = useState(1);
    const dropdownLocation = allFilterData.filter(item => item?.type === "Country")
    const dropdownDatePosted = allFilterData.filter(item => item?.type === "Created")
    const dropdownWorkplace = allFilterData.filter(item => item?.type === "Workplaces")
    const dropdownStakeholders = allFilterData.filter(item => item?.type === "Stakeholders")
    const [pageArray, setPageArray] = useState([]);
    const [perpageArray, setPerpageArray] = useState([]);
    const [reloadList, setReloadList] = useState(false);
    const [searchtext, setSearchText] = useState();
    const filterData = JSON.parse(GetLocalStorage("InternShipfilters"));
    const goToTop = () => {
        window.scrollTo(0, 0);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            getInternshipsByFilter();
        }
    }

    const getallFilterData = () => {
        setLoading(true);
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/internship/getsearchoptions`
        };
        axios(option)
            .then(e => {
                setAllFilterData(e?.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                if (err?.response?.status === 401) {
                }
            });
    }

    useEffect(() => {
        document.title = `${GetPortalTitle('Internships')}`;
        getallFilterData();
        goToTop();
        RemoveLocalStorage("filters");
        RemoveLocalStorage("Coursesfilters");
    }, [])

    const onError = (e) => {
        e.target.src = 'assets/images/logos/dummy-logo.webp'
    }

    //filter function starts from here
    //filter by work place
    const [workplaceArray, setWorkplaceArray] = useState([]);
    const [workplaceValues, setWorkplaceValues] = useState("");
    const filterWorkplaces = (e) => {
        if (e?.target?.checked) {
            setWorkplaceArray([...workplaceArray, e.target.value])
        } else {
            const data = workplaceArray?.filter((item) => item != e?.target?.value)
            setWorkplaceArray(data);
        };
    }
    useEffect(() => {
        const data = workplaceArray?.map((item) => `${item}`).join(', ');
        setWorkplaceValues(data)
    }, [workplaceArray])

    //filter by stakeholders
    const [stakeholdersArray, setStakeholdersArray] = useState([]);
    const [stakeholdersValues, setStakeholdersValues] = useState("");
    useEffect(() => {
        const data = stakeholdersArray?.map((item) => `${item.value}`).join(', ');
        setStakeholdersValues(data)
    }, [stakeholdersArray])

    const getInternshipsByFilter = () => {
        setLoading(true);
        const data = {}
        setSearchLocation('');
        setSearchStakeholders('');
        setSearchText('');

        data.pageno = pageNumber;
        if (perPage) { data.perpage = perPage }
        if (sortBy) { data.sortby = sortBy }
        if (filterData?.searchtext || keyword) {
            data.text = filterData?.searchtext || keyword;
            setSearchText(filterData?.searchtext || keyword);
        }
        if (filterData?.searchLocation?.length || filterLocation) {
            data.country = filterData?.searchLocation?.map((item) => `${item.value}`).join(', ') || filterLocation
            setSearchLocation(filterData?.searchLocation?.map((item) => `${item.value}`).join(', ') || filterLocation);
        }
        if (workplaceValues) { data.workplaces = workplaceValues }

        if (filterData?.searchStakeholders?.length || stakeholdersValues) {
            data.stakeholders = filterData?.searchStakeholders?.map((item) => `${item.value}`).join(', ') || stakeholdersValues
            setSearchStakeholders(filterData?.searchStakeholders?.map((item) => `${item.value}`).join(', ') || stakeholdersValues);
        }
        if (datePosted) { data.dateposted = datePosted }

        const candidateId = CandidateIdGet();
        if (candidateId) { data.candidateId = candidateId }
       
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            data: JSON.stringify(data),
            url: `${Apiurl()}/internship/list`,
           
        }
        
        axios(option)
            .then((e) => {
                if (e?.status === 200) {                   
                    if (e?.data?.length > 0) {
                        setInternshipsLength(e?.data[0]?.totalItems);
                        setTotalPages(e?.data[0]?.totalPages)
                        setInternships(e?.data);
                                              
                    } else {
                        setInternships([]);
                        setInternshipsLength(0);
                        setTotalPages(0);
                    }
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    const handleLocationChange = (selected, event) => {
        if (event.action === 'deselect-option' && event.option.groupItem === '0') {
            selected = selected.filter(x => x.groupItem != event.option.value);
        }
        if (event.action === 'deselect-option' && event.option.groupItem !== '0') {
            selected = selected.filter(x => x.value != event.option.groupItem);
        }
        else if (event.action === 'select-option' && event.option.groupItem === '0') {
            selected = selected.concat(dropdownLocation.filter(x => x.groupItem === event.option.value));
        }
        else if (event.action === 'select-option' && event.option.groupItem !== '0') {
            var selectedcount = selected.filter(x => x.groupItem === event.option.groupItem).length;
            var defaultcount = dropdownLocation.filter(x => x.groupItem === event.option.groupItem).length;
            if (selectedcount === defaultcount) {
                selected = selected.concat(dropdownLocation.filter(x => x.value === event.option.groupItem));
            }
        }

        const result = [];
        const map = new Map();
        for (const item of selected) {
            if (!map.has(item.value)) {
                map.set(item.value, true);    // set any value to Map
                result.push(item);
            }
        }
        setFilterLocationArray(result);
    };

    const handleStakeholderChange = (selected) => {
        setStakeholdersArray(selected);
    };

    useEffect(() => {
        const data = filterLocationArray?.map((item) => `${item.value}`).join(', ');
        setFilterLocation(data)
    }, [filterLocationArray])

    const pagination = () => {
        let arr = [];
        for (let i = 1; i <= totalPages; i++) {
            arr.push(i);
        }
        setPageArray(arr)
    }
    useEffect(() => {
        pagination();
    }, [totalPages])

    const getperpage = () => {
        let arr = [];
        if (internshipsLength <= 10) { return }
        else {
            let len = Math.ceil(internshipsLength / 10);
            for (let i = 1; i <= len; i++) {
                arr.push(i);
            }
            setPerpageArray(arr)
            //setPerPage(20)
        }
    }
    useEffect(() => {
        getperpage();
    }, [internshipsLength])

    const perPageChange = (e) => {
        setPerPage(Number(e.target.value));
        setPageNumber(1)
        setShowingFrom(1);
        setShowingTo(Number(e.target.value))
        setPerPage(Number(e.target.value));
    }

    const changePage = (e) => {
        if (e <= 0) { return }
        if (e > pageArray?.length) { return }
        setPageNumber(e);
        setShowingFrom((perPage * e) - (perPage - 1));
        setShowingTo((perPage * e))
        if (pageNumber != e) { goToTop() }
    }

    const resetFilters = (key) => {

        if (key === 'keyword') {
            setKeyword('');
            setSearchText('');
            const data = {
                searchtext:"",
                searchLocation: filterLocationArray,
                searchStakeholders: stakeholdersArray,
            }
            SetLocalStorage("InternShipfilters", JSON.stringify(data))
        }

    };

    useEffect(() => {
        getInternshipsByFilter();
    }, [searchtext, reloadList, workplaceValues, datePosted, pageNumber, perPage, sortBy
    ])

    const onFindInternShips = () => {
        const data = {
            searchtext: keyword?.trim(),
            searchLocation: filterLocationArray,
            searchStakeholders: stakeholdersArray,
        }
        SetLocalStorage("InternShipfilters", JSON.stringify(data))
    }
    useEffect(() => {
        if (filterData) {
            const localsearchText = filterData?.searchtext;
            if (localsearchText) {
                setKeyword(localsearchText);
            }
            const localsearchLocation = filterData?.searchLocation;
            if (localsearchLocation?.length) {
                setFilterLocationArray(localsearchLocation);
            }
            const localsearchStakeholders = filterData?.searchStakeholders;
            if (localsearchStakeholders?.length) {
                setStakeholdersArray(localsearchStakeholders);
            }
        }
    }, [])
    return (
        <div>
            {
                loading ? <Loader /> : null
            }

            <Header setReloadList={setReloadList} loading={loading} />

            <div>
                <div id="jobSearchWidget" className="alternative-section">
                    <Container>
                        <Row>
                            <Col className="col-sm-12">
                                <Form className="job-search">
                                    <Row className="row g-4 align-items-center row">
                                        <Col className="field-wrapper">
                                            <span className="search-field-icon">
                                                <Input value={keyword}
                                                    onChange={(e) => setKeyword(e?.target?.value)}
                                                    id="internshipSearchField"
                                                    name="search"
                                                    onKeyDown={(e) => handleKeyDown(e)}
                                                    placeholder="Internship Title, Keywords..." type="text" />
                                            </span>
                                        </Col>
                                        <Col className="field-wrapper col-md-1">
                                            {
                                                searchtext ?
                                                    <a id="filter-keyword" className="search-close" onClick={() => { resetFilters('keyword'); }}>
                                                        <span>x</span></a>
                                                    : null
                                            }
                                        </Col>
                                        <Col className="field-wrapper internColwidth">
                                            <ReactSelect
                                                ref={selectLocationInputRef}
                                                instanceId="300"
                                                options={dropdownLocation}
                                                isMulti
                                                isSearchable
                                                isClearable
                                                hideSelectedOptions={false}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                placeholder={'Location'}
                                                controlShouldRenderValue={false}
                                                components={{
                                                    Option
                                                }}
                                                className={searchLocation != '' ? 'selected-select-control' : ''}
                                                onChange={handleLocationChange}
                                                allowSelectAll={true}
                                                defaultValue={filterLocationArray}
                                                value={filterLocationArray}
                                            />
                                        </Col>
                                        <Col className="field-wrapper internColwidth">
                                            <ReactSelect
                                                ref={selectStakeholderInputRef}
                                                instanceId="550"
                                                options={dropdownStakeholders}
                                                isMulti
                                                isSearchable
                                                isClearable
                                                hideSelectedOptions={false}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                placeholder={'Stakeholder'}
                                                controlShouldRenderValue={false}
                                                components={{
                                                    Option
                                                }}
                                                className={searchStakeholders != '' ? 'selected-select-control' : ''}
                                                onChange={handleStakeholderChange}
                                                allowSelectAll={true}
                                                value={stakeholdersArray}
                                            />
                                        </Col>
                                        <Col className="field-wrapper internColwidth col-md-3 d-flex justify-content-md-end align-middle col">
                                            <Button onClick={() => { getInternshipsByFilter(); onFindInternShips() } } className="find-jobs-btn1" title="Find Internships"> Find Internships </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="jobsFilter" className="jobsFilter">
                    <Container>
                        <Row>
                            <Col className="col-12 col-md-7 order-md-first order-last showing-results">
                                {loading ? '' : <p>Showing {internshipsLength === 0 ? 0 : showingFrom} – {showingTo < internshipsLength ? showingTo : internshipsLength} of {internshipsLength} internships</p>}
                            </Col>
                            <Col className="col-12 col-md-5 select justify-content-end">
                                <FormGroup>
                                    <Input id="jobSort" className="jobSort" name="jobsort" type="select" value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                                        <option disabled>Sort by (Default)</option>
                                        <option value="created_desc">Newest</option>
                                        <option value="created_asc">Oldest</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Input value={perPage} onChange={(e) => perPageChange(e)} id="perPage" className="noOfPage perpage-resp ms-4" name="perPage" type="select">
                                        <option value="10">10 per page</option>
                                        <option value="20">20 per page</option>
                                        <option value="30">30 per page</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="jobsList1">
                    <Container>
                        <Row>
                            <Col className="col-12 col-md-3">
                                <div className="sidebar advanced-search">
                                    <div className="date-section pb-4 ps-2 border0">
                                        <h4>Date Posted</h4>
                                        {
                                            dropdownDatePosted?.map((item, i) => (
                                                <FormGroup check key={i}>
                                                    <Label check>
                                                        <input id="Date-posted" value={datePosted} onChange={(e) => setDatePosted(item?.value)} type="radio" name="date" className="date-radio" defaultChecked={item?.value === "All"} />                                                    {item?.text}</Label>
                                                </FormGroup>
                                            ))
                                        }
                                    </div>
                                    <div className="salary-offer">
                                        <h4 className="expand-title" id="workplace">Workplace</h4>
                                        <UncontrolledCollapse className="salary-section" toggler="#workplace">
                                            {
                                                dropdownWorkplace?.map((item, i) => (
                                                    <FormGroup check className="box-checked" key={i}>
                                                        <Label check className="label-input-checker">
                                                            <span className="filter-check-label"><Input type="checkbox" onChange={(e) => filterWorkplaces(e)} value={item?.value} />{item?.text}</span>
                                                            <span>({item?.count})</span></Label>
                                                    </FormGroup>
                                                ))
                                            }
                                        </UncontrolledCollapse>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-12 col-md-9">
                                <CardColumns>
                                    {internships?.map((item, i) => (
                                        <NavLink to={item?.url} className="cardDiv" key={item?.id} >

                                            <Card className={(item?.applied === true) ? "background-grey" : "even"}>
                                                <CardBody title={item?.title}>
                                                    {
                                                        item?.company?.imageUrl ? <CardImg alt="Card image cap" title={item?.company?.shortName} src={item?.company?.imageUrl || "assets/images/logos/dummy-logo.webp"} onError={(e) => onError(e)} width="145" /> :
                                                            <span className="image-initials-popular jobs-img-initials"><span title={item?.company?.shortName}>{item?.company?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{item?.company?.shortName?.split(' ').length > 1 ? item?.company?.shortName?.split(' ')[item?.company?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</span></span>
                                                    }
                                                    <CardTitle tag="h3">{item?.title}</CardTitle>
                                                    <div className="card-text">
                                                        <div className="icons listing"><i className="fa-solid fa-location-crosshairs"></i><strong>Location: </strong>{item?.countryDescription}, {item?.cityText}</div>
                                                        <div className=""><i className="fa-regular fa-chair-office"></i><strong className="strong-text">Workplace: </strong>{item?.workPlaceDescription}</div>
                                                        <div className="icons job-date-added listing"><i className="fa-solid fa-clock"></i><strong>Posted: </strong>{moment(item?.created).format("DD MMM YYYY")}</div><br />
                                                        <div className="fa-light fa-users paddingleft5"></div><strong className="strong-text">No of Placements Available: </strong>{item?.noOfPlacements}
                                                        <div className="job-desc">
                                                            <p>{item?.natureOfWork}</p>
                                                        </div>
                                                        {item?.applied === true ? <div className="job-desc">
                                                            <p>You applied for this internship on {moment(item?.internshipApplicationCreated).format("DD MMM YYYY")}</p></div> : null}
                                                        
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </NavLink>
                                    ))}
                                    
                                </CardColumns>
                                <div className="outerdiv">
                                    {
                                        internshipsLength == 0 ?
                                            (!loading ? <div className="nodata">There are no internships matching the search filters</div> : "")
                                            : null
                                    }
                                    {
                                        internshipsLength > 0 ? (<p>Showing {internshipsLength === 0 ? 0 : showingFrom} – {showingTo < internshipsLength ? showingTo : internshipsLength} of {internshipsLength} internships</p>)
                                            : ""
                                    }
                                    {
                                        pageArray?.length ?
                                            <Pagination className="mt-2 mb-5">
                                                <PaginationItem>
                                                    <PaginationLink className={pageNumber === 1 ? "next-prev disabled" : "next-prev"} onClick={() => changePage(pageNumber - 1)}>
                                                        <span className="fa fa-angle-left"></span> Previous
                                                    </PaginationLink>
                                                </PaginationItem>
                                                {
                                                    pageArray?.map((item, i) => (
                                                        <PaginationItem key={i}>
                                                            <PaginationLink className={pageNumber === item ? "active" : ""} onClick={() => changePage(item)}>{item}</PaginationLink>
                                                        </PaginationItem>
                                                    ))
                                                }
                                                <PaginationItem>
                                                    <PaginationLink className={pageNumber === totalPages ? "next-prev disabled":"next-prev"} onClick={() => changePage(pageNumber + 1)} >
                                                            Next <span className="fa fa-angle-right"></span>
                                                        </PaginationLink> 
                                                    </PaginationItem> 
                                                
                                        </Pagination>
                                        : null
                                    }
                              
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer loading={loading} />
        </div>
    );
}

export default Internships;
