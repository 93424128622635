
import React, { useEffect, useState } from 'react'
import "./Header.css"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../../../src/img/jobs-portal-logo.jpg";
import { NavbarBrand } from 'reactstrap';
import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Modal, Row, Col, DropdownButton } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import ResetPasswordImage from '../../../src/img/correct.png';
import { toast } from 'react-toastify';
import { Apiurl, CandidateId, CandidateIdGet, GetToken, loginValidates, getUserEmail, RemoveUserSession, SetToken, userEmail, ValidateResetLink, XApiKey, RemoveLocalStorage } from '../../utils/utils';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import CustomButton from '../Button/button';
import Loader from '../Loader/loader';

const Header = ({ setReloadList, setApplyBtn, loginIsApply, setLoginIsApply, applyDetails, jobDetail, jobData, loading, applyModal, profilePage, setProfilePage, candidateProfile, InternData, internurl, courseData }) => {
    const [formState, setFormState] = useState({
        loginEmail: '',
        loginPassword: '',
        forgotPasswordEmail: '',
    })
    const [passwordVisible, setPasswordVisile] = useState(false);
    const [headerModals, setHeaderModals] = useState(false);
    //login states
    const [loginModal, setLoginModal] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const redirecturl = searchParams.get("redirecturl")
    const profiletab = searchParams.get("tab")

    // forgot password states
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
    const closeForgotPassword = () => {
        setForgotPasswordModal(false);
        setFormState({
            forgotPasswordEmail: ""
        })
    }

    const showForgotPassword = () => showForgotPassword(true);

    //resend email states
    const [resendEmail, setResendEmail] = useState('');
    const closeResendEmail = () => setResendEmail(false);
    const showResendEmail = () => setResendEmail(true);
    const Emailvalidate = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const [forgotCaptcha, setForgotCaptcha] = useState();
    const navigate = useNavigate();
    const popup = useLocation();
    const Candidateid = CandidateIdGet();
    const Token = GetToken();
    const [showModal, setShowModal] = useState(popup?.state?.showloginModal);
    const [pwlink, setPwlink] = useState(false);
    const ClosePwLink = () => setPwlink(false);
    const ShowPwLink = () => setPwlink(true);
    const [activate, setActivate] = useState()
    const [activateIntern, setActivateIntern] = useState()
    const [activateCourse, setActivateCourse] = useState()
    const [load, setLoad] = useState(false);

    const closeLoginModal = () => {
        if (redirecturl != null) {
            navigate('/');
        }

        if (loginIsApply) {
            setLoginIsApply(false);
        } else if (profilePage) {
            setProfilePage(false)
            navigate('/')
            setLoginModal(true);
        } else {
            setLoginModal(false);
        }

        setFormState({
            loginEmail: '',
            loginPassword: ''
        })
    }
    const showLoginModal = () => {
        setLoginModal(true);
        setPasswordVisile(false);
    }

    useEffect(() => {
        if (showModal || (Candidateid < 1 && redirecturl != null)) {
            showLoginModal();
        }
        else if (Candidateid > 0 && redirecturl != null) {
            navigate(redirecturl);
        }
    }, [])

    const onChange = (event) => {
        const name = event?.target?.name;
        const value = event?.target?.value;
        let localState = { ...formState }
        localState[name] = value
        setFormState({ ...localState })
    }

    const GetJobByUrl = () => {
        setLoad(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/jobpost/getbyurl?url=${jobDetail}&CandidateId=${Candidateid}`,
        }
        axios(option)
            .then((e) => {
                if (e?.status === 200) {
                    if (e?.data?.applied) {
                        setApplyBtn(false)
                        toast.success("You have already applied for this job");
                        setTimeout(() => {
                            setLoad(false)
                        }, 1000)
                    } else {
                        navigate(`/application/${jobDetail?.split('/')?.[2]}`)
                    }
                }
            })
            .catch((err) => {
                setLoad(false)
                navigate('404')
            })
    }

    const GetCourseByUrl = () => {
        setLoad(true);
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/course/getbyurl?url=${courseData?.url}&CandidateId=${Candidateid}`,
        }
        axios(option)
            .then((e) => {
                setLoad(false)
                if (e?.status === 200) {
                    if (e?.data?.applied) {
                        setApplyBtn(false)
                        toast.success("You have already applied for this course");
                        setTimeout(() => {
                            setLoad(false)
                        }, 1000)
                    } else {
                        navigate(`/courseapplication/${courseData?.url.split('/')?.[2]}`)
                    }
                }
                if (e?.status === 204) {
                    navigate('404')
                }
            })
            .catch((err) => {
                setLoad(false);
                navigate('404')
            })
    }

    const GetInternByUrl = () => {
        setLoad(true);
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/Internship/getbyurl?url=/Internship${internurl}&CandidateId=${Candidateid}`,
        }
        axios(option)
            .then((e) => {
                if (e?.status === 200) {
                    if (e?.data?.applied) {
                        setApplyBtn(false)
                        toast.success("You have already applied for this Internship");
                        setTimeout(() => {
                            setLoad(false)
                        }, 1000)
                    } else {
                        navigate(`/internshipapplication${internurl}`)
                    }
                }
                setLoad(false)
                if (e?.status === 204) {
                    navigate('404')
                }
            })
            .catch((err) => {
                setLoad(false);
                navigate('404')
            })
    }

    const LoginSubmit = () => {
        if (!loginValidates(formState, Emailvalidate)) return;
        //setLoad(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/candidate/login?email=${formState?.loginEmail}&password=${formState?.loginPassword}`
        };
        axios(option)
            .then((e) => {
                if (e?.data?.statusCode === 200) {
                    toast.success("Log in successfull");
                    SetToken(e?.data?.token);
                    CandidateId(e?.data?.id);
                    closeLoginModal();
                    RemoveLocalStorage("filters");
                    RemoveLocalStorage("InternShipfilters");
                    RemoveLocalStorage("Coursesfilters");
                    if (applyDetails === "yes") {
                        setActivate(true);
                    } else if (applyDetails === "intern") {
                        setActivateIntern(true)
                    } else if (applyDetails === "Course") {
                        setActivateCourse(true)
                    } else if (candidateProfile === "yes") {
                        navigate("/candidateprofile")
                    } else if (candidateProfile === "editPro") {
                        navigate("/profile", { state: { tab: "GeneralInfo" } })
                    } else {
                        if (redirecturl === 'profile') {
                            navigate("/profile", { state: { tab: profiletab } })
                        }
                        else if (window.location.pathname.toLowerCase() == '/jobs' || window.location.pathname.toLowerCase() == '/internships' || window.location.pathname.toLowerCase() == '/courses') {
                            setReloadList(true)
                        }
                        else if (window.location.pathname.toLowerCase() == '/signupsuccess' || window.location.pathname.toLowerCase() == '/emailvalidate') {
                            localStorage.removeItem('successemail');
                            navigate("/")
                        }
                        else {
                            navigate(redirecturl);
                        }
                    }
                    //setLoad(false)
                }
                if (e?.data?.statusCode === 500) {
                    if (e?.data?.errorMessage === "The e-mail address or password is incorrect") {
                        toast.error(e?.data?.errorMessage);
                    }
                    else if (e?.data?.errorMessage === "Please validate your email address before Login") {
                        toast.error('You need to verify your account email address before you can login. Please check your inbox and follow the email instructions to verify your email address.');
                    }
                    else {
                        ShowPwLink();
                        closeLoginModal();
                    }
                    //setLoad(false)
                }
            })
    }

    //candidate logout
    const LogoutSubmit = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${GetToken()}`
            },
            url: `${Apiurl()}/candidate/logout?token=${GetToken()}`
        };
        axios(option)
            .then((e) => {
                if (e?.data?.statusCode === 200) {
                    toast.success(e?.data?.successMessage)
                    RemoveUserSession();
                    RemoveLocalStorage("filters");
                    RemoveLocalStorage("InternShipfilters");
                    RemoveLocalStorage("Coursesfilters");
                    navigate("/");
                }
                if (e?.data?.statusCode === 500) {
                    toast.error(e?.data?.errorMessage);
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    RemoveUserSession();
                    navigate('/');
                }
            })
    }

    //reset link
    const ResetLinkSend = () => {
        if (!ValidateResetLink(formState, Emailvalidate, forgotCaptcha)) return;
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/candidate/issuepassword?email=${formState?.forgotPasswordEmail}`
        };
        axios(option)
            .then((e) => {
                if (e?.data?.statusCode === 200) {
                    userEmail(formState?.forgotPasswordEmail);
                    closeForgotPassword();
                    showResendEmail();
                }
                if (e?.data?.statusCode === 500) {
                    toast.error(e?.data?.errorMessage)
                }
            })
            .catch((err) => {
            })

    }

    //resend mail
    const ResendEmail = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/candidate/issuepassword?email=${getUserEmail()}`
        };
        axios(option)
            .then((e) => {
                if (e?.data?.statusCode === 200) {
                    toast.success("reset link sent to your email please check your email");
                }
            })
            .catch(() => {

            })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            LoginSubmit();
        }
    }

    const forgotPwLink = () => {
        navigate("/");
        setForgotPasswordModal(true);
        setPwlink(false);
    }

    useEffect(() => {
        if (load === true || applyModal === true || loading === true || loginModal === true || loginIsApply === true || profilePage === true || forgotPasswordModal === true || resendEmail === true || pwlink === true) {
            setHeaderModals(true);
        } else {
            setHeaderModals(false);
        }
    }, [load || loading, loginModal, loginIsApply, profilePage, forgotPasswordModal, resendEmail, pwlink, applyModal])

    useEffect(() => {
        if (activate) { GetJobByUrl() }
    }, [activate])


    useEffect(() => {
        if (activateIntern) {
            GetInternByUrl();
        }
    }, [activateIntern])

    useEffect(() => {
        if (activateCourse) {
            GetCourseByUrl();
        }
    }, [activateCourse])

    const navigatetoProfile = () => {
        navigate("/candidateprofile", { state: "application" })
    }

    const navigateToSignup = () => {
        if (applyDetails === "yes") {
            navigate("/signup", { state: { applyFrom: "jobs", jobDetail: jobDetail?.split('/')?.[2], jobData: jobData } });
        } else if (applyDetails === "intern") {
            navigate("/signup", { state: { applyFrom: "intern", internDetail: internurl, internData: InternData } });
        } else if (applyDetails === "Course") {
            navigate("/signup", { state: { applyFrom: "course", courseDetail: courseData?.url.split('/')?.[1], courseData: courseData } });
        } else {
            navigate("/signup");
        }
    }

    return (
        <>
            {
                load ? <Loader /> : null
            }
            <Navbar className={headerModals ? "header-navbar-modal" : "header-navbar"} expand="lg">
                <Container className='header-container'>
                    <NavbarBrand tag={Link} to="/"><img src={logo} className="img-fluid logo" alt="MRN Logo" title="Job Portal" /></NavbarBrand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/jobs" className='header-link' title="Jobs">Jobs</Nav.Link>
                            <Nav.Link as={Link} to="/internships" className='header-link' title="Internships">Internships</Nav.Link>
                            <Nav.Link as={Link} to="/courses" className='header-link' title="Courses">Courses</Nav.Link>
                            <div className='header-buttons' title="Candidate">
                                {
                                    Candidateid && Token ?
                                        <DropdownButton
                                            variant="outline-secondary"
                                            title="Candidate"
                                            id="input-group-dropdown-1"
                                            className="dropdown-profile"
                                        >
                                            <div> <NavLink className='header-profile-inactive' to="/candidateprofile" state="1" title="Profile">Profile</NavLink></div>
                                            <div> <a className='header-profile-inactive' onClick={() => navigatetoProfile()} title="Job Applications">Job Applications</a></div>
                                            <div> <a className="header-profile-inactive" onClick={() => LogoutSubmit()} title="Sign Out">Sign Out</a></div>
                                        </DropdownButton>
                                        : <>
                                            <Nav.Link href="#" className='signin-button' onClick={showLoginModal} title="Sign In">Sign In</Nav.Link>
                                            <Nav.Link className='signup-button' onClick={() => navigate('/signup')} title="Sign Up">Sign Up</Nav.Link>
                                        </>
                                }

                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* login modal start */}
            <Modal show={loginModal || loginIsApply || profilePage} onHide={closeLoginModal} className="login-modal " backdrop="static">
                <Modal.Body className="login-modal-body" >
                    <button onClick={() => { closeLoginModal() }} className="btn-close close-modal" aria-label="Close"></button>
                    <div className="login-modal-header login-modal-card-main-section">
                        <div className="login-card-section">
                            <Row>
                                <Col md={12} className="left-section-login">
                                    <div className="card-login card-login-50">
                                        <h1>Login</h1>
                                        <div className="inputs-main-section">
                                            <label className='loginLabels'>
                                                Your email
                                            </label>
                                            <input className='loginInputs' label="Your email" placeholder="email@address.com" type="email"
                                                onChange={onChange}
                                                value={formState?.loginEmail}
                                                name="loginEmail"
                                                onKeyDown={(e) => handleKeyDown(e)}
                                            />
                                        </div>

                                        <div className="inputs-main-section password">
                                            <label className='loginLabels'>
                                                Password
                                            </label>
                                            <input className='loginInputs' label="Password" placeholder="8+ characters required" name="loginPassword"
                                                type={passwordVisible ? 'text' : 'password'}
                                                onChange={onChange}
                                                value={formState?.loginPassword}
                                                onKeyDown={(e) => handleKeyDown(e)}

                                            />
                                            {passwordVisible ?
                                                <AiOutlineEye className="icon-password-visible" onClick={() => setPasswordVisile(false)} /> :
                                                <AiOutlineEyeInvisible className="icon-password-visible" onClick={() => setPasswordVisile(true)} />
                                            }
                                        </div>

                                        <div className="forgot-password-section">
                                            <span className="forget_pass">
                                                <a onClick={() => { setForgotPasswordModal(true); closeLoginModal(); }} title="Forgot Password">Forgot Password?</a>
                                            </span>
                                        </div>

                                        <div className="submit-button-section">
                                            <CustomButton text="Sign in" Submit={LoginSubmit} />
                                        </div>

                                        <div className="sign-up-link-section">
                                            <h4>Don't have an account yet? <span onClick={navigateToSignup} title="Sign up here">Sign up here</span></h4>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* login modal end */}

            {/* forgot password modal start */}
            <Modal show={forgotPasswordModal} onHide={closeForgotPassword} className="login-modal" backdrop="static">
                <Modal.Body className="login-modal-body">
                    <button onClick={() => { closeForgotPassword() }} className="btn-close close-modal" aria-label="Close"></button>
                    <div className="login-modal-header login-modal-card-main-section">
                        <div className="login-card-section">
                            <Row>
                                <Col md={12} className="left-section-login">
                                    <div className="card-login card-login-50">
                                        <h1>Password Reset</h1>
                                        <hr className="hr-login-heading" />

                                        <div className="forgot-password-heading-inner">
                                            <h3>Enter the email address associated with your account and, we'll email you a link to reset your password.</h3>
                                        </div>
                                        <div className="inputs-main-section forgot-input-change">
                                            <label className='loginLabels'>
                                                Your email
                                            </label>
                                            <input className='loginInputs' label="Your email" placeholder="email@address.com" type="email"
                                                onChange={onChange} value={formState?.forgotPasswordEmail} name="forgotPasswordEmail"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        ResetLinkSend();
                                                    }
                                                }
                                                }
                                            />
                                        </div>

                                        <div className="main-section-captcha">

                                            <ReCAPTCHA
                                                sitekey="6Lf1dtAkAAAAAPZUiH82grk2GYeghAtorDYhYr2V"
                                                onChange={(e) => setForgotCaptcha(e)}
                                            />

                                        </div>

                                        <div className="submit-button-section">
                                            <CustomButton text="Send Reset Link" Submit={ResetLinkSend} />
                                        </div>

                                        <div className="sign-up-link-section">
                                            <h4>Remember Your Password? <span><a className="forgot-login-link" onClick={() => { closeForgotPassword(); setLoginModal(true) }} title="Log in">Log in</a></span></h4>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* forgot password modal end */}

            {/* resend password modal start */}
            <Modal show={resendEmail} onHide={closeResendEmail} className="login-modal" backdrop="static">
                <Modal.Body className="login-modal-body">
                    <button onClick={() => closeResendEmail()} className="btn-close close-modal" aria-label="Close"></button>
                    <div className="login-modal-header login-modal-card-main-section">
                        <div className="login-card-section">
                            <Row>
                                <Col md={12} className="left-section-login">
                                    <div className="card-login card-login-50">
                                        <h1>Check Your Email</h1>
                                        <hr className="hr-login-heading" />
                                        <div className="reset-password-img-section">
                                            <img src={ResetPasswordImage} alt="correct-img" />
                                        </div>
                                        <div className="forgot-password-heading-inner resend-heading">
                                            <h3>Please check your inbox for instructions to reset your password.</h3>
                                        </div>
                                        <div className="submit-button-section">
                                            <CustomButton text="Resend Email" Submit={ResendEmail} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* resend password modal end */}

            {/* reset password modal start */}
            <Modal show={pwlink} onHide={ClosePwLink} className="login-modal" backdrop="static">
                <Modal.Body className="login-modal-body">
                    <button onClick={() => ClosePwLink()} className="btn-close close-modal" aria-label="Close"></button>
                    <div className="login-modal-header login-modal-card-main-section">
                        <div className="login-card-section">
                            <Row>
                                <Col md={12} className="left-section-login">
                                    <div className="card-login card-login-50">
                                        <h1>Login Failed</h1>
                                        <hr className="hr-login-heading" />

                                        <div className="forgot-password-heading-inner">
                                            <h3>
                                                The maximum number of allowable login attempts have been exceeded.
                                                Click <button onClick={() => forgotPwLink()} title="Click here">here</button> to reset your password and login.
                                            </h3>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* reset password modal end */}

        </>
    )
}

export default Header;