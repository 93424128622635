import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./Terms.css";
import { Col, Container, Row } from "react-bootstrap";
import checked from "../../img/checked.svg"
import { GetPortalTitle, RemoveLocalStorage } from "../../utils/utils";

const Terms = () => {

    useEffect(() => {
        document.title = `${GetPortalTitle('Terms & Conditions of Use')}`;
        RemoveLocalStorage("filters");
        RemoveLocalStorage("InternShipfilters");
        RemoveLocalStorage("Coursesfilters");
    }, [])
    return (

        <div>
            <Header />

            <div className="terms-section terms-section-container">
                <Container>

                    <Row className="terms-row">

                        <Col className="col-lg-12">
                            <h2 className="mb-3 pt-4 terms-head">Terms & Conditions of Use</h2>
                        </Col>

                        <Col className="col-lg-12">
                            <p>
                                For the purposes of these Terms & Conditions of Use, this website shall be referred to as the ‘job portal’.
                            </p>
                        </Col>

                    </Row>

                    <Row className="terms-body">

                        <Col className="col-lg-12">

                            <h3 className="terms-img-checked"><img src={checked} alt="1. Terms" title="1. Terms" />&nbsp; 1. Terms</h3>
                            <p className="terms-data">By accessing this website, you are agreeing to be bound by these website Terms & Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable laws. If you do not agree with any of these terms, you are prohibited from using or accessing this website. The materials contained in this website are protected by applicable copyright and trademark laws.</p>

                            <h3 className="terms-img-checked"><img src={checked} alt="2. Use License" title="2. Use License" />&nbsp; 2. Use License</h3>
                            <p className="terms-data">Permission is granted to temporarily access the materials (information or software) on the job portal website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not: modify or copy the materials; use the materials for any commercial purpose, or for any public display (commercial or non-commercial); attempt to decompile or reverse engineer any software contained on the job portal website; remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or "mirror" the materials on any other server. This license shall automatically terminate if you violate any of these restrictions and may be terminated by the job portal at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p>

                            <h3 className="terms-img-checked"><img src={checked} alt="3. Disclaimer" title="3. Disclaimer" />&nbsp; 3. Disclaimer</h3>
                            <p className="terms-data">The materials on the job portal website are provided "as is". The job portal makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, the job portal does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</p>

                            <h3 className="terms-img-checked"><img src={checked} alt="4. Limitations" title="4. Limitations" />&nbsp; 4. Limitations</h3>
                            <p className="terms-data">In no event shall the job portal or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on the job portal website, even if the job portal or an job portal authorised representative has been notified orally or in writing of the possibility of such damage. As some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>

                            <h3 className="terms-img-checked"><img src={checked} alt="5. Revisions and Errata" title="5. Revisions and Errata" />&nbsp; 5. Revisions and Errata</h3>
                            <p className="terms-data">The materials appearing on the job portal website could include technical, typographical, or photographic errors. The job portal does not warrant that any of the materials on its web site are accurate, complete, or current. The job portal may make changes to the materials contained on its website at any time without notice. The job portal does not, however, make any commitment to update the materials.</p>

                            <h3 className="terms-img-checked"><img src={checked} alt="6. Links" title="6. Links" />&nbsp; 6. Links</h3>
                            <p className="terms-data">The job portal has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by the job portal of the website. Use of any such linked website is at the user's own risk.</p>

                            <h3 className="terms-img-checked"><img src={checked} alt="7. Site Terms of Use Modifications" title="7. Site Terms of Use Modifications" />&nbsp; 7. Site Terms of Use Modifications</h3>
                            <p className="terms-data">The job portal may revise these terms of use for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these Terms and Conditions of Use.</p>

                            <h3 className="terms-img-checked"><img src={checked} alt="8. Governing Law" title="8. Governing Law" />&nbsp; 8. Governing Law</h3>
                            <p className="terms-data">Any claim relating to the job portal website shall be governed by the laws of the United Arab Emirates without regard to its conflict of law provisions.</p>

                            <h3 className="terms-img-checked"><img src={checked} alt="Refund & Cancellation Policy" title="Refund & Cancellation Policy" />&nbsp; Refund & Cancellation Policy</h3>
                            <ol className="terms-data">
                                <li>You can cancel your subscription at any time. If you cancel your subscription, your cancellation will become effective at the end of the subscription period.</li>
                                <li>Refunds will not be provided for any subscription. The job portal does not provide credit, refunds, or prorated billing for subscriptions that are cancelled before the end of the subscription period. Where subscription is cancelled early, you will continue to have access to your subscription until the end of the subscription period.</li>
                            </ol>

                        </Col>

                    </Row>
                                        
                </Container>
            </div>

            <Footer />
        </div>

    )
};

export default Terms;