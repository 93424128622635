import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./HomeBanner.css";
import bannerimg from "../../../img/hero-graphic.svg";
import { CiSearch } from "react-icons/ci"
const HomeBanner = () => {
    const navigate = useNavigate();
    const [inputSearch, setInputSearch] = useState('');
    const navigateSearch = () => {
        //if (!inputSearch || inputSearch.trim() === "") return;
        navigate("/jobs", { state: { search: inputSearch } })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            navigateSearch();
        }
    }

    return (
        <div className="banner-section">
        <Container>
            <Row className="home-banner-row">
                <Col md="6" className="home-banner-inner-col">
                        <h1 className="banner-head">Employment and learning portal for the Kurdistan Region's energy sector</h1>
                        <div className="banner-positions mb-5">Search for Jobs, Internship or Training opportunities.</div>
                        <div className="banner-search">
                            <CiSearch className="search-icon-banner"/>
                            <input className="banner-search-input mb-3" type="text" placeholder="Keywords..." onChange={(e) => setInputSearch(e?.target?.value)} value={inputSearch} onKeyDown={(e) => handleKeyDown(e)} />
                        </div>
                        <button className="banner-search-btn" onClick={navigateSearch} title="Search">Search</button>
                </Col>
                    <Col md="6" className="d-flex align-items-center justify-content-center pb-0">
                        <img src={bannerimg} alt="banner" className="w-100" title="Employment and learning portal for the Kurdistan Region's energy sector" />
                </Col>
            </Row>
            </Container>
            </div>
    )
};

export default HomeBanner;