import React from "react";
import "./InformationHome.css";
import { Col, Container, Row } from "react-bootstrap";
import guidance from "../../../img/important-info.svg";
import checked from "../../../img/checked.svg"

const InformationHome = () => {
    return (
        <div className="informationHome-section">
            <Container>
                <Row className="informationHome-row">
                    <Col md={12} className="mt-3">
                        <h2 className="informationHome-head mb-4">Important Information</h2>
                    </Col>
                    <Col md="5">
                        <img src={guidance} alt="banner" title="Important Information" />
                    </Col>
                    <Col md="7" className="ps-0">
                        <li className="informationHome-img-checked"><img src={checked} alt="checked" />
                            <span className="span-information-text">The MNR is not responsible for the hiring of staff in the private sector, nor is it</span> involved in the hiring process.
                        </li>
                        <li className="informationHome-img-checked"><img src={checked} alt="checked" />
                            <span className="span-information-text">The MNR has established requirements for hiring in its Recruitment </span>Instructions, which companies in the energy sector are required to incorporate in their internal processes.
                        </li>
                        <li className="informationHome-img-checked"><img src={checked} alt="checked" />
                            <span className="span-information-text">Employers are responsible for following all applicable laws and regulations, set </span>out by the MNR, Ministry of Labour, or any other authorised Government entity.
                        </li>
                        <li className="informationHome-img-checked"><img src={checked} alt="checked" />
                            <span className="span-information-text">If candidates have reason to believe an employer is non-complaint to any </span>applicable law or regulation, a non-compliance report can be raised by completing this form.
                        </li>
                        <li className="informationHome-img-checked"><img src={checked} alt="checked" />
                            <span className="span-information-text">Candidates should not raise non-compliance reports because of a failed job </span>application. Non-compliance reports should be raised if a clear breach of law or regulation has been observed.
                        </li>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default InformationHome;