import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './Profile.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { BsArrowRightShort, BsArrowLeftShort } from 'react-icons/bs';
import { toast } from 'react-toastify';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import GeneralInfoProfile from '../../components/Profile/GeneralInfo/GeneralInfoProfile';
import CitizenshipInfoProfile from '../../components/Profile/CitizenshipInfoProfile/CitizenshipInfoProfile';
import QualificationInfoProfile from '../../components/Profile/QualificationInfo/QualificationInfoProfile';
import { Apiurl, CandidateIdGet, GetToken, XApiKey, RemoveUserSession, SetProfileTab, GetPortalTitle } from '../../utils/utils';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
const Profile = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const activeTab = location?.state?.tab;
    const applyFrom = location?.state?.applyFrom;
    const jobData = location?.state?.jobData;
    const jobDetail = location?.state?.jobDetail;
    const [profilePage, setProfilePage] = useState(false);
    const internData = location?.state?.internData;
    const internDetail = location?.state?.internDetail;
    const courseData = location?.state?.courseData;
    const courseDetail = location?.state?.courseDetail;

    const [currentTab, setCurrentTab] = useState("GeneralInfo");
    const [candidate, setCandidate] = useState('');
    const [updateBtn, setUpdateBtn] = useState('');
    const [formValues, setformValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        gender: '',
        birthDate: '',
        phoneCode: '',
        phone: '',
        internationalPhoneCode: '',
        interNationalPhone: '',
        nationality: '',
        residentCountry: '',
        iraqiCitizenShip: '',
        kurdistanCitizen: '',
        ethnicity: '',
        birthGoverNate: '',
        birthPlace: '',
        residentAddress: '',
        passportFile: [],
        citizenshipDocument: [],
        passportNumber: '',
        citizenNumber: '',
        seniorityLevel: '',
        jobFamily: '',
        relevantExperience: '',
        highestQualification: '',
        cv: [],
        highestQualdocument: [],
        otherCertificates: [],
        passportFileValue: "",
        NfoosFileValue: "",
    })
    const expatData = !(formValues.nationality === "104" || formValues.nationality === 104) ? "Foreign" : ((formValues.nationality === "104" || formValues.nationality === 104) && (formValues.iraqiCitizenShip === "true" || formValues.iraqiCitizenShip === true)) ? "National" : ((formValues.nationality === "104" || formValues.nationality === 104) && (formValues.iraqiCitizenShip === "false" || formValues.iraqiCitizenShip === false)) ? "Local" : "National";

    const emailRegex = /^[a-zA-Z0-9.!#$%&�*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const mobileRegex = /^\d+$/;
    const candidateId = CandidateIdGet();
    const candidateToken = GetToken()
    useEffect(() => {
        if (!candidateToken) {
            navigate('/?redirecturl=profile&tab=Citizenship')
        }
        localStorage.removeItem("filters");
    }, [])
    const getCandidateData = () => {
        setLoading(true);
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            url: `${Apiurl()}/candidate/get?id=${candidateId}`

        };
        axios(option)
            .then(e => {
                setCandidate(e?.data?.body);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                if (err?.response?.status === 401) {
                    RemoveUserSession();
                    navigate("/");
                }
            });
    }

    useEffect(() => {
        if (candidateId && candidateToken) {
            getCandidateData();
        }

    }, [candidateId, candidateToken])

    useEffect(() => {
        if (candidate?.id) {
            setformValues({
                firstName: candidate?.firstName,
                lastName: candidate?.lastName,
                email: candidate?.email,
                password: candidate?.password,
                gender: candidate?.sex,
                birthDate: candidate?.birthDate,
                phoneCode: candidate?.localPhoneNoCountryId,
                phone: candidate?.localPhoneNo,
                internationalPhoneCode: candidate?.internationalPhoneNoCountryId,
                interNationalPhone: candidate?.internationalPhoneNo,
                nationality: candidate?.nationalityId,
                iraqiCitizenShip: candidate?.hasCitizenship,
                residentCountry: candidate?.residentCountryId,
                residentAddress: candidate?.residentAddress,
                kurdistanCitizen: candidate?.hasCitizenship === false && candidate?.expat === "National" ? "yes" : candidate?.hasCitizenship === false && candidate?.expat === "Local" ? "no" : "",
                passportNumber: candidate?.passportNumber !== null ? candidate?.passportNumber : '',
                citizenNumber: candidate?.citizenshipNo !== null ? candidate?.citizenshipNo : '',
                seniorityLevel: candidate?.levelId,
                jobFamily: candidate?.jobFamily.map(item => item?.jobFamilyId),
                relevantExperience: candidate?.relevantExperience,
                highestQualification: candidate?.highestQualification,
                passportFileValue: candidate?.passport,
                nfoosFileValue: candidate?.nfoos,

            })
        }
    }, [candidate])

    const onChange = (event) => {
        const name = event?.target?.name
        const value = event?.target?.files ? event?.target?.files : event?.target?.value
        let localState = { ...formValues }
        localState[name] = value;
        setformValues({ ...localState })
    }

    const generalInfoValidate = () => {
        if (!formValues?.firstName || formValues?.firstName.trim() === '') {
            toast.error("Please Enter First Name")
            return false;
        }
        if (!formValues?.lastName || formValues?.lastName.trim() === '') {
            toast.error("Please Enter Last Name")
            return false;
        }
        if (!emailRegex.test(formValues?.email)) {
            toast.error("Please Enter Valid Email Address")
            return false;
        }
        if (formValues?.phone?.length) {
            if (!formValues?.phoneCode) {
                toast.error("Please select Local Phone Country Code")
                return false;
            }
            if (!mobileRegex.test(formValues?.phone)) {
                toast.error("Please enter valid Local Phone")
                return false;
            }
            if (formValues?.phone?.length < 6) {
                toast.error("Local Phone number must be at least 6 digits")
                return false;
            }
        }
        if (formValues?.interNationalPhone?.length) {
            if (!formValues?.internationalPhoneCode) {
                toast.error("Please select International Phone Country Code")
                return false;
            }
            if (!mobileRegex.test(formValues?.interNationalPhone)) {
                toast.error("Please enter valid International Phone")
                return false;
            }
            if (formValues?.interNationalPhone?.length < 6) {
                toast.error("International Phone number must be at least 6 digits")
                return false;
            }
        }

        if (!formValues?.gender) {
            toast.error("Please Select Gender")
            return false;
        }
        if (formValues?.birthDate === moment(new Date()).format("YYYY-MM-DD")) {
            toast.error("Please Enter Date Of Birth")
            return false;
        }
        if (!formValues?.nationality) {
            toast.error("Please Select Nationality")
            return false;
        }
        if (!formValues?.residentCountry) {
            toast.error("Please Select Resident Country")
            return false;
        }
        if (!formValues?.iraqiCitizenShip && formValues?.nationality === "104") {
            toast.error("Please Select Iraqi Citizenship")
            return false;
        }
        if (!formValues?.residentAddress || formValues?.residentAddress.trim() === "") {
            toast.error("Please Enter Resident Address")
            return false;
        }
        return true;
    }


    const citizenshipInfoValidate = () => {
        if (!(formValues.nationality === "104" || formValues.nationality === 104)) {
            if (!formValues?.passportNumber || formValues?.passportNumber.trim() === '') {
                toast.error("Please Enter your Passport Number")
                return false;
            }
            if (!formValues?.passportFile?.length) {
                if (candidate?.passport === null || "") {
                    toast.error("Please Upload your Passport")
                    return false;
                } else {
                    return true;
                }
            }
            return true;
        }
        else if ((formValues.nationality === "104" || formValues.nationality === 104)) {
            if (!formValues?.citizenNumber || formValues?.citizenNumber.trim() === '') {
                toast.error("Please Enter your Citizenship Number")
                return false;
            }
            if (!formValues?.citizenshipDocument?.length) {
                if (candidate?.nfoos === null || candidate?.nfoos === "") {
                    toast.error("Please Upload Iraqi Citizenship")
                    return false;
                } else {
                    return true;
                }
            }
            return true;
        }
    }

    const qualificationInfoValidate = () => {
        if (!formValues?.seniorityLevel) {
            toast.error("Please Select Seniority Level")
            return false;
        }
        if (formValues?.jobFamily?.length === 0 || formValues?.jobFamily === "") {
            toast.error("Please Select Profession")
            return false;
        }
        if (!formValues?.relevantExperience) {
            toast.error("Please Select Relevant Experience")
            return false;
        }
        if (!formValues?.highestQualification) {
            toast.error("Please Select Highest Academic Qualification")
            return false;
        }
        if (!(formValues?.highestQualification === "None" || formValues?.highestQualification === "") && candidate?.highestQualificationCertificate === null) {
            if (!formValues?.highestQualdocument?.length) {
                if (candidate?.highestQualificationCertificate === null) {
                    toast.error("Please Upload your highest academic qualification document")
                    return false;
                } else {
                    return true;
                }

            }
        }
        if (!formValues?.cv?.length) {
            if (candidate?.cv === null) {
                toast.error("Please Upload your CV")
                return false;
            } else {
                return true;
            }
        }

        return true;
    }

    const GeneralInfoNext = () => {
        if (!generalInfoValidate()) return;
        setLoading(true)
        const data = {};
        data.id = candidateId;
        data.Email = formValues.email;
        data.FirstName = formValues.firstName;
        data.LastName = formValues.lastName;
        data.Password = "";
        data.LocalPhoneNoCountryId = Number(formValues.phoneCode);
        data.LocalPhoneNo = formValues.phone;
        data.InternationalPhoneNoCountryId = Number(formValues.internationalPhoneCode);
        data.InternationalPhoneNo = formValues.interNationalPhone;
        data.Sex = formValues.gender;
        data.BirthDate = formValues.birthDate;
        data.NationalityId = Number(formValues.nationality);
        data.ResidentAddress = formValues.residentAddress;
        data.ResidentCountryId = Number(formValues.residentCountry);
        data.HasCitizenship = (formValues.nationality === "104" || formValues.nationality === 104) ? formValues.iraqiCitizenShip === "true" : null;
        data.Expat = expatData;
        const option = {
            method: "POST", headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`
            },
            data: data,
            url: `${Apiurl()}/candidate/signup`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                if (e?.data?.statusCode === 500) {
                    toast.error(e?.data?.errorMessage)
                } else {
                    toast.success(e?.data?.successMessage)
                    setCurrentTab("Citizenship");
                    SetProfileTab("Citizenship")
                    getCandidateData();
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err?.response?.status === 401) {
                    RemoveUserSession();
                    navigate("/");
                }
            });
    }

    const UpdateStep1 = () => {
        if (!generalInfoValidate()) return;
        setLoading(true);
        const data = {};
        data.id = candidateId;
        data.Email = formValues.email;
        data.FirstName = formValues.firstName;
        data.LastName = formValues.lastName;
        data.Password = "";
        data.LocalPhoneNoCountryId = Number(formValues.phoneCode);
        data.LocalPhoneNo = formValues.phone;
        data.InternationalPhoneNoCountryId = Number(formValues.internationalPhoneCode);
        data.InternationalPhoneNo = formValues.interNationalPhone;
        data.Sex = formValues.gender;
        data.BirthDate = formValues.birthDate;
        data.NationalityId = Number(formValues.nationality);
        data.ResidentAddress = formValues.residentAddress;
        data.ResidentCountryId = Number(formValues.residentCountry);
        data.HasCitizenship = (formValues.nationality === "104" || formValues.nationality === 104) ? formValues.iraqiCitizenShip === "true" : null;
        data.Expat = expatData;
        const option = {
            method: "POST", headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            data: data,
            url: `${Apiurl()}/candidate/editprofile`
        };
        axios(option)
            .then(e => {
                setLoading(false);
                if (e?.data?.statusCode === 500) {
                    toast.error(e?.data?.errorMessage)
                } else {
                    toast.success(e?.data?.successMessage)
                    getCandidateData();
                    if (updateBtn === "") {
                        setCurrentTab("Citizenship");
                    } else {
                        setUpdateBtn("Citizenship")
                        setCurrentTab("Citizenship");
                    }
                    SetProfileTab("Citizenship")
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err?.response?.status === 401) {
                    RemoveUserSession();
                    navigate("/");
                }
            });
    }
    const CitizenInfoSubmit = () => {
        if (!citizenshipInfoValidate()) return;
        setLoading(true);
        const formData = new FormData();
        formData.append('id', candidate?.id);
        formData.append('expat', candidate?.expat);
        formData.append('PassportNumber', formValues?.nationality === "104" || formValues?.nationality === 104 ? "" : formValues?.passportNumber);
        formData.append('CitizenshipNo', formValues?.nationality === "104" || formValues?.nationality === 104 ? formValues?.citizenNumber : "");
        formData.append('files', formValues?.passportFile ? formValues?.passportFile?.[0] : formValues?.citizenshipDocument?.[0]);
        const option = {
            method: "POST", headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            data: formData,
            url: `${Apiurl()}/candidate/signup/step2`
        };
        axios(option)
            .then(e => {
                setLoading(false);
                if (e?.data?.statusCode === 0) {
                    toast.success(e?.data?.successMessage)
                    setformValues({
                        ...formValues,
                        passportFile: null,
                        citizenshipDocument: [],
                    });
                    getCandidateData();
                    if (updateBtn === "") {
                        setCurrentTab("Qualifications");
                    } else {
                        setUpdateBtn("Qualifications")
                        setCurrentTab("Qualifications");
                    }
                    SetProfileTab("Qualifications")
                } else {
                    toast.error(e?.data?.errorMessage);
                }

            })
            .catch((err) => {
                setLoading(false);
                if (err?.response?.status === 401) {
                    RemoveUserSession();
                    navigate("/");
                }
            });
    }

    const CitizenInfoPrevious = () => {
        setCurrentTab("GeneralInfo");
        SetProfileTab("GeneralInfo")
        getCandidateData();

    }
    const CitizenInfoPreviousUpdate = () => {
        setUpdateBtn("GeneralInfo")
        setCurrentTab("GeneralInfo");
        SetProfileTab("GeneralInfo")
        getCandidateData();

    }

    const QualificationInfoPrevious = () => {
        setCurrentTab("Citizenship");
        SetProfileTab("Citizenship")
        getCandidateData();
    }
    const QualificationInfoPreviousUpdate = () => {
        setUpdateBtn("Citizenship")
        setCurrentTab("Citizenship");
        SetProfileTab("Citizenship")
        getCandidateData();
    }

    const otherCertificatesData = formValues?.otherCertificates?.length ? Object.values(formValues?.otherCertificates) : formValues?.otherCertificates;
    const QualificationInfoSubmit = () => {
        if (!qualificationInfoValidate()) return;
        setLoading(true);
        const formData = new FormData();
        formData.append('id', candidate?.id);
        formData.append('LevelId', formValues?.seniorityLevel);
        formData.append('JobFamilyIds', formValues?.jobFamily);
        formData.append('RelevantExperience', formValues?.relevantExperience);
        formData.append('CVFile', formValues?.cv?.[0]);
        formData.append('HighestQualification', formValues?.highestQualification);
        formData.append('HighestQualificationCertificateFile', !formValues?.highestQualification === "None" ? [] : formValues?.highestQualdocument?.[0]);
        {
            otherCertificatesData?.length ?
                otherCertificatesData?.map((file) => {
                    formData.append('OtherFiles', file);
                })
                : formData.append('OtherFiles', []);
        }

        const option = {
            method: "POST", headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            data: formData,
            url: `${Apiurl()}/candidate/signup/step3`
        };
        axios(option)
            .then(e => {
                setLoading(false);
                if (e?.data?.statusCode === 0) {
                    toast.success(e?.data?.successMessage)
                    getCandidateData();
                    if (e?.data?.body?.isCompleted === true) {
                        if (updateBtn === "") {
                            if (applyFrom === "jobs") {
                                navigate(`/application/${jobDetail}`, { state: jobData })
                            } else if (applyFrom === "intern") {
                                navigate(`/internshipapplication${internDetail}`, { state: internData })
                            } else if (applyFrom === "course") {
                                navigate(`/courseapplication${courseDetail}`, { state: courseData })
                            } else {
                                navigate('/')
                            }
                        } else {
                            navigate('/candidateprofile')
                        }
                    }
                } else {
                    toast.error("Data entered is incorrect.")
                }

            })
            .catch((err) => {
                setLoading(false);
                if (err?.response?.status === 401) {
                    RemoveUserSession();
                    navigate("/");
                }
            });
    }

    const goToTop = () => {
        window.scrollTo(0, 0);
    }

    const CancelEditProfile = () => {
        goToTop();
        navigate('/candidateprofile')
    }

    const changeTab = (k) => {
        if (k === "Citizenship") {
            if (!generalInfoValidate()) return
            if (updateBtn !== "") { setUpdateBtn(k) }
            setCurrentTab(k);
        }
        else if (k === "Qualifications") {
            if (!generalInfoValidate()) return
            if (!citizenshipInfoValidate()) return;
            if (updateBtn !== "") { setUpdateBtn(k) }
            setCurrentTab(k);
        } else {
            if (updateBtn !== "") { setUpdateBtn(k) }
            setCurrentTab(k);
        }
        SetProfileTab(k)
    }

    useEffect(() => {
        SetProfileTab(activeTab)
        if (activeTab === "GeneralInfo") {
            setCurrentTab(activeTab)
            setUpdateBtn(activeTab)
            
        } else if (activeTab === "Citizenship") {
            setCurrentTab(activeTab)
            setUpdateBtn(activeTab)
        } else if (activeTab === "Qualifications") {
            setCurrentTab(activeTab)
            setUpdateBtn(activeTab)
        } else {
            setCurrentTab("Citizenship")
            SetProfileTab("Citizenship")
        }
    }, [activeTab]);

    useEffect(() => {
        if (currentTab === "GeneralInfo") {
            document.title = `${GetPortalTitle('General Info')}`;
        } else if (currentTab === "Citizenship") {
            document.title = `${GetPortalTitle('Citizenship/Work Permit Info')}`;
        } else if (currentTab === "Qualifications") {
            document.title = `${GetPortalTitle('Position & Qualifications')}`;
        }

    }, [currentTab]);

    return (
        <>
            {/*<Headers />*/}
            {
                loading ? <Loader /> : null
            }
            <div className="profile-container">
                <Header loading={loading} setProfilePage={setProfilePage} profilePage={profilePage} candidateProfile="editPro" />
                <Container className="mb-5 sign-up-main-container profile-tabs">
                    <Row className="signup-row">
                        <Col md={12}>
                            <div className='card-login'>
                                <h1 className="heading">{candidate?.firstName + "'s Profile"}</h1>
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={currentTab}
                                    onSelect={(k) => changeTab(k)}
                                    className="mb-3"
                                >
                                    {/*General Info*/}
                                    <Tab eventKey="GeneralInfo" title="General Info">
                                        <GeneralInfoProfile
                                            onChange={onChange}
                                            formValues={formValues}
                                            candidate={candidate}
                                            setformValues={setformValues}
                                        />
                                        <Row className="d-flex justify-content-end mt-3 profile-btn-mob">
                                            <Col md={2} className="profile-inner-btn"> <Button variant="success" className="next-btn-signup" title="Cancel" onClick={() => CancelEditProfile()}>Cancel</Button></Col>
                                            {updateBtn === "GeneralInfo" ?
                                                <Col md={2} className="profile-inner-btn"><Button variant="success" className="next-btn-signup" title="Update & Next" onClick={() => UpdateStep1()}>Update & Next</Button></Col>
                                                : <Col md={2} className="profile-inner-btn"> <Button variant="success" className="next-btn-signup" title="Next" onClick={() => GeneralInfoNext()}>Next <BsArrowRightShort className="prev-icon-signup" /></Button></Col>}

                                        </Row>
                                    </Tab>

                                    {/*Citizenship/Work Permit Info*/}
                                    <Tab eventKey="Citizenship" title="Citizenship/Passport Info">
                                        <CitizenshipInfoProfile
                                            onChange={onChange}
                                            formValues={formValues}
                                            candidate={candidate}
                                        />
                                        <Row className="d-flex justify-content-end mt-3 profile-btn-mob">
                                            {
                                                updateBtn === "Citizenship" ? <>
                                                    <Col md={2} className="profile-inner-btn">
                                                        <Button variant="success" className="previous-btn-signup" onClick={() => CitizenInfoPreviousUpdate()} title="Previous"><BsArrowLeftShort className="prev-icon-signup" /> Previous </Button>
                                                    </Col>
                                                    <Col md={2} className="profile-inner-btn"> <Button variant="success" className="next-btn-signup" onClick={() => CancelEditProfile()} title="Cancel">Cancel</Button></Col>
                                                    <Col md={2} className="profile-inner-btn">
                                                        <Button variant="success" className="next-btn-signup" onClick={() => CitizenInfoSubmit()} title="Update & Next">Update & Next </Button>
                                                    </Col></>
                                                    : <>
                                                        <Col md={2} className="profile-inner-btn">
                                                            <Button variant="success" className="previous-btn-signup" onClick={() => CitizenInfoPrevious()} title="Previous"><BsArrowLeftShort className="prev-icon-signup" /> Previous </Button>
                                                        </Col>
                                                        <Col md={2} className="profile-inner-btn"> <Button variant="success" className="next-btn-signup" onClick={() => CancelEditProfile()} title="Cancel">Cancel</Button></Col>
                                                        <Col md={2} className="profile-inner-btn">
                                                            <Button variant="success" className="next-btn-signup" onClick={() => CitizenInfoSubmit()} title="Next">Next <BsArrowRightShort className="prev-icon-signup" /> </Button>
                                                        </Col>
                                                    </>

                                            }

                                        </Row>
                                    </Tab>

                                    {/*Position & Qualifications*/}
                                    <Tab eventKey="Qualifications" title="Position & Qualifications">
                                        <QualificationInfoProfile
                                            onChange={onChange}
                                            formValues={formValues}
                                            setformValues={setformValues}
                                            candidate={candidate}
                                        />
                                        <Row className="d-flex justify-content-end mt-3 profile-btn-mob">
                                            
                                            {
                                                updateBtn === "Qualifications" ? <>
                                                    <Col md={2} className="profile-inner-btn">
                                                        <Button variant="success" className="previous-btn-signup" onClick={() => QualificationInfoPreviousUpdate()} title="Previous"><BsArrowLeftShort className="prev-icon-signup" /> Previous </Button>
                                                    </Col>
                                                    <Col md={2} className="profile-inner-btn"> <Button variant="success" className="next-btn-signup" onClick={() => CancelEditProfile()} title="Cancel">Cancel</Button></Col>
                                                    <Col md={2} className="profile-inner-btn">
                                                        <Button variant="success" className="next-btn-signup" onClick={() => QualificationInfoSubmit()} title="Update">Update</Button>
                                                    </Col></>
                                                    : <>
                                                        <Col md={2} className="profile-inner-btn">
                                                            <Button variant="success" className="previous-btn-signup" onClick={() => QualificationInfoPrevious()} title="Previous"><BsArrowLeftShort className="prev-icon-signup" /> Previous </Button>
                                                        </Col>
                                                        <Col md={2} className="profile-inner-btn"> <Button variant="success" className="next-btn-signup" onClick={() => CancelEditProfile()} title="Cancel">Cancel</Button></Col>
                                                        <Col md={2} className="profile-inner-btn">
                                                            <Button variant="success" className="next-btn-signup" onClick={() => QualificationInfoSubmit()} title="Submit">Submit</Button>
                                                        </Col>
                                                    </>
                                            }

                                        </Row>
                                    </Tab>
                                </Tabs>
                            </div>



                        </Col>
                    </Row>
                </Container>
                <Footer loading={loading} />
            </div>
        </>
    )
}

export default Profile;