import React, { useState } from 'react';
import { Container, Row, Col, Form, Label, Input, FormGroup } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import axios from "axios";
import { Apiurl, CandidateIdGet, GetPortalTitle, GetToken, RemoveUserSession, XApiKey, RemoveLocalStorage } from '../../../utils/utils';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import Loader from '../../../components/Loader/loader';

const CourseApplication = () => {
    const navigate = useNavigate()
    const courseUrl = window?.location?.pathname.toLowerCase();
    const actualUrl = courseUrl?.split('/courseapplication')[1];
    const [courseDetail, setCourseDetail] = useState([])
    const [applyModal, setApplyModal] = useState(false);
    const CloseApplyModal = () => setApplyModal(false);
    const ShowApplyModal = () => setApplyModal(true);
    const [candidate, setCandidate  ] = useState([]);
    const [loading, setLoading] = useState(false);
    const [backtoSearch, setBacktoSearch] = useState('');
    const [popupText, setPopUpText] = useState('');
    const candidateId = CandidateIdGet();
    const candidateToken = GetToken();
    const [courseApplication, setCourseApplication] = useState([])
    const [appliedCourse, setAppliedCourse] = useState('')
    
    const getCandidateData = () => {
        setLoading(true);
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            url: `${Apiurl()}/candidate/get?id=${candidateId}`

        };
        axios(option)
            .then(e => {
                setCandidate(e?.data?.body);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                if (err?.response?.status === 401) {
                    RemoveUserSession();
                    //navigate("/");
                } else {
                    navigate('404')
                }
            });
    }

    const GetCourseByUrl = () => {
        return new Promise((resolve, reject) => {
            const option = {
                method: 'GET', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'application/json',
                    'XApiKey': `${XApiKey()}`,
                },
                url: `${Apiurl()}/course/getbyurl?url=/course${actualUrl}&uId=${candidateId}`,
            }
            axios(option)
                .then((e) => {
                    if (e?.status === 200) {
                        const data = e?.data || []
                        setCourseDetail(data);
                        resolve(data);
                        if (e?.data?.applied) {
                            console.log('232323')
                            setAppliedCourse('true')
                        }
                    }
                    if (e?.data?.id === 0) {
                        navigate('404')
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                })
        })
    }

    useEffect(() => {
        getCandidateData();
        GetCourseByUrl();
    }, [])

    useEffect(() => {
        document.title = `${GetPortalTitle('Course Application')}`;
        RemoveLocalStorage("filters");
        RemoveLocalStorage("InternShipfilters");
    }, [])

    const applicationSubmit = () => {
        if (!candidate?.id) return;
        setLoading(true)
        const formData = new FormData();
        formData.append('courseid', courseDetail?.id);
        formData.append('uid', candidate?.id);
        const option = {
            method: "POST", headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            data: formData,
            url: `${Apiurl()}/courseAttendees/create`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                if (e?.data?.statusCode === 200) {
                    setPopUpText('Thank you for enrolling for ' + courseDetail?.title + ' in ' + ((courseDetail?.countryDescription) ? (courseDetail?.countryDescription + ", ") : "") + courseDetail?.city + ' with ' + courseDetail?.company?.shortName + '.');
                    ShowApplyModal()
                }
                if (e?.data?.statusCode === 500) {
                    setBacktoSearch('false')
                    setPopUpText(e?.data?.errorMessage)
                    ShowApplyModal()
                }
            })
            .catch((err) => {
                setLoading(false)
                navigate('404')
            });
    }

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                if (applyModal) {
                    setPopUpText('Thank you for enrolling for ' + courseDetail?.title + ' in ' + ((courseDetail?.countryDescription) ? (courseDetail?.countryDescription + ", ") : "") + courseDetail?.city + ' with ' + courseDetail?.company?.shortName + '.');
                    ShowApplyModal();
                } else {
                    navigate('/courses')
                }
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [applyModal]);

    const navigateToStakeholder = () => {
        var type = "";
        if (courseDetail?.company?.type === "International Oil Company") {
            type = "ioc"
        } else if (courseDetail?.company?.type === "Refinery") {
            type = "refinery"
        } else if (courseDetail?.company?.type === "Pipeline Operator") {
            type = "pipelineoperator"
        } else if (courseDetail?.company?.type === "Oilfield Service Company") {
            type = "osc"
        }
        navigate(`/Stakeholder/${type}/${courseDetail?.company?.shortName}`, { state: { stakeholder: courseDetail?.company } })
    }

    const getCourseApplications = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            url: `${Apiurl()}/courseAttendees/getlist?uid=${candidateId}`
        };
        axios(option)
            .then(e => {
                setCourseApplication(e?.data);
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            });
    }

    useEffect(() => {
        getCourseApplications();
    }, [])

    useEffect(() => {
        if (appliedCourse) {
            setPopUpText('You have already enrolled for ' + courseDetail?.title + ' in ' + ((courseDetail?.countryDescription) ? (courseDetail?.countryDescription + ", ") : "") + courseDetail?.city + ' with ' + courseDetail?.company?.shortName + '.');
            ShowApplyModal()
        }
    }, [appliedCourse])

    const onNavigateToCandidateProfile = () => {
        localStorage.setItem(`source-page`, `courseapplication`)
    }

    return (
        <div>
            {
                loading ? <Loader /> : null
            }

            <Header loading={loading} applyModal={applyModal} />
            <div id="applicationForm">
                <Container>
                    <Row className="application-form">
                        <Col className="col-md-6">
                            <Form>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="applicantFirstName">First Name</Label>
                                            <Input id="applicantFirstName" name="firstname" placeholder="" type="text" value={candidate?.firstName} disabled />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="applicantLastName">Last Name</Label>
                                            <Input id="applicantLastName" name="surname" placeholder="" type="text" value={candidate?.lastName} disabled />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>By enrolling you agree to the Terms and Conditions</Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col className="col-md-6 application-sidebar">
                            <h2 className="job-info-application">Course Information</h2>
                            <div className="job-details-apply">
                                <div><i className="fa-light fa-graduation-cap"></i><b>Course Title: </b>{courseDetail?.title}</div>
                                <div style={{ marginLeft: '4px' }}><i className="fa-light fa-location-dot"></i><b>   Location: </b>{courseDetail?.countryDescription ? courseDetail?.countryDescription +", " : ""} {courseDetail?.city}</div>
                                <div style={{ marginLeft: '2.5px' }}><i className="fa-light fa-earth-asia"></i><b>Stakeholder: </b><span onClick={() => navigateToStakeholder()} className="application-stakeholder">{courseDetail?.company?.shortName}</span></div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="btn btn-primary submit application-submit" onClick={() => applicationSubmit()}>Enroll</div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer loading={loading}/>

            <Modal show={applyModal} onHide={CloseApplyModal} className="login-modal" backdrop="static">
                <Modal.Body className="login-modal-body">
                    <div className="login-modal-header login-modal-card-main-section">
                        <div className="login-card-section">
                            <Row>
                                <Col md={12} className="left-section-login">
                                    <div className="card-login card-login-50">
                                        <div className="forgot-password-heading-inner text-center">
                                            <h3>{ popupText }</h3>
                                            <div className="paddingbottom15">
                                                {backtoSearch ? null : <Link to="/courses" className="application-modal floatleft">Back to search</Link>}
                                                <Link to="/candidateprofile" state="courseapplication" className="application-modal floatright" title="View enrollments"
                                                    onClick={onNavigateToCandidateProfile} >
                                                    View enrollments
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    );
}
export default CourseApplication;