import { toast } from 'react-toastify';
import CONFIG_URLS from '../footerConfig.js'

export const GetSystemEnv = () => {
    let checkUrl = window?.location?.hostname;
    let config = {
        isProd: false,
        isDev: false,
        isTest:false
    }
    if (checkUrl === `localhost`) {
        config.isDev = true;
    }
    if (checkUrl === 'test.jobs.mnronline.com') {
        config.isTest = true;
    }
    if (checkUrl === 'jobs.mnronline.com') {
        config.isProd = true;
    }
    return config;
}

export const GetPortalTitle = (subTitle) => {
    const sysetmEnv = GetSystemEnv();
    let title = ``;
    if (sysetmEnv.isProd) {
        title = `MNR`
    }else if (sysetmEnv.isTest) {
        title = `Test`
    } else if (sysetmEnv.isDev) {
        title = `Dev`
    }
    title = `${title} Job Portal`;
    if (subTitle) {
         title = `${title} - ${subTitle}`
    }
    return title;
}
//api url
export const Apiurl = () => {
    /*return 'http://localhost:84/api';*/
    let checkUrl = window?.location?.hostname;
    if (checkUrl === `localhost`) {
        return CONFIG_URLS?.CONFIG_URLS?.test?.core;
    }
    if (checkUrl === 'test.jobs.mnronline.com') {
        return CONFIG_URLS?.CONFIG_URLS?.test?.core;
    }
    if (checkUrl === 'jobs.mnronline.com') {
        return CONFIG_URLS?.CONFIG_URLS?.live?.core;
    }
} 

//login token set
export const SetToken = (token) => {
    localStorage.setItem('@token', token)
}

//login token get
export const GetToken = () => {
    return localStorage.getItem('@token') || null;
}

//candidate id set
export const CandidateId = (candidateid) => {
    localStorage.setItem('candidateid', candidateid);
}

//candidate id get
export const CandidateIdGet = () => {
    return localStorage.getItem('candidateid') || null;
}

//remove candidate id
export const RemoveUserSession = () => {
    localStorage.removeItem('candidateid');
    localStorage.removeItem('@token');
    localStorage.removeItem('source-page');
} 

//store XApiKey
export const XApiKey = () => {
    return 'Jzi446fIRFvz7x4pw~5UTwlEHXgHptQ';
}

//password token set
export const PasswordtokenSet = (passwordToken) => {
    localStorage.setItem('@passwordToken', passwordToken) 
}

//password token get
export const PasswordtokenGet = () => {
   return localStorage.getItem('@passwordToken') || null;
}


//store userEmail
export const userEmail = (useremail) => {
    localStorage.setItem('useremail', useremail)
}

//get useremail
export const getUserEmail = () => {
    return localStorage.getItem('useremail') || null;
}

//login token set
export const SetProfileTab = (tab) => {
    localStorage.setItem('profiletab', tab)
}

//login token get
export const GetProfileTab = () => {
    return localStorage.getItem('profiletab') || null;
}

export const loginValidates = (formState, Emailvalidate) => {
    if (!formState.loginEmail) {
        toast.error("Please Enter Your Email Address")
        return false
    }
    if (!Emailvalidate.test(formState.loginEmail)) {
        toast.error("Please Enter Valid Email Address")
        return false
    }
    if (!formState.loginPassword) {
        toast.error("Please Enter Your Password")
        return false
    }
    if (formState.loginPassword.length < 8) {
        toast.error("Password must be at least 8 characters ")
        return false
    }
    return true
}

export const ValidateResetLink = (formState, Emailvalidate, forgotCaptcha) => {
    if (!formState.forgotPasswordEmail) {
        toast.error("Please Enter Your Email Address")
        return false
    }
    if (!Emailvalidate.test(formState.forgotPasswordEmail)) {
        toast.error("Please Enter Valid Email Address")
        return false
    }
    if (!forgotCaptcha) {
        toast.error("Failed recaptcha check")
        return false
    }
    return true

}

//Set Local Storage
export const SetLocalStorage = (name, value) => {
    localStorage.setItem(name, value)
}

//Get Local Storage
export const GetLocalStorage = (name) => {
    return localStorage.getItem(name) || null;
}

//Remove Local Storage
export const RemoveLocalStorage = (name) => {
    localStorage.removeItem(name);
} 