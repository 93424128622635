import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, List } from 'reactstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SimilarJobs from '../components/SimilarJobs/SimilarJobs';
import { Apiurl, CandidateIdGet, GetPortalTitle, GetToken, RemoveUserSession, XApiKey } from '../utils/utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../components/Loader/loader';
import moment from 'moment';
import Headers from '../components/Header/Header';
import Footer from '../components/Footer/Footer';


const JobDetails = () => {
    const navigate = useNavigate()
    const Token = GetToken();
    const Candidateid = CandidateIdGet();
    const [loading, setLoading] = useState(false);
    const url = window.location.pathname;
    const actualUrl = url?.split('/');
    const [jobData, setJobData] = useState('');
    const [similarJob, setSimilarJob] = useState([]);
    const GetJobFamilyId = jobData?.jobFamily?.id;
    const GetStakeholderId = jobData?.stakeholder?.id;
    const [loginIsApply, setLoginIsApply] = useState(false);
    const [applyBtn, setApplyBtn] = useState(false);
    const [candidate, setCandidate] = useState([])
    const userStatus = candidate?.isCompleted;
    const [applyDetails, setApplyDetails]=useState("")

    const applyNow = () => {
        if (!Token) {
            setApplyDetails("yes");
            setLoginIsApply(true);
        } else {
            if (userStatus) {
                navigate(`/application/${actualUrl?.[2]}`, { state: jobData })
            } else { toast.error('Please complete your profile before apply') }
         }
    }

    const goToTop = () => {
        window.scrollTo(0, 0);
    }

    const getCandidateData = () => {
        if (!Candidateid) { return }
        setLoading(true);
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${Token}`
            },
            url: `${Apiurl()}/candidate/get?id=${Candidateid}`
        };
        axios(option)
            .then(e => {
                setCandidate(e?.data?.body);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                if (err?.response?.status === 401) {
                    
                } else {
                    //navigate('*')
                }
            });
    }

    const GetJobByUrl = () => {
        setLoading(true);
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/jobpost/getbyurl?url=${url}&CandidateId=${Candidateid}`,
        }
        axios(option)
            .then((e) => {
                if (e?.status === 200) {
                    setJobData(e?.data);

                    if (e?.data?.candidateApplied) {
                        setApplyBtn(false)

                        var msg = "You have already applied for this job";

                        if (e?.data?.latestApplication_StatusString !== "Applied")
                        {
                            switch (e?.data?.latestApplication_StatusString) {
                                case "Rejected":
                                    msg += ", your application has been rejected";
                                    break;
                                case "Hired":
                                    msg += ", you have been hired";
                                    break;
                                default:
                                    msg += "";
                                    break;
                            }
                        }

                        toast.success(msg);
                    
                    } else {
                        const deadline = moment(e?.data?.deadline).format('L');
                        const today = moment(new Date()).format('L');
                        if (today <= deadline && e?.data?.status === 0) {
                            setApplyBtn(true)
                        }
                    }
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                navigate('404')
            })
    }

    const GetSimillarJobsByJobFamily = () => {
        setLoading(true)
        const data = {}
        data.jobFamilyid = JSON.stringify(GetJobFamilyId);

        const candidateId = CandidateIdGet();
        if (candidateId) { data.candidateId = candidateId }

        const option = {
            method: 'Post', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            data: JSON.stringify(data),
            url: `${Apiurl()}/jobpost/getjobposts`,
        }
        axios(option)
            .then((e) => {
                if (e?.status === 200) {
                    const data = e?.data?.filter((item) => item?.id != jobData?.id)
                    setSimilarJob(data)
                    if (data?.length === 0) {
                        GetSimillarJobsbyStakeholder();
                    }
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const GetSimillarJobsbyStakeholder = () => {
        setLoading(true)
        const data = {}
        data.stakeholderid = JSON.stringify(GetStakeholderId);

        const candidateId = CandidateIdGet();
        if (candidateId) { data.candidateId = candidateId }

        const option = {
            method: 'Post', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            data: JSON.stringify(data),
            url: `${Apiurl()}/jobpost/getjobposts`,
        }
        axios(option)
            .then((e) => {
                if (e?.status === 200) {
                    const data = e?.data?.filter((item) => item?.id != jobData?.id)
                    setSimilarJob(data)
                }
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const navigateToStakeholder = () => {
        var type = "";
        if (jobData?.stakeholder?.type === "International Oil Company") {
            type = "ioc"
        } else if (jobData?.stakeholder?.type === "Refinery") {
            type = "refinery"
        } else if (jobData?.stakeholder?.type === "Pipeline Operator") {
            type = "pipelineoperator"
        } else if (jobData?.stakeholder?.type === "Oilfield Service Company") {
            type = "osc"
        }
        navigate(`/stakeholder/${type}/${jobData?.stakeholder?.shortName}`, { state: { stakeholder: jobData?.stakeholder } })
    }

    useEffect(() => {
        GetJobByUrl();
        getCandidateData();
        goToTop();
    }, [])

    useEffect(() => {
        if (GetJobFamilyId) { GetSimillarJobsByJobFamily() }
    }, [GetJobFamilyId])

    const onError = (e) => {
        e.target.src = 'assets/images/logos/dummy-logo.webp';
    }

    useEffect(() => {
        document.title = `${GetPortalTitle( jobData?.title)}`;
    }, [jobData])

    useEffect(() => {
        if (jobData?.title === null || "" || undefined) {
            navigate('404')
        }
    }, [jobData])

    return (

        <div>
            {
                loading ? <Loader /> : null
            }
            <Headers setApplyBtn={setApplyBtn} loginIsApply={loginIsApply} setLoginIsApply={setLoginIsApply} applyDetails={applyDetails} jobDetail={url} jobData={jobData} loading={loading} />
            <div id="jobDetailsIntro" className="alternative-section">
                <Container>
                    <Row>
                        <Col className="col-sm-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="col-12 col-sm-2 col img-col">
                                            {
                                                jobData?.stakeholder?.imageUrl ? <CardImg className="cardImg" alt="logo" src={jobData?.stakeholder?.imageUrl || 'assets/images/logos/dummy-logo.webp'} title={jobData?.stakeholder?.shortName} onError={(e) => onError(e)} /> :
                                                    <div className="image-initials" title={jobData?.stakeholder?.shortName}>{jobData?.stakeholder?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{jobData?.stakeholder?.shortName?.split(' ').length > 1 ? jobData?.stakeholder?.shortName?.split(' ')[jobData?.stakeholder?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null }</div>
                                            }
                                            
                                        </Col>
                                        <Col className="col-12 col-sm-10 col">
                                            <CardTitle tag="h3">{jobData?.title?.split(".")[0]}</CardTitle>
                                            <div className="card-text">
                                                <div className="badge-wrapper">
                                                    {jobData?.employmentTypeDescription ? <span className="badge green align-middle">{jobData?.employmentTypeDescription}</span> : null}
                                                    {jobData?.isUrgent ? <span className="badge gold align-middle">Urgent</span> : null}
                                                </div>

                                                <div className="top-section-job-details">
                                                    <div className="top-family-location">
                                                        {jobData?.subSector?.parent?.name ? <div><span className="listing"><i className="fa-solid fa-oil-well"></i><strong>Job Sector: </strong>{jobData?.subSector?.parent?.name}</span></div> : null}
                                                        {jobData?.subSector?.name ? <div><span className="listing"><i className="fa-regular fa-oil-well"></i><strong>Sub Sector: </strong>{jobData?.subSector?.name}</span></div> : null}
                                                    </div>
                                                    <div className="top-family-location">
                                                        {jobData?.jobFamily?.name ? <div>
                                                            <i className="fa-regular fa-briefcase"></i><span><strong>Profession: </strong>{jobData?.jobFamily?.name}</span></div> : null}

                                                        {jobData?.cityText ? <div>
                                                            <i className='fa-solid fa-location-crosshairs'></i><span className="listing"><strong> Role Location: </strong>{jobData?.countryDescription}, {jobData?.cityText}</span>
                                                        </div> : null}
                                                    </div>
                                                    <div className="top-family-location">
                                                        {jobData?.minSalary && jobData?.maxSalary ? <div>
                                                            <i className='fa-solid fa-money-bills'></i><span><strong>Salary: </strong>{jobData?.currencyDescription} {jobData?.minSalary.toLocaleString()} - {jobData?.maxSalary.toLocaleString()}</span></div> : null}
                                                        {jobData?.deadline ? <div>
                                                            <i className='fa-solid fa-clock'></i><span><strong>Deadline: </strong>{moment(jobData?.deadline).format("DD MMM YYYY")}</span></div> : null}
                                                    </div>
                                                </div>
                                                {
                                                    applyBtn ?
                                                        <button className="btn btn-primary apply apply-now-view-dtls" onClick={() => applyNow()} title="Apply Now" >Apply Now</button>
                                                        : null
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="jobDetails" className="mt-5 mb-5">
                <Container>
                    <Row>
                        <Col className="col-12 col-md-8">
                            {jobData?.responsibilities ? <div className="other-para">
                                <h3>Role in the Organisation:</h3>
                                <ul className="list-group mb-4 job-details-role">
                                    {jobData?.responsibilities?.split('\r\n')?.map((item, i) => (
                                        <Fragment key={i}>
                                            {
                                                item !== "" ? <>{item.includes(':') ? <li className='resp'><b>{item}</b></li> : <li key={i}>{item}</li>}</> : null
                                            }

                                        </Fragment>
                                    ))}
                                </ul></div> : null}
                            {jobData?.performance ? <div className="other-para">
                                <h3>Performance Expectations:</h3>
                                {jobData?.performance.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}
                            {jobData?.competency ? <div className="other-para">
                                <h3>Competency (Knowledge & Skills) Required:</h3>
                                {jobData?.competency.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}

                            {jobData?.education ? <div className="other-para">
                                <h3>Education and Qualifications Expected / Required:</h3>
                                {jobData?.education.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}
                            {jobData?.experience ? <div className="other-para">
                                <h3>Experience Expected / Required:</h3>
                                {jobData?.experience.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}
                            {jobData?.other ? <div className="other-para">
                                <h3>Other Relevant Competency Requirements:</h3>
                                <ul className="list-group mb-4 job-details-role">
                                    {jobData?.other?.split('\r\n')?.map((item, i) => (
                                        <Fragment key={i}>
                                            {item !== "" ? <>
                                                {item.includes(':') ? <li className='resp'><b>{item}</b></li> : <li>{item}</li>}
                                            </> : null}
                                        </Fragment>
                                    ))}
                                </ul></div> : null}
                        </Col>

                        <Col className="col-12 col-md-4">
                            <div className="side-bar mb-4">
                                {jobData?.jobTitle?.name ?
                                    <div className="job-detail job-title">
                                        <h4>Standard Job Title</h4>
                                        <p>{jobData?.jobTitle?.name}</p>
                                    </div> : null}

                                {jobData?.seniority?.name ?
                                    <div className="job-detail job-level">
                                        <h4>Seniority Level</h4>
                                        <p>{jobData?.seniority?.name}</p>
                                    </div> : null}
                             
                                {jobData?.relevantExperience ?
                                    <div className="job-detail industry-experience">
                                        <i className="fa-solid fa-industry jobdetails-inner-icons"></i>
                                        <h4>Relevant Experience</h4>
                                        <p>{jobData?.relevantExperience}</p>
                                    </div> : null}

                                {jobData?.employmentTypeDescription ?
                                    <div className="job-detail job-type">
                                        <h4>Employment Type</h4>
                                        <p>{jobData?.employmentTypeDescription}</p>
                                    </div> : null}

                                {jobData?.countryDescription ? <>
                                    <div className="job-detail location">
                                        <i className='fa-solid fa-location-crosshairs jobdetails-inner-icons'></i>
                                        <h4>Location</h4>
                                        <p>{jobData?.countryDescription}</p>
                                    </div></> : null}

                                {jobData?.cityText ?
                                    <div className="job-detail city">
                                        <h4>City</h4>
                                        <p>{jobData?.cityText}</p>
                                    </div> : null}

                                {jobData?.minSalary || jobData?.maxSalary ?
                                    <div className="job-detail compensation">
                                        <i className='fa-solid fa-money-bills jobdetails-inner-icons'></i>
                                        <h4>Compensation</h4>
                                        <p>{jobData?.currencyDescription} {jobData?.minSalary.toLocaleString()} - {jobData?.maxSalary.toLocaleString()}</p>
                                    </div> : null}

                                {jobData?.reportingTo ?
                                    <div className="job-detail reporting">
                                        <i className='fa-solid fa-people-arrows jobdetails-inner-icons'></i>
                                        <h4>Reporting To</h4>
                                        <p>{jobData?.reportingTo}</p>
                                    </div> : null}
                            </div>

                            <div className="side-bar employer mb-4">
                                <div className='logo-section mb-4'>
                                    {
                                        jobData?.stakeholder?.imageUrl ? <img src={jobData?.stakeholder?.imageUrl || "assets/images/logos/dummy-logo.webp"} onError={(e) => onError(e)} width="145" title={jobData?.stakeholder?.shortName} /> :
                                            <div className="image-initials job-view-initials" title={jobData?.stakeholder?.shortName}>{jobData?.stakeholder?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{jobData?.stakeholder?.shortName?.split(' ').length > 1 ? jobData?.stakeholder?.shortName?.split(' ')[jobData?.stakeholder?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</div>
                                    }
                                  
                                    <h4><span>{jobData?.stakeholder?.shortName}</span></h4>
                                </div>
                                <ul>
                                    {jobData?.stakeholder?.type === "Oilfield Service Company" ?
                                        <li>Categories: <span className="paddingbottom10">{jobData?.stakeholder?.categories} </span></li> : null}
                                    {jobData?.stakeholder?.establishment ?
                                        <li>Founded Date: <span>{jobData?.stakeholder?.establishment.slice(0, 4)}</span></li> : null}
                                    {jobData?.stakeholder?.country?.name ?
                                        <li>Location: <span>{jobData?.stakeholder?.country?.name}</span></li> : null}
                                    {!(jobData?.stakeholder?.enquiriesTel === "" || jobData?.stakeholder?.enquiriesTel === "-" || jobData?.stakeholder?.enquiriesTel === null) ?
                                        <li> Phone Number: <span>{jobData?.stakeholder?.enquiriesTelCountry?.diallingCode}{jobData?.stakeholder?.enquiriesTel}</span></li> : null}
                                    {jobData?.stakeholder?.enquiriesEmail ?
                                        <li>Email: <span title={jobData?.stakeholder?.enquiriesEmail }><a href={'mailto:' + jobData?.stakeholder?.enquiriesEmail}>{jobData?.stakeholder?.enquiriesEmail}</a></span></li> : null}
                                </ul>
                                <div className="btn btn-primary apply" onClick={() => navigateToStakeholder()} title="View Company Profile" >View Company Profile</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {jobData?.title && similarJob?.length > 0 ?
                <div className="similar-jobs alternative-section pt-5 pb-5">
                    <Container>
                        <SimilarJobs similarJob={similarJob} />
                    </Container>
                </div>
                : null}

            <Footer loading={loading} />

        </div>


    );
}
export default JobDetails;
