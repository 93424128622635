import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./Error404.css";
import { Col, Container, Row } from "react-bootstrap";
import guidance from "../../img/important-info.svg";
import checked from "../../img/checked.svg"
import { GetPortalTitle, RemoveLocalStorage } from "../../utils/utils";

const Error404 = () => {

    useEffect(() => {
        document.title = `${GetPortalTitle('Website Error')}`;
        RemoveLocalStorage("filters");
        RemoveLocalStorage("InternShipfilters");
        RemoveLocalStorage("Coursesfilters");
    }, [])
    return (
        <div>
            <Header />
            <div className="informationHome-section error-section-container">
                <Container>

                    <Row className="informationHome-row">
                        <Col md={12} className="mt-3">
                            <h2 className="informationHome-head mb-4">Website Error</h2>                           
                        </Col>
                        <Col md="5">
                            <img src={guidance} alt="banner" />
                        </Col>
                        <Col md="7" className="ps-0">
                            <h5 className="error-section">The webpage you are trying to reach can't be found, possible reasons are:</h5>
                            <li className="informationHome-img-checked error-data"><img src={checked} alt="checked" />
                                <span>The page has been moved or deleted.</span>
                            </li>
                            <li className="informationHome-img-checked error-data"><img src={checked} alt="checked" />
                                <span>The URL has been entered incorrectly. </span>
                            </li>
                            <li className="informationHome-img-checked error-data"><img src={checked} alt="checked" />
                                <span>You have followed an out of date bookmark or link. </span>
                            </li>
                            <li className="informationHome-img-checked error-data"><img src={checked} alt="checked" />
                                <span>An error has occurred. IT have been automatically notified and the issue will be resolved promptly. </span>
                            </li>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
};

export default Error404;