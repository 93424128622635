import React, { Component, useState } from 'react';
import { Container, Row, Col, Button, CardColumns, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import './SimilarJobs.css';
import moment from 'moment';

const SimilarJobs = ({ similarJob }) => {
    const navigate = useNavigate();

    const viewJob = (item) => {
        navigate(`/Job/${item?.url.split("/Job/")[1]}`, { state: item });
        window.location.reload();
    }
    const onError = (e) => {
        e.target.src = 'assets/images/logos/dummy-logo.webp'
    }

    return (
        <div>
            <div id="similarJobsIntro">
                <Container>
                    <Row>
                        <Col className="col-sm-12">
                            <h3>Similar Jobs</h3>
                        </Col>
                    </Row>
                </Container>
            </div>
            {
                similarJob?.map((item, i) => (
                    <div id="similarJobsList" key={i}>
                        <Container>
                            <Row>
                                <Col className="col-12">
                                    <CardColumns>
                                        <Card className={(item?.candidateApplied === true) ? "background-grey" : (i % 2 === 0 ? "odd" : "even")}>
                                            <CardBody>
                                                <div>
                                                    {item?.stakeholder?.imageUrl ? <CardImg alt="Card image cap" src={item?.stakeholder?.imageUrl || "assets/images/logos/dummy-logo.webp"} onError={(e) => onError(e)} width="145" title={item?.stakeholder?.shortName} /> :
                                                        <span className="image-initials-popular" style={{ margin: '0 65px 0 20px' }}><span>{item?.stakeholder?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{item?.stakeholder?.shortName?.split(' ').length > 1 ? item?.stakeholder?.shortName?.split(' ')[item?.stakeholder?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</span></span>
                                                    }
                                                </div>
                                                <CardTitle tag="h3">{item?.title?.split(".")[0]}
                                                    <div className="similar-jobs-badges-resp">
                                                        <span className="badge green align-middle">{item?.employmentTypeDescription}</span>
                                                        {item?.isUrgent ? <span className="badge gold align-middle">Urgent</span> : null}
                                                    </div>
                                                </CardTitle>
                                                <div className="card-text">
                                                    <div className='section-job'>
                                                        <i className="fa-regular fa-briefcase"></i><span className="marginleft10">{item?.jobFamily?.name}</span>
                                                        <i className="fa-solid fa-location-crosshairs"></i><span className="marginleft10">{item?.cityText}</span>
                                                        <i className="fa-solid fa-clock"></i><span className="marginleft10">{moment(item?.created).format("DD MMM YYYY")}</span>
                                                        <i className="fa-solid fa-money-bills"></i><span className="marginleft10">{item?.currencyDescription} {item?.minSalary.toLocaleString()} - {item?.maxSalary.toLocaleString()} </span>
                                                    </div>
                                                    <div className="job-links d-inline">
                                                        <span className="job-view">
                                                            <button title="View" onClick={() => viewJob(item)}>View</button>
                                                        </span>
                                                    </div>                                                  
                                                </div>
                                                {item?.candidateApplied === true ? <div className="applied">
                                                    <p>You applied for this job on {moment(item?.latestApplication_Created).format("DD MMM YYYY")}</p></div> : null}
                                            </CardBody>
                                        </Card>
                                    </CardColumns>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                ))
            }
        </div>
    );

}

export default SimilarJobs