import React, { useEffect, useState } from 'react'
import './Footer.css'
import CONFIG_URLS from '../../footerConfig.js'
const Footer = ({ loading }) => {
    const getYear = () => {
        return new Date().getFullYear();
    }
    const [configData,setConfigData] = useState('');
    let checkUrl = window?.location?.hostname;
    useEffect(() => {
        if (checkUrl === `localhost`) {
            setConfigData(CONFIG_URLS?.CONFIG_URLS?.local);
        }
        if (checkUrl === 'test.jobs.mnronline.com') {
            setConfigData(CONFIG_URLS?.CONFIG_URLS?.test);
        }
        if (checkUrl === 'jobs.mnronline.com') {
            setConfigData(CONFIG_URLS?.CONFIG_URLS?.live);
        }
    }, [checkUrl])
    return (
        <>
            <div className={loading ? "copyright loading-footer" : "copyright"}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-sm-12 p-0">
                            <div className="d-flex justify-content-md-start">
                                <p>
                                    {getYear()} &copy; All Rights Reserved.&nbsp;
                                    <a title="Privacy Policy" href="/privacypolicy">Privacy Policy</a> | <a href="/terms" title="Terms of Service">Terms of Service</a> | <a href="http://www.skynet.design" target="_blank" rel="noreferrer" title="Developed by Skynet">Developed by Skynet</a> <a href={configData?.aboutUs} title="About Skynet">(About Us)</a>
                                    | <span className='footer-version-spacing'>t1.0.0 03/08 09</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12 p-0">
                            <div className="d-flex justify-content-md-end">
                                <p>General:&nbsp;
                                    <a href="mailto:portal@mnr.krg.org">portal@mnr.krg.org</a>&nbsp;&nbsp;|&nbsp;&nbsp; Technical:&nbsp;
                                    <a href="mailto:support@mnr.krg.org">support@mnr.krg.org</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer