import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../PrivacyPolicy/PrivacyPolicy.css";
import { Col, Container, Row } from "react-bootstrap";
import { GetPortalTitle, RemoveLocalStorage } from "../../utils/utils";

const SignupSuccess = () => {

    useEffect(() => {
        document.title = `${GetPortalTitle('Verify your account email address')}`;
        RemoveLocalStorage("filters");
        RemoveLocalStorage("InternShipfilters");
        RemoveLocalStorage("Coursesfilters");
    }, [])
    return (

        <div>
            <Header />

            <div className="privacypolicy-section privacypolicy-section-container">
                <Container>

                    <Row className="privacypolicy-row">

                        <Col className="col-lg-12">
                            <h2 className="mb-3 pt-4 privacypolicy-head">Verify your account email address</h2>
                        </Col>

                        <Col className="col-lg-12">
                            <p>
                                A verification email has been sent to you. Look for the verification email in your inbox and click the link in that email. A confirmation message will appear in your web browser.
                            </p>
                            <p>
                                Didn't get the email?
                            </p>
                            <p>
                                Check your spam folder to make sure it didn't end up there. You can also add the email address <b>{localStorage.getItem('successemail')}</b> to your address book and then try sending the email again.
                            </p>
                            <p>
                                If you're still not receiving the email, contact your email service provider to troubleshoot further.
                            </p>
                        </Col>

                    </Row>

                </Container>
            </div>

            <Footer />
        </div>

    )
};

export default SignupSuccess;