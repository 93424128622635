import React, { useState } from 'react';
import { Container, Row, Col, Form, Label, Input, FormGroup } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { Modal } from 'react-bootstrap';
import { Apiurl, CandidateIdGet, GetToken, RemoveUserSession, XApiKey, GetPortalTitle,RemoveLocalStorage } from '../utils/utils';
import { toast } from 'react-toastify';
import axios from "axios";
import Loader from '../components/Loader/loader';
import moment from 'moment';

const Application = () => {
    const navigate = useNavigate()
    const jobUrl = window?.location?.pathname.toLowerCase();
    const actualUrl = jobUrl?.split('/application')[1];
    const [jobDetail, setJobDetail] = useState([])
    const [applyModal, setApplyModal] = useState(false);
    const CloseApplyModal = () => setApplyModal(false);
    const ShowApplyModal = () => setApplyModal(true);
    const [candidate, setCandidate] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cv, setCv] = useState();
    const [backtoSearch, setBacktoSearch] = useState('');
    const [popupText, setPopUpText] = useState('');
    const [viewCv, setViewCv] = useState(false);
    const candidateId = CandidateIdGet();
    const candidateToken = GetToken()
    const [jobApplication, setJobApplication] = useState([])
    const appliedJobs = jobApplication.find(item => item?.jobPost?.id === jobDetail?.id)
    const [allCVs, setAllCVs] = useState([])
    const [value, setValue] = useState('');

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    /*Initialize*/

    useEffect(() => {
        getCandidateData().then((_candidate) => {
            getJobApplications().then(_jobApplications => {
                getAllCVs(_candidate, _jobApplications);
                setLoading(false);
            });
        });

        getJobByUrl().then((_jobdetail) => {
            document.title = `${GetPortalTitle('Application for ' + (_jobdetail?.title))}`;
        })
        RemoveLocalStorage("InternShipfilters");
        RemoveLocalStorage("Coursesfilters");
    }, [])

    useEffect(() => {
        if (appliedJobs) {
            setPopUpText('You have already applied for ' + jobDetail?.title + ' in ' + jobDetail?.countryDescription + ', ' + jobDetail?.cityText + ' with ' + jobDetail?.stakeholder?.shortName + '.');
            ShowApplyModal()
        }
    }, [appliedJobs])

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                if (applyModal) {
                    setPopUpText('You have applied for ' + jobDetail?.title + ' in ' + jobDetail?.countryDescription + ', ' + jobDetail?.cityText + ' with ' + jobDetail?.stakeholder?.shortName + '.');
                    ShowApplyModal();
                } else {
                    navigate('/jobs')
                }
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [applyModal]);

    const getCVOptionId = (_cv) => {
        return `${_cv.id}|${_cv.cv}`;
    }
    const getCVOptionLabel = (_cv) => {
        return `${_cv.cv} ${_cv.suffix || ''} `;
    }
    const getAllCVs = (_candidate, _jobApplications) => {

        let applications = [];

        if (_candidate.cv !== null) {
            const application = { id: '0', cv: `${_candidate.cv}`, suffix: '(from profile)' };
            applications.push(application);
            setValue(getCVOptionId(application));
        }

        if (allCVs.length === 0) {
            _jobApplications.forEach(ja => {
                if (ja.cv !== null && ja.cv != '') {
                        applications.push({
                            id: ja.id, cv: `${ja.cv}`, suffix: ' (from ' + ja.jobPost.title + ' job application on ' + moment(ja?.created).format("DD MMM YYYY") + ')'
                        })
                }
            });

            setAllCVs(applications);
        }
    }

    const getCandidateData = () => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            const option = {
                method: 'GET', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'application/json',
                    'XApiKey': `${XApiKey()}`,
                    'authorization': `Bearer ${candidateToken}`
                },
                url: `${Apiurl()}/candidate/get?id=${candidateId}`
            };

            axios(option)
                .then(e => {
                    const data = e?.data?.body || []
                    setCandidate(data);
                    resolve(data);
                })
                .catch((err) => {
                    setLoading(false);
                    if (err?.response?.status === 401) {
                        RemoveUserSession();
                    } else {
                        navigate('404')
                    }
                    reject(err);
                });
        })
    }

    const getJobByUrl = () => {
        return new Promise((resolve, reject) => {
            const option = {
                method: 'GET', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'application/json',
                    'XApiKey': `${XApiKey()}`,
                },
                url: `${Apiurl()}/jobpost/getbyurl?url=/Job${actualUrl}`,
            }
            axios(option)
                .then((e) => {
                    if (e?.status === 200) {
                        const data = e?.data || []
                        setJobDetail(data);
                        resolve(data);
                    }
                    if (e?.data?.id === 0) {
                        navigate('404')
                    }
                })
                .catch((err) => {
                    setLoading(false);
                })
        })
    }

    const applicationSubmit = () => {
        setLoading(true)
        if (!candidate?.id) return;
        if (viewCv) {
            if (!cv) {
                setLoading(false)
                toast.error("Upload your new CV");
                return false;
            }
        }
        const formData = new FormData();
        formData.append('jobpost.id', jobDetail?.id);
        formData.append('candidate.id', candidate?.id);
        formData.append('createdby', candidate?.id);
        (cv && viewCv === true) ? formData.append('cvfile', cv) : formData.append('existingcvdetails', value);

        const option = {
            method: "POST", headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            data: formData,
            url: `${Apiurl()}/jobapplications/create`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                if (e?.data?.statusCode === 200) {
                    setPopUpText('You have applied for ' + jobDetail?.title + ' in ' + jobDetail?.countryDescription + ', ' + jobDetail?.cityText + ' with ' + jobDetail?.stakeholder?.shortName + '.');
                    ShowApplyModal()
                }
                else if (e?.data?.statusCode === 500) {
                    setBacktoSearch('false')
                    setPopUpText(e?.data?.errorMessage)
                    ShowApplyModal()
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err?.response?.status === 401) {
                    RemoveUserSession();
                    navigate("/");
                }
            });
    }

    const navigateToStakeholder = () => {
        var type = "";
        if (jobDetail?.stakeholder?.type === "International Oil Company") {
            type = "ioc"
        } else if (jobDetail?.stakeholder?.type === "Refinery") {
            type = "refinery"
        } else if (jobDetail?.stakeholder?.type === "Pipeline Operator") {
            type = "pipelineoperator"
        } else if (jobDetail?.stakeholder?.type === "Oilfield Service Company") {
            type = "osc"
        }
        navigate(`/stakeholder/${type}/${jobDetail?.stakeholder?.shortName}`, { state: { stakeholder: jobDetail?.stakeholder } })

    }

    const getJobApplications = () => {
        return new Promise((resolve, reject) => {
            const option = {
                method: 'GET', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'application/json',
                    'XApiKey': `${XApiKey()}`,
                    'authorization': `Bearer ${candidateToken}`
                },
                url: `${Apiurl()}/jobapplications/getjobapplications?CandidateId=${candidateId}`
            };
            axios(option)
                .then(e => {
                    const data = e?.data || []
                    setJobApplication(data);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                    setLoading(false);
                });
        })
    }
    const onNavigateToCandidateProfile = () => {
        localStorage.setItem(`source-page`, `application`)
    }

    return (
        <div>
            {
                loading ? <Loader /> : null
            }
            <Header loading={loading} applyModal={applyModal} />
            <div id="applicationForm">
                <Container>
                    <Row className="application-form">
                        {/*<Col className="col-md-12">Job Application</Col>*/}
                        <Col className="col-md-6 application-sidebar">
                            <h2 className="job-info-application">Job Information</h2>
                            <div className="job-details-apply">
                                <div><i className="fa-regular fa-id-badge"></i><b>Job Title: </b>{jobDetail?.title}</div>
                                <div><i className="fa-solid fa-oil-well"></i><b>Job Sector: </b>{jobDetail?.subSector?.parent?.name}</div>
                                <div><i className="fa-regular fa-oil-well"></i><b>Sub Sector: </b>{jobDetail?.subSector?.name}</div>
                                <div><i className="fa-light fa-user-group"></i><b>Profession: </b>{jobDetail?.jobFamily?.name}</div>
                                <div><i className="fa-light fa-location-dot"></i><b>Location: </b>{jobDetail?.countryDescription}, {jobDetail?.cityText}</div>
                                <div><i className="fa-light fa-earth-asia"></i><b>Stakeholder: </b><span onClick={() => navigateToStakeholder()} className="application-stakeholder">{jobDetail?.stakeholder?.shortName}</span></div>
                            </div>
                        </Col>
                        <Col className="col-md-6 col-12">
                            <Form>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup className="general_info_input application-form-input">
                                            <Label for="applicantEmail" className="apply-job-cv">CV
                                                <span className="apply-newCv" title={viewCv ? "Upload an existing CV" : "Upload a new CV"} onClick={() => setViewCv(!viewCv)}>{viewCv ? "Upload an existing CV" : "Upload a new CV"}</span></Label>
                                            {
                                                viewCv ?
                                                    <Input id="applicantCv" name="uploadcv" accept="application/pdf" placeholder="Upload your CV" type="file" onChange={(e) => setCv(e?.target?.files?.[0])} className="application-Cv" /> :

                                                    <div className="side-line-signup">
                                                        <span className="arrow-down-application">&nbsp;</span>

                                                        <Input
                                                            id="exampleSelect"
                                                            name="select"
                                                            type="select"
                                                            value={value}
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                            {allCVs.map(cv => {

                                                                return (<option value={getCVOptionId(cv)}>{getCVOptionLabel(cv)}</option>)

                                                            })}

                                                        </Input>

                                                        <Link to="/candidateprofile" state="application" target="_blank" className="application-modal"
                                                            onClick={onNavigateToCandidateProfile}>
                                                            Click here to view your previous applications and CVs
                                                        </Link>
                                                    </div>
                                            }

                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>By applying you agree to the Terms and Conditions</Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>

                        <Col md={12}>
                            <div className="btn btn-primary submit application-submit" onClick={() => applicationSubmit()} title="Apply">Apply</div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer loading={loading} />


            <Modal show={applyModal} onHide={CloseApplyModal} className="login-modal" backdrop="static">
                <Modal.Body className="login-modal-body">
                    <div className="login-modal-header login-modal-card-main-section">
                        <div className="login-card-section">
                            <Row>
                                <Col md={12} className="left-section-login">
                                    <div className="card-login card-login-50">
                                        <div className="forgot-password-heading-inner text-center">
                                            <h3>
                                                {popupText}
                                            </h3>
                                            <div className="paddingbottom15">
                                                { backtoSearch ? null : <Link to="/Jobs" className="application-modal floatleft">Back to search</Link> }
                                                <Link to="/candidateprofile" state="application" className="application-modal floatright" title="View applications"
                                                    onClick={onNavigateToCandidateProfile} >
                                                    View applications
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    );


}
export default Application;