import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../PrivacyPolicy/PrivacyPolicy.css";
import { Col, Container, Row } from "react-bootstrap";
import { GetPortalTitle } from "../../utils/utils";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Apiurl, XApiKey } from '../../utils/utils';
import axios from 'axios';

const EmailValidate = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    let [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get("e")

    const GetPathName = window?.location?.search?.split("t=");
    const getToken = GetPathName?.[1];
    
    const validateEmail = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/candidate/validateemail?email=${email}&token=${getToken}`,
        }
        axios(option)
            .then((e) => {
                if (e?.data?.statusCode === 200) {
                    setMessage(e?.data?.successMessage);
                }
                else {
                    setMessage('Invalid request, please try with the correct link or contact portal support.');
                }
            })
            .catch((err) => {
                setLoading(false)
                navigate('404')
            })
    }

    useEffect(() => {
        document.title = `${GetPortalTitle('Verification')}`;
        localStorage.removeItem("filters");
        validateEmail()
    }, [])
    return (

        <div>
            <Header />

            <div className="privacypolicy-section privacypolicy-section-container">
                <Container>

                    <Row className="privacypolicy-row">

                        <Col className="col-lg-12">
                            <h2 className="mb-3 pt-4 privacypolicy-head">Your account email address verification</h2>
                        </Col>

                        <Col className="col-lg-12">
                            <p>
                                {message}
                            </p>
                        </Col>

                    </Row>

                </Container>
            </div>

            <Footer />
        </div>

    )
};

export default EmailValidate;