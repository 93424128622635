import React, { useState } from 'react';
import { Container, Row, Col, Form, Label, Input, FormGroup } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import { Modal } from 'react-bootstrap';
import { Apiurl, CandidateIdGet, GetPortalTitle, GetToken, RemoveUserSession, XApiKey, RemoveLocalStorage } from '../../../utils/utils';
import { toast } from 'react-toastify';
import axios from "axios";
import Loader from '../../../components/Loader/loader';
import moment from 'moment';


const InternApplication = () => {
    const navigate = useNavigate()
    const internUrl = window?.location?.pathname.toLowerCase();
    const actualUrl = internUrl?.split('/internshipapplication')[1];
    const [internDetail, setInternDetail] = useState([])
    const [applyModal, setApplyModal] = useState(false);
    const CloseApplyModal = () => setApplyModal(false);
    const ShowApplyModal = () => setApplyModal(true);
    const [candidate, setCandidate] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cv, setCv] = useState();
    const [viewCv, setViewCv] = useState(false);
    const candidateId = CandidateIdGet();
    const candidateToken = GetToken()
    const [internApplication, setInternApplication] = useState([])
    const [allCVs, setAllCVs] = useState([])
    const [backtoSearch, setBacktoSearch] = useState('');
    const [popupText, setPopUpText] = useState('');
    const appliedInterns = internApplication.find(item => item?.internship?.id === internDetail?.id)
    const [value, setValue] = useState('');
    const handleChange = (e) => {
        setValue(e.target.value);
    }

    useEffect(() => {
        getCandidateData().then((_candidate) => {
            getInternApplications().then(_internApplications => {
                getAllCVs(_candidate, _internApplications);
                setLoading(false);
            });
        });

        GetInternByUrl().then((_internDetail) => {
            document.title = `${GetPortalTitle('Application for ' + (_internDetail?.title))}`;
        })
        RemoveLocalStorage("filters");
        RemoveLocalStorage("Coursesfilters");
       
    }, [])

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                if (applyModal === true) {
                    setPopUpText('You have applied for ' + internDetail?.title + ' in ' + ((internDetail?.countryDescription) ? (internDetail?.countryDescription + ", ") : "") + internDetail?.city + ' with ' + internDetail?.company?.shortName + '.');
                    ShowApplyModal();
                } else {
                    navigate('/internships')
                }

            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [applyModal]);

    useEffect(() => {
        if (appliedInterns?.id) {
            setPopUpText('You have already applied for ' + internDetail?.title + ' in ' + ((internDetail?.countryDescription) ? (internDetail?.countryDescription + ", ") : "") + internDetail?.city + ' with ' + internDetail?.company?.shortName + '.');
            ShowApplyModal()
        }
    }, [appliedInterns?.id])

    const onNavigateToCandidateProfile = () => {
        localStorage.setItem(`source-page`, `internapplication`)
    }

    const getCVOptionId = (_cv) => {
        return `${_cv.id}|${_cv.cv}`;
    }
    const getCVOptionLabel = (_cv) => {
        return `${_cv.cv} ${_cv.suffix || ''} `;
    }

    const getAllCVs = (_candidate, _internApplications) => {

        let applications = [];

        if (_candidate.cv !== null) {
            const application = { id: '0', cv: `${_candidate.cv}`, suffix: '(from profile)' };
            applications.push(application);
            setValue(getCVOptionId(application));
        }

        if (allCVs.length === 0) {
            _internApplications.forEach(ia => {
                if (ia.cv !== null && ia.cv != '') {
                        applications.push({
                            id: ia.id, cv: `${ia.cv}`, suffix: ' (from ' + ia.internship.title + ' internship application on ' + moment(ia?.created).format("DD MMM YYYY") + ')'
                        })
                  }
            });

            setAllCVs(applications);
        }
    }

    const getCandidateData = () => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            const option = {
                method: 'GET', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'application/json',
                    'XApiKey': `${XApiKey()}`,
                    'authorization': `Bearer ${candidateToken}`
                },
                url: `${Apiurl()}/candidate/get?id=${candidateId}`

            };
            axios(option)
                .then(e => {
                    const data = e?.data?.body || []
                    setCandidate(data);
                    resolve(data);
                })
                .catch((err) => {
                    setLoading(false);
                    if (err?.response?.status === 401) {
                        RemoveUserSession();
                    } else {
                    }
                    reject(err);
                });
        })
    }

    const GetInternByUrl = () => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            const option = {
                method: 'GET', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'application/json',
                    'XApiKey': `${XApiKey()}`,
                },
                url: `${Apiurl()}/Internship/getbyurl?url=/Internship${actualUrl}`,

            }
            axios(option)
                .then((e) => {
                    setLoading(false)
                    if (e?.status === 200) {
                        setInternDetail(e?.data)
                        resolve(e?.data)
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    navigate('404')
                })
        })
    }

    const applicationSubmit = () => {
        if (!candidate?.id) return;
        if (viewCv) {
            if (!cv) {
                toast.error("Upload your new CV");
                return false;
            }
        }
        const formData = new FormData();
        formData.append('internshipid', internDetail?.id);
        formData.append('candidateid', candidate?.id);
        formData.append('statusstring', "Applied");
        formData.append('createdby', candidate?.id);
        (cv && viewCv === true) ? formData.append('cvfile', cv) : formData.append('existingcvdetails', value);

        const option = {
            method: "POST", headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            data: formData,
            url: `${Apiurl()}/internshipapplications/create`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                if (e?.data?.statusCode === 200) {
                    setPopUpText('You have applied for ' + internDetail?.title + ' in ' + ((internDetail?.countryDescription) ? (internDetail?.countryDescription + ", ") : "") + internDetail?.city + ' with ' + internDetail?.company?.shortName + '.');
                    ShowApplyModal()
                }
                else if (e?.data?.statusCode === 500) {
                    setBacktoSearch('false')
                    setPopUpText(e?.data?.errorMessage)
                    ShowApplyModal()
                }
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    RemoveUserSession();
                    navigate("/");
                } 
            });
    }

    const navigateToStakeholder = () => {
        var type = "";
        if (internDetail?.company?.type === "International Oil Company") {
            type = "ioc"
        } else if (internDetail?.company?.type === "Refinery") {
            type = "refinery"
        } else if (internDetail?.company?.type === "Pipeline Operator") {
            type = "pipelineoperator"
        } else if (internDetail?.company?.type === "Oilfield Service Company") {
            type = "osc"
        }
        navigate(`/stakeholder/${type}/${internDetail?.company?.shortName}`, { state: { stakeholder: internDetail?.company } })

    }

    const getInternApplications = () => {
        return new Promise((resolve, reject) => {
            const option = {
                method: 'GET', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'application/json',
                    'XApiKey': `${XApiKey()}`,
                    'authorization': `Bearer ${candidateToken}`
                },
                url: `${Apiurl()}/internshipapplications/getlist?candidateid=${candidateId}`
            };
            axios(option)
                .then(e => {
                    const data = e?.data || []
                    setInternApplication(data);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                    setLoading(false);
                });
        })
    }

    const onNavigateToInternshipsTab = () => {
        localStorage.setItem(`source-page`, `2`)
    }

    return (
        <div>
            {
                loading ? <Loader /> : null
            }
            <Header loading={loading} applyModal={applyModal} />
            <div id="applicationForm">
                <Container>
                    <Row className="application-form">
                        {/*<Col className="col-md-12">Job Application</Col>*/}
                        <Col className="col-md-6 application-sidebar">
                            <h2 className="job-info-application">Internship Information</h2>
                            <div className="job-details-apply">
                                <div><i className="fa-regular fa-id-badge"></i><b>Internship Title: </b>{internDetail?.title}</div>
                                <div><i className="fa-regular fa-location-dot"></i><b>Location: </b>{internDetail?.countryDescription ? internDetail?.countryDescription + ", " : null} {internDetail?.city}</div>
                                <div><i className="fa-light fa-earth-asia"></i><b>Stakeholder: </b><span onClick={() => navigateToStakeholder()} className="application-stakeholder">{internDetail?.company?.shortName}</span></div>
                            </div>
                        </Col>
                        <Col className="col-md-6 col-12">
                            <Form>
                                <Row>
                                   
                                    <Col md={12}>
                                        <FormGroup className="general_info_input application-form-input">
                                            <Label for="applicantEmail" className="apply-job-cv">CV<span className="apply-newCv" title={viewCv ? "Upload an existing CV" : "Upload a new CV" } onClick={() => setViewCv(!viewCv)}>{viewCv ? "Upload an existing CV" : "Upload a new CV"}</span></Label>
                                            {
                                                viewCv ? <Input id="applicantCv" name="uploadcv" accept="application/pdf" placeholder="Upload your CV" type="file" onChange={(e) => setCv(e?.target?.files?.[0])} className="application-Cv" /> : <div className="side-line-signup">
                                                    <span className="arrow-down-application">&nbsp;</span>
                                                    <Input
                                                        id="exampleSelect"
                                                        name="select"
                                                        value={value}
                                                        type="select"
                                                        onChange={(e) => handleChange(e)}
                                                    >
                                                        {allCVs.map(cv => {

                                                            return (<option value={getCVOptionId(cv)}>{getCVOptionLabel(cv)}</option>)

                                                        })}
                                                    </Input>
                                                    <Link to="/candidateprofile" target="_blank" className="application-modal" state="internapplication"
                                                        onClick={onNavigateToCandidateProfile}>Click here to view your previous applications and CVs</Link>
                                                </div>
                                            }

                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>By applying you agree to the Terms and Conditions</Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                       
                        <Col md={12}>
                            <div className="btn btn-primary submit application-submit" title="Apply" onClick={() => applicationSubmit()}>Apply</div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer loading={loading} />


            <Modal show={applyModal} onHide={CloseApplyModal} className="login-modal" backdrop="static">
                <Modal.Body className="login-modal-body">
                    <div className="login-modal-header login-modal-card-main-section">
                        <div className="login-card-section">
                            <Row>
                                <Col md={12} className="left-section-login">
                                    <div className="card-login card-login-50">
                                        <div className="forgot-password-heading-inner text-center">
                                            <h3>{ popupText }</h3>
                                            <div className="paddingbottom15">
                                                {backtoSearch ? null : <Link to="/internships" className="application-modal floatleft">Back to search</Link>}
                                                <Link to="/candidateprofile" state="internapplication" className="application-modal floatright" title="View applications"
                                                    onClick={onNavigateToCandidateProfile} >
                                                    View applications
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    );
}
export default InternApplication;