import React from "react";
import "./Guidance.css";
import { Col, Container, Row } from "react-bootstrap";
import guidance from "../../../img/applicant-guidance.svg";
import checked from "../../../img/checked.svg"
const Guidance = () => {
    return (
        
        <div className="guidance-section">
            <Container>
                <Row className="guidance-row">
                    <Col className="col-lg-12">
                        <h2 className="mb-3 pt-4 guidance-head">Applicant Guidance</h2>
                    </Col>
                </Row>
                <Row className="flex-lg-row-reverse">
                    <Col className="col-12 col-lg-6 mb-5 guidance-image">
                        <img src={guidance} className="d-block img-fluid" alt="MNR Job Portal - Guidance and rules" loading="lazy" title="Applicant Guidance" />
                    </Col>
                    <Col className="col-12 col-lg-6 mb-5">
                        <h3 className="mb-4 guidance-img-checked"><img src={checked} alt="checked" />&nbsp; Honesty</h3>
                        <p className="mb-5 guidance-data">Be honest on your CV and application. Dishonest applications will be found out in the verification process.</p>
                        <h3 className="mb-4 guidance-img-checked"><img src={checked} alt="checked" />&nbsp; Accuracy</h3>
                        <p className="mb-5 guidance-data">Ensure the information you provide is accurate, up-to-date and complete. Incomplete applications will not be verified.</p>
                        <h3 className="mb-4 guidance-img-checked"><img src={checked} alt="checked" />&nbsp; Suitability</h3>
                        <p className="mb-5 guidance-data">Employers receive many applications. To avoid getting lost in the crowd, we ask that you apply for roles where you meet the requirements.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default Guidance;