import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './hiring.css'

const Hiring = () => {
    return (
        <>
            <div className="hiring-section">
                <Container>
                    <Row className="hiring-row">
                        <Col md={12} className="mt-3">
                            <h1 className="hiring-head mb-3 pt-4">Hiring Process</h1>
                        </Col>
                    </Row>
                    <Row className="hiring-second-row">
                        <Col md="2" className="hiring-section">
                            <h4 className="col-heading">CANDIDATE REGISTRATION</h4>
                            <img className='col-img' src={require('../../../img/registration.svg').default} alt="CANDIDATE REGISTRATION" title="CANDIDATE REGISTRATION"></img>
                            <p className='col-para'>Candidate registers on the Job Portal and completes their profile</p>
                        </Col>
                        <Col className="col-12 col-lg-3 justify-content-center d-flex extra-arrow-hiring">
                            <img src={require('../../../img/mobile-arrow.svg').default} className="mx-auto img-fluid" width="46" loading="lazy" alt="CANDIDATE REGISTRATION" />
                        </Col>
                        <Col md="3" className='arrow-col'>
                            <img className='arrow-img' src={require('../../../img/arrow.svg').default} alt="CANDIDATE REGISTRATION"></img>
                        </Col>
                        <Col md="2" className="hiring-section">
                            <h4 className="col-heading">CANDIDATE VERIFICATION</h4>
                            <img className='col-img' src={require('../../../img/verification.svg').default} alt="CANDIDATE VERIFICATION" title="CANDIDATE VERIFICATION" ></img>
                            <p className='col-para'>Job Portal agents screen and verify candidate's profile</p>
                        </Col>
                        <Col className="col-12 col-lg-3 justify-content-center d-flex extra-arrow-hiring">
                            <img src={require('../../../img/mobile-arrow.svg').default} className="mx-auto img-fluid" width="46" loading="lazy" alt="CANDIDATE VERIFICATION"/>
                        </Col>
                        <Col md="3" className='arrow-col'>
                            <img className='arrow-img' src={require('../../../img/arrow-inverted.svg').default} alt="CANDIDATE VERIFICATION"></img>
                        </Col>
                        <Col md="2" className="hiring-section">
                            <h4 className="col-heading">JOB APPLICATION</h4>
                            <img className='col-img' src={require('../../../img/job-application.svg').default} alt="JOB APPLICATION" title="JOB APPLICATION"></img>
                            <p className='col-para'>Candidate applies for suitable jobs</p>
                        </Col>
                        <Col className="col-12 col-lg-3 justify-content-center d-flex extra-arrow-hiring">
                            <img src={require('../../../img/mobile-arrow.svg').default} className="mx-auto img-fluid" width="46" loading="lazy" alt="JOB APPLICATION" />
                        </Col>
                        <div className="hiring-section-inner-sct" style={{ display: 'flex' }}>
                            <Col md="2" className="hiring-section">
                                <h4 className="col-heading">CANDIDATE HIRED</h4>
                                <img className='col-img' src={require('../../../img/selection.svg').default} alt="CANDIDATE HIRED" title="CANDIDATE HIRED"></img>
                                <p className='col-para'>Candidate accepts the job offer</p>
                            </Col>
                            <Col className="col-12 col-lg-3 justify-content-center d-flex extra-arrow-hiring">
                                <img src={require('../../../img/mobile-arrow.svg').default} className="mx-auto img-fluid" width="46" loading="lazy" alt="CANDIDATE HIRED" />
                            </Col>
                            <Col md="3" className='arrow-col'>
                                <img className='arrow-img arrRotate' src={require('../../../img/arrow.svg').default} alt="CANDIDATE HIRED"></img>
                            </Col>
                            <Col md="2" className="hiring-section">
                                <h4 className="col-heading">JOB OFFER</h4>
                                <img className='col-img' src={require('../../../img/offer.svg').default} alt="JOB OFFER" title="JOB OFFER"></img>
                                <p className='col-para'>Employer makes a job offer to the candidate</p>
                            </Col>
                            <Col className="col-12 col-lg-3 justify-content-center d-flex extra-arrow-hiring">
                                <img src={require('../../../img/mobile-arrow.svg').default} className="mx-auto img-fluid" width="46" loading="lazy" alt="JOB OFFER" />
                            </Col>
                            <Col md="3" className='arrow-col'>
                                <img className='arrow-img arrRotate' src={require('../../../img/arrow-inverted.svg').default} alt="JOB OFFER"></img>
                            </Col>
                            <Col md="2" className="hiring-section">
                                <h4 className="col-heading">CANDIDATE SELECTION</h4>
                                <img className='col-img' src={require('../../../img/hired.svg').default} alt='CANDIDATE SELECTION' title="CANDIDATE SELECTION"></img>
                                <p className='col-para'>Employer selects candidate to participate in their recruitment process</p>
                            </Col>
                            <Col md="3" className='arrow-col-position'>
                                <img className='arrow-img' src={require('../../../img/arrow-inverted.svg').default} alt='CANDIDATE SELECTION'></img>
                            </Col>
                        </div>

                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Hiring