import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './Signup.css'
import { toast } from 'react-toastify';
import GeneralInfo from '../../components/newSignUp/GeneralInfo/GeneralInfo';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Apiurl, CandidateId, CandidateIdGet, SetToken, XApiKey, GetPortalTitle, RemoveLocalStorage } from '../../utils/utils';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from "moment";
const Signup = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const applyFrom = location?.state?.applyFrom;
    const jobData = location?.state?.jobData;
    const jobDetail = location?.state?.jobDetail;

    const internData = location?.state?.internData;
    const internDetail = location?.state?.internDetail;
    const courseData = location?.state?.courseData;
    const courseDetail = location?.state?.courseDetail;

    const [formValues, setformValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        birthDate: '',
        phoneCode: '',
        phone: '',
        internationalPhoneCode: '',
        interNationalPhone: '',
        residentCountry: '',
        ethnicity: '',
        birthGoverNate: '',
        birthPlace: '',
        residentAddress: '',
        nationality: '',
        iraqiCitizenShip: '',
        kurdistanCitizen: '',
        passportNumber: '',
        citizenNumber: '',
        seniorityLevel: '',
        jobFamily: '',
        familyExperience: '',
        oilExperience: '',
        highestQualification: '',
        gender: 'Male',
        passportFile: [],
        citizenshipDocument: [],
        cv: [],
        highestQualdocument: [],
        otherCertificates: [],
    })
    const emailRegex = /^[a-zA-Z0-9.!#$%&�*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const mobileRegex = /^\d+$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^?&\*])/;
    var firstNameVal = formValues?.firstName.toLowerCase();
    var lastNameVal = formValues?.lastName.toLowerCase();
    var passwordVal = formValues?.password.toLowerCase();
    var passwordtest = (passwordVal.indexOf(firstNameVal) >= 0 || passwordVal.indexOf(lastNameVal) >= 0);

    const goToTop = () => {
        window.scrollTo(0, 0);
    }

    const onChange = (event) => {
        const name = event?.target?.name
        const value = event?.target?.files ? event?.target?.files : event?.target?.value
        let localState = { ...formValues }
        localState[name] = value;
        setformValues({ ...localState })
    }
    const expatData = !(formValues.nationality === "104") ? "Foreign" : (formValues.nationality === "104" && formValues.iraqiCitizenShip === "true") ? "Local" : (formValues.nationality === "104" && formValues.iraqiCitizenShip === "false" && formValues?.kurdistanCitizen === "yes") ? "Local" : "National";

    const CancelSignup = () => {
        goToTop();
        navigate('/')
    }

    const generalInfoValidate = () => {
        if (!formValues?.firstName || formValues?.firstName.trim() === '') {
            toast.error("Please Enter First Name")
            return false;
        }
        if (!formValues?.lastName || formValues?.lastName.trim() === '') {
            toast.error("Please Enter Last Name")
            return false;
        }
        if (!formValues?.email) {
            toast.error("Please Enter Email Address")
            return false;
        }
        if (!emailRegex.test(formValues?.email)) {
            toast.error("Please Enter Valid Email Address")
            return false;
        }
        if (!formValues?.password) {
            toast.error("Please Enter Password")
            return false;
        }
        if (formValues?.password?.length < 8) {
            toast.error("Please Enter Password More Than 8 Characters")
            return false;
        }
        if (!passwordRegex.test(formValues?.password)) {
            toast.error("The password must include lowercase letters, uppercase letters, numbers and special characters @$!%*#.?&^")
            return false;
        }
        if (passwordtest) {
            toast.error("Password should not contain first name and last name")
            return false;
        }
        if (formValues?.phone?.length) {
            if (!formValues?.phoneCode) {
                toast.error("Please select Local Phone Country Code")
                return false;
            }
            if (!mobileRegex.test(formValues?.phone)) {
                toast.error("Please enter valid Local Phone")
                return false;
            }
            if (formValues?.phone?.length < 6) {
                toast.error("Local Phone number must be at least 6 digits")
                return false;
            }
        }
        if (formValues?.interNationalPhone?.length) {
            if (!formValues?.internationalPhoneCode) {
                toast.error("Please select International Phone Country Code")
                return false;
            }
            if (!mobileRegex.test(formValues?.interNationalPhone)) {
                toast.error("Please enter valid International Phone")
                return false;
            }
            if (formValues?.interNationalPhone?.length < 6) {
                toast.error("International Phone number must be at least 6 digits")
                return false;
            }
        }

        if (!formValues?.gender) {
            toast.error("Please Select Gender")
            return false;
        }
        if (formValues?.birthDate === moment(new Date()).format("YYYY-MM-DD")) {
            toast.error("Please Enter Date Of Birth")
            return false;
        }
        if (!formValues?.nationality) {
            toast.error("Please Select Nationality")
            return false;
        }
        if (!formValues?.residentCountry) {
            toast.error("Please Select Resident Country")
            return false;
        }
        if (!formValues?.iraqiCitizenShip && formValues?.nationality === "104") {
            toast.error("Please Select Iraqi Citizenship")
            return false;
        }
        if (!formValues?.residentAddress || formValues?.residentAddress.trim() === "") {
            toast.error("Please Enter Resident Address")
            return false;
        }
        return true;
    }

    const SignUpSubmit = () => {
        if (!generalInfoValidate()) return;
        const data = {};
        data.Email = formValues.email;
        data.FirstName = formValues.firstName;
        data.LastName = formValues.lastName;
        data.Password = formValues.password;
        data.LocalPhoneNoCountryId = Number(formValues.phoneCode);
        data.LocalPhoneNo = formValues.phone;
        data.InternationalPhoneNoCountryId = Number(formValues.internationalPhoneCode);
        data.InternationalPhoneNo = formValues.interNationalPhone;
        data.Sex = formValues.gender;
        data.BirthDate = formValues.birthDate;
        data.NationalityId = Number(formValues.nationality);
        data.ResidentAddress = formValues.residentAddress;
        data.ResidentCountryId = Number(formValues.residentCountry);
        data.HasCitizenship = formValues.nationality === "104" ? formValues.iraqiCitizenShip === "true" : null;
        data.Expat = expatData;
        const option = {
            method: "POST", headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`
            },
            data: data,
            url: `${Apiurl()}/candidate/signup`
        };
        axios(option)
            .then(e => {
                if (e?.data?.statusCode === 500) {
                    toast.error(e?.data?.errorMessage)
                } else {
                    localStorage.setItem('successemail', data.Email);
                    navigate("/signupsuccess")
                }

            })
            .catch((err) => {
            });
    }

    useEffect(() => {
        if (CandidateIdGet() != null) {
            navigate('/candidateprofile')
        }
        document.title = `${GetPortalTitle('General Info')}`;
        RemoveLocalStorage("filters");
        RemoveLocalStorage("InternShipfilters");
        RemoveLocalStorage("Coursesfilters");
    }, [])
    return (
        <>
            <Header />
            <Container className="mb-5 sign-up-main-container">
                <Row className="signup-row">
                    <Col md={12}>
                        <div className='card-login'>
                            <h1 className="heading">Create your account</h1>
                            <GeneralInfo
                                onChange={onChange}
                                formValues={formValues}
                                setformValues={setformValues}
                            />
                            <Row className="d-flex justify-content-end mt-3">
                                <Col md={2} className="profile-inner-btn"> <Button variant="success" className="next-btn-signup" title="Cancel" onClick={() => CancelSignup()}>Cancel</Button></Col>
                                <Col md={2} className="profile-inner-btn profile-btn-mob">
                                    <Button variant="success" className="next-btn-signup" title="Next" onClick={() => SignUpSubmit()}>Submit</Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default Signup;