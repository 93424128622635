import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './featuredCandidate.css';

const FeaturedCandidate = () => {
    const state = {
        responsive: {
            0: {
                items: 1,
            },
            1000: {
                items: 3,
            },
        },
    }
    return (
        <div className='featuredSection pt-5 pb-5'>
            <div className="container">
                <OwlCarousel id="featuredCandidateScroller" items={3} margin={8} center={true} autoplay={true} autoplayTimeout={5000} loop={true} dots={true} responsive={state.responsive} >
                    <div className="feturedContainer">
                        <img className="featuredImage" src={'assets/images/testimonials/featuredCandidate1.png'} alt="Featured Candidate" />
                        <img className="img" src={'assets/images/testimonials/quote.svg'} width="42" height="42" alt="Featured Candidate" />
                        <h4>Featured Candidate</h4>
                        <p>I truly believe working with the region is a great achievement. Great salary and management are friendly. A very balanced place to work with the ability to work as much shift if so desired. Opportunity for growth.</p>
                    </div>
                    <div className="feturedContainer" >
                        <img className="featuredImage" src={'assets/images/testimonials/featuredCandidate2.png'} alt="Featured Candidate" />
                        <img className="img" src={'assets/images/testimonials/quote.svg'} width="42" height="42" alt="Featured Candidate" />
                        <h4>Featured Candidate</h4>
                        <p>Love my work within the Region. Can't really saw anything bad. There are a variety of areas to learn and I'm finding that I learn something new every day.".</p>
                    </div>
                    <div className="feturedContainer" >
                        <img className="featuredImage" src={'assets/images/testimonials/featuredCandidate3.png'} alt="Featured Candidate" />
                        <img className="img" src={'assets/images/testimonials/quote.svg'} width="42" height="42" alt="Featured Candidate" />
                        <h4>Featured Candidate</h4>
                        <p>Good work life balance. Good benefits. Opportunity to advance. Variety of jobs. Service jobs with high level of job satisfaction. Expanding work force each year.</p>
                    </div>
                    <div className="feturedContainer" >
                        <img className="featuredImage" src={'assets/images/testimonials/testimonial-avatar.png'} alt="Featured Candidate" />
                        <img className="img" src={'assets/images/testimonials/quote.svg'} width="42" height="42" alt="Featured Candidate" />
                        <h4>Featured Candidate</h4>
                        <p>To say that starting to work in the region has been life-changing is an understatement. My career is back on track and I got my life back.</p>
                    </div>
                </OwlCarousel>
            </div>
        </div>
    )
}



export default FeaturedCandidate;