import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./PrivacyPolicy.css";
import { Col, Container, Row } from "react-bootstrap";
import checked from "../../img/checked.svg"
import { GetPortalTitle, RemoveLocalStorage } from "../../utils/utils";

const PrivacyPolicy = () => {

    useEffect(() => {
        document.title = `${GetPortalTitle('Privacy Policy')}`;
        RemoveLocalStorage("filters");
        RemoveLocalStorage("InternShipfilters");
        RemoveLocalStorage("Coursesfilters");
    }, [])
    return (

        <div>
            <Header />

            <div className="privacypolicy-section privacypolicy-section-container">
                <Container>

                    <Row className="privacypolicy-row">

                        <Col className="col-lg-12">
                            <h2 className="mb-3 pt-4 privacypolicy-head">Privacy Policy</h2>
                        </Col>

                        <Col className="col-lg-12">
                            <h5>
                                <em>This privacy policy is for this website ({window?.location?.protocol + '//' + window?.location?.hostname}) and governs the privacy of its users.</em>
                            </h5>
                            <p>
                                The policy sets out the different areas where user privacy is concerned and outlines the obligations & requirements of the users, the website and website owners. Furthermore the way this website processes, stores and protects user data and information will also be detailed within this policy.
                            </p>
                        </Col>

                    </Row>

                    <Row className="privacypolicy-body">

                        <Col className="col-lg-12">

                            <h3 className="privacypolicy-img-checked"><img src={checked} alt="The Website" title="The Website" />&nbsp; The Website</h3>
                            <p className="privacypolicy-data">This website and its owners take a proactive approach to user privacy and ensure the necessary steps are taken to protect the privacy of its users throughout their visiting experience. This website complies with all United Arab Emirates national laws and requirements for user privacy.</p>

                            <h3 className="privacypolicy-img-checked"><img src={checked} alt="Contact & Communication" title="Contact & Communication" />&nbsp; Contact & Communication</h3>
                            <p className="privacypolicy-data">Users contacting this website and/or its owners do so at their own discretion and provide any such personal details requested at their own risk. Your personal information is kept private and stored securely until a time it is no longer required or has no use. This website and its owners use any information submitted to provide you with further information about the products/services they offer or to assist you in answering any questions or queries you may have submitted. This includes using your details to subscribe you to any email newsletter program the website operates but only if this was made clear to you and your express permission was granted when submitting any form to email process. Or whereby you have previously purchased from or enquired about purchasing a product or service that the email newsletter relates to. Your details are not passed on to any third parties.</p>

                            <h3 className="privacypolicy-img-checked"><img src={checked} alt="External Links" title="External Links" />&nbsp; External Links</h3>
                            <p className="privacypolicy-data">Although this website only looks to include quality, safe and relevant external links, users are advised to adopt a policy of caution before clicking any external web links mentioned throughout this website. The owners of this website cannot guarantee or verify the contents of any externally linked website despite their best efforts. Users should therefore note they click on external links at their own risk and this website and its owners cannot be held liable for any damages or implications caused by visiting any external links mentioned.</p>

                            <h3 className="privacypolicy-img-checked"><img src={checked} alt="Applicable Law" title="Applicable Law" />&nbsp; Applicable Law</h3>
                            <p className="privacypolicy-data">Applicable laws of the United Arab Emirates only shall be implemented in connection to any disputes arising from using this website. In addition, courts of the United Arab Emirates shall be the exclusive jurisdiction to consider and settle such disputes.</p>

                        </Col>

                    </Row>

                </Container>
            </div>

            <Footer />
        </div>

    )
};

export default PrivacyPolicy;