import { components } from "react-select";

const Option = (props) => {
    return (
        <div className="left-align">
            <components.Option {...props}>
                {
                    <input
                        type="checkbox"
                        className={props?.data?.groupItem !== "0" ? "chkOption marginleft20" : "chkOption"}
                        checked={props.isSelected}
                        onChange={() => null}
                    />
                }{" "}
                <label className="fontsize-dropdown">{props?.label}</label>
            </components.Option>
        </div>
    );
};

export default Option;