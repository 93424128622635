import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import IraqCity from "../DropDownDataProfile/IraqCity";
import "./GeneralInfoProfile.css";
import { XApiKey, Apiurl } from "../../../utils/utils";
import moment from "moment";
import DatePicker from "react-multi-date-picker"
import {FiChevronDown} from "react-icons/fi"

const GeneralInfoProfile = ({ onChange, formValues, candidate, setformValues }) => {
    const [pwdtype, setPwdtype] = useState('password');
    const [showPassword, setShowPassword] = useState(true);
    const [countries, setCountries] = useState([]);
    const currentTime = new Date();
    const convertTime = moment(currentTime).format("YYYY/MM/DD");
    const [date, setDate] = useState();
    const birthDateNow = moment(date?.toDate?.().toString()).format("YYYY-MM-DD")
    useEffect(() => {
        setformValues({
            ...formValues,
            birthDate: birthDateNow
        })
    }, [date])

    const showpassword = () => {
        if (pwdtype === 'password') {
            setShowPassword(false);
            setPwdtype("text");
        } else {
            setShowPassword(true);
            setPwdtype("password");
        }
    }
    const getCountries = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin':'*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`
            },
            url: `${Apiurl()}/candidate/countries`
        };
        axios(option)
            .then(e => {
                setCountries(e?.data);
            })
            .catch((err) => {
            });
    }
    useEffect(() => {
        getCountries();
    }, [])

    return (
        <Container>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3 general_info_input">
                        <Form.Label>First Name<span>*</span></Form.Label>
                        <Form.Control type="text" placeholder="" onChange={onChange}
                            value={formValues?.firstName}
                            name="firstName" />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3 general_info_input">
                        <Form.Label>Last Name<span>*</span></Form.Label>
                        <Form.Control type="text" placeholder="" onChange={onChange}
                            value={formValues?.lastName}
                            name="lastName" />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3 general_info_input">
                        <Form.Label>Local Phone</Form.Label>
                        <InputGroup className="mb-3">
                            <select id="phoneCode" className="input-group-dropdown-1 new-phone-mobile" onChange={onChange}
                                value={formValues?.phoneCode}
                                name="phoneCode">
                                <option className="option-dropdown" value=""  disabled>Country Code</option>
                                {
                                    countries.map((item,i) => {
                                        return (
                                            <Fragment key={i}>
                                            <option value={item?.id}>{item?.nameAndCode}</option>
                                            </Fragment>
                                        );
                                    })
                                }
                            </select>
                            <Form.Control type="number" onChange={onChange}
                                value={formValues?.phone}
                                name="phone" className="new-phone-mobile" />
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3 general_info_input" >
                        <Form.Label>International Phone</Form.Label>
                        <InputGroup className="mb-3">
                            <select id="internationalPhoneCode" className="input-group-dropdown-1 new-phone-mobile" onChange={onChange}
                                value={formValues?.internationalPhoneCode}
                                name="internationalPhoneCode">
                                <option className="option-dropdown" value=""  disabled>Country Code</option>
                                {
                                    countries.map((item,i) => {
                                        return (
                                            <Fragment key={i}>
                                            <option value={item?.id}>{item?.nameAndCode}</option>
                                        </Fragment>
                                        );
                                    })
                                }
                            </select>
                            <Form.Control type="number" onChange={onChange}
                                value={formValues?.interNationalPhone}
                                name="interNationalPhone" className="new-phone-mobile" />
                        </InputGroup>

                    </Form.Group>
                </Col>
                <Col md={6} className="gender-profile-mob">
                    <Form.Group key={`inline-radio`} className="mb-3 general_info_input ">
                        <Form.Label>Sex<span>*</span></Form.Label>
                        <div className="my-2">
                            <Form.Check
                                inline
                                label="Male"
                                name="gender"
                                type={'radio'}
                                id={`inline-${'radio'}-1`}
                                value="Male"
                                onChange={onChange}
                                checked={formValues?.gender === 'Male'}
                            />
                            <Form.Check
                                inline
                                label="Female"
                                name="gender"
                                type={'radio'}
                                id={`inline-${'radio'}-2`}
                                value="Female"
                                onChange={onChange}
                                checked={formValues?.gender === 'Female'}
                            />
                        </div>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3 general_info_input date-picker-signup">
                        <Form.Label>Date of Birth<span>*</span></Form.Label>
                        <DatePicker
                            value={date ? date : new Date(candidate?.birthDate)}
                            format="DD/MM/YYYY"
                            name="birthDate"
                            onChange={setDate}
                            minDate="1900/01/01"
                            maxDate={convertTime}
                            placeholder="DD/MM/YYYY"
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3 general_info_input">
                        <Form.Label>Nationality<span>*</span></Form.Label>
                        <div className="side-line-signup">
                        <span className="arrow-down-signup">&nbsp;</span>
                        <Form.Select aria-label="Default select example" onChange={onChange}
                            value={formValues?.nationality}
                            name="nationality">
                            <option className="option-dropdown" value="" disabled>--- Select Nationality ---</option>
                            {
                                countries?.map((item,i) => {
                                    return (
                                        <Fragment key={i}>
                                            <option value={item?.id} >{item?.name}</option>
                                        </Fragment>
                                    )
                                })
                            }
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3 general_info_input">
                        <Form.Label>Resident Country<span>*</span></Form.Label>
                        <div className="side-line-signup">
                        <span className="arrow-down-signup">&nbsp;</span>
                        <Form.Select aria-label="Default select example" onChange={onChange}
                            value={formValues?.residentCountry}
                            name="residentCountry">
                            <option className="option-dropdown" value="" disabled>--- Select Resident Country ---</option>
                            {
                                countries?.map((item,i) => {
                                    return (
                                        <Fragment key={i}>
                                            <option value={item?.id} >{item?.name}</option>
                                        </Fragment>
                                    )
                                })
                            }
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                {
                    formValues?.nationality === "104" || formValues?.nationality === 104 ? <>
                        <Col md={12}>
                            <Form.Group className="mb-3 general_info_input general-info-width">
                                <Form.Label>Do you have Iraqi Citizenship issued in the Kurdistan Region or province of Kirkuk?<span>*</span></Form.Label>
                                <div className="side-line-signup">
                                    <span className="arrow-down-signup">&nbsp;</span>
                                <Form.Select aria-label="Default select example" onChange={onChange}
                                    value={formValues?.iraqiCitizenShip}
                                    name="iraqiCitizenShip" >
                                    <option className="option-dropdown" value="" selected  disabled>--Select--</option>
                                    <option value={ true}>Yes</option>
                                    <option value={false}>No</option>
                                    </Form.Select>
                                </div>
                            </Form.Group>
                        </Col>
                    </> : null
                }



                <Col md={12}>
                    <Form.Group className="mb-3 general_info_input">
                        <Form.Label>Resident Address<span>*</span></Form.Label>
                        <Form.Control as="textarea" rows={3} className="general-address" onChange={onChange}
                            value={formValues?.residentAddress}
                            name="residentAddress" placeholder="Resident Address" />
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    )
}

export default GeneralInfoProfile;