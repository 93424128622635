import React, { useEffect, useState, useRef } from 'react';
import {
    Container, Row, Col, Button, Form, Label, Input, FormGroup, CardColumns,
    Card, CardImg, CardBody, CardTitle, Pagination, PaginationItem, PaginationLink, UncontrolledCollapse
} from 'reactstrap';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { default as ReactSelect } from "react-select";
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { Apiurl, GetPortalTitle, XApiKey, CandidateIdGet, SetLocalStorage, GetLocalStorage, RemoveLocalStorage } from '../utils/utils';
import axios from 'axios';
import moment from 'moment';
import Loader from '../components/Loader/loader';
import Option from '../components/Option';

const Jobs = () => {
    const selectLocationInputRef = useRef();
    const selectJobSectorInputRef = useRef();
    const selectJobFamilyInputRef = useRef();
    const selectStakeholderInputRef = useRef();

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [jobsLength, setJobsLength] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [sortBy, setSortBy] = useState("");
    const [filterJobFamily, setFilterJobFamily] = useState();
    const [filterjobFamilyArray, setFilterJobFamilyArray] = useState([]);
    const [keyword, setKeyword] = useState(location?.state?.search || '');
    const [reloadList, setReloadList] = useState(false);

    const [searchfilter, removeSearchFilter] = useState(false);
    const [perPage, setPerPage] = useState(20);
    const [showingFrom, setShowingFrom] = useState(1);
    const [showingTo, setShowingTo] = useState(perPage);
    const [filterLocation, setFilterLocation] = useState();
    const [filterLocationArray, setFilterLocationArray] = useState([]);
    const [filterJobSector, setFilterJobSector] = useState(location?.state?.jobsector || '');
    const [filterJobSectorArray, setFilterJobSectorArray] = useState(location?.state?.jobsector ? location?.state?.jobsector : []);
    const [pageNumber, setPageNumber] = useState(1);
    const [allFilterData, setAllFilterData] = useState([])
    const dropdownLocation = allFilterData.filter(item => item?.type === "Country")
    const dropdownJobSector = allFilterData.filter(item => item?.type === "Sector")
    const dropdownJobFamily = allFilterData?.filter(item => item?.type === "JobFamilyId")?.sort(function (a, b) {
        if (a?.text < b?.text) {
            return -1;
        }
        if (a?.text > b?.text) {
            return 1;
        }
        return 0;
    });
    const dropdownDatePosted = allFilterData.filter(item => item?.type === "Created")
    const dropdownEmploymentype = allFilterData.filter(item => item?.type === "EmploymentTypes")
    const dropdownOfferedSalary = allFilterData.filter(item => item?.type === "Salaries")
    const dropdownWorkplace = allFilterData.filter(item => item?.type === "Workplaces")
    const dropdownSeniorityLevel = allFilterData.filter(item => item?.type === "Seniorities")
    const dropdownStakeholders = allFilterData.filter(item => item?.type === "Stakeholders");
    const [pageArray, setPageArray] = useState([]);
    const [perpageArray, setPerpageArray] = useState([]);
    const [datePosted, setDatePosted] = useState("");

    const [searchtext, setSearchText] = useState();
    const [searchLocation, setSearchLocation] = useState();
    const [searchJobSector, setSearchJobSector] = useState();
    const [searchJobFamily, setSearchJobFamily] = useState();
    const [searchStakeholders, setSearchStakeholders] = useState();
    const [searchWorkPlace, setSearchWorkPlace] = useState();
    const [searchSeniorityLevel, setSearchSeniorityLevel] = useState();
    const [searchEmployeementType, setSearchEmployeementType] = useState();
    const [searchSalary, setSearchSalary] = useState();
    const [searchDatePosted, setSearchDatePosted] = useState();
    const filterData = JSON.parse(GetLocalStorage("filters"));

    const goToTop = () => {
        window.scrollTo(0, 0);
    }

    const handleLocationChange = (selected, event) => {
        if (event.action === 'deselect-option' && event.option.groupItem === '0') {
            selected = selected.filter(x => x.groupItem != event.option.value);
        }
        if (event.action === 'deselect-option' && event.option.groupItem !== '0') {
            selected = selected.filter(x => x.value != event.option.groupItem);
        }
        else if (event.action === 'select-option' && event.option.groupItem === '0') {
            selected = selected.concat(dropdownLocation.filter(x => x.groupItem === event.option.value));
        }
        else if (event.action === 'select-option' && event.option.groupItem !== '0') {
            var selectedcount = selected.filter(x => x.groupItem === event.option.groupItem).length;
            var defaultcount = dropdownLocation.filter(x => x.groupItem === event.option.groupItem).length;
            if (selectedcount === defaultcount) {
                selected = selected.concat(dropdownLocation.filter(x => x.value === event.option.groupItem));
            }
        }

        const result = [];
        const map = new Map();
        for (const item of selected) {
            if (!map.has(item.value)) {
                map.set(item.value, true);    // set any value to Map
                result.push(item);
            }
        }
        setFilterLocationArray(result);
    };

    const handleJobSectorChange = (selected, event) => {
        if (event.action === 'deselect-option' && event.option.groupItem === '0') {
            selected = selected.filter(x => x.groupItem !== event.option.value);
        }
        if (event.action === 'deselect-option' && event.option.groupItem !== '0') {
            selected = selected.filter(x => x.value !== event.option.groupItem);
        }
        else if (event.action === 'select-option' && event.option.groupItem === '0') {
            selected = selected.concat(dropdownJobSector.filter(x => x.groupItem === event.option.value));
        }
        else if (event.action === 'select-option' && event.option.groupItem !== '0') {
            var selectedcount = selected.filter(x => x.groupItem === event.option.groupItem).length;
            var defaultcount = dropdownJobSector.filter(x => x.groupItem === event.option.groupItem).length;
            if (selectedcount === defaultcount) {
                selected = selected.concat(dropdownJobSector.filter(x => x.value === event.option.groupItem));
            }
        }

        const result = [];
        const map = new Map();
        for (const item of selected) {
            if (!map.has(item.value)) {
                map.set(item.value, true);    // set any value to Map
                result.push(item);
            }
        }
        setFilterJobSectorArray(result);
    };

    const handleStakeholderChange = (selected) => {
        setStakeholdersArray(selected);
    };

    const handleJobFamilyChange = (selected) => {
        setFilterJobFamilyArray(selected);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            getJobsByFilter();
        }
    }

    const getallFilterData = () => {
        setLoading(true);
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/jobpost/getsearchoptions`
        };
        axios(option)
            .then(e => {
                setAllFilterData(e?.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                if (err?.response?.status === 401) {
                }
            });
    }

    const onError = (e) => {
        e.target.src = 'assets/images/logos/dummy-logo.webp'
    }

    //filter function starts from here
    //filter by salary
    const [minimunSalary, setMinSalary] = useState('');
    const filterOfferedSalary = (e) => {
        setMinSalary(e?.target?.value);
    }

    //filter by employee type
    const [empTypeArray, setEmpTypeArray] = useState([]);
    const [empTypeValues, setEmpTypeValues] = useState("");
    const filterEmployeementType = (e) => {
        if (e?.target?.checked) {
            setEmpTypeArray([...empTypeArray, e.target.value])
        } else {
            const data = empTypeArray?.filter((item) => item != e?.target?.value)
            setEmpTypeArray(data);
        };
    }
    useEffect(() => {
        const data = empTypeArray?.map((item) => `${item}`).join(', ');
        setEmpTypeValues(data)
    }, [empTypeArray])

    //filter by work place
    const [workplaceArray, setWorkplaceArray] = useState([]);
    const [workplaceValues, setWorkplaceValues] = useState("");
    const filterWorkplaces = (e) => {
        if (e?.target?.checked) {
            setWorkplaceArray([...workplaceArray, e.target.value])
        } else {
            const data = workplaceArray?.filter((item) => item != e?.target?.value)
            setWorkplaceArray(data);
        };
    }
    useEffect(() => {
        const data = workplaceArray?.map((item) => `${item}`).join(', ');
        setWorkplaceValues(data)
    }, [workplaceArray])

    //filter by seniority level
    const [senioritylevelArray, setSenioritylevelArray] = useState([]);
    const [senioritylevelValues, setSenioritylevelValues] = useState("");
    const filterSeniorityLevel = (e) => {
        if (e?.target?.checked) {
            setSenioritylevelArray([...senioritylevelArray, e.target.value])
        } else {
            const data = senioritylevelArray?.filter((item) => item != e?.target?.value)
            setSenioritylevelArray(data);
        };
    }
    useEffect(() => {
        const data = senioritylevelArray?.map((item) => `${item}`).join(', ');
        setSenioritylevelValues(data)
    }, [senioritylevelArray])

    //filter by stakeholders
    const [stakeholdersArray, setStakeholdersArray] = useState([]);
    const [stakeholdersValues, setStakeholdersValues] = useState("");
    useEffect(() => {
        const data = stakeholdersArray?.map((item) => `${item.value}`).join(', ');
        setStakeholdersValues(data)
    }, [stakeholdersArray])

    useEffect(() => {
        const data = filterLocationArray?.map((item) => `${item.value}`).join(', ');
        setFilterLocation(data)
    }, [filterLocationArray])

    useEffect(() => {
        const data = filterJobSectorArray?.map((item) => `${item.value}`).join(', ');
        setFilterJobSector(data)
    }, [filterJobSectorArray])

    useEffect(() => {
        const data = filterjobFamilyArray?.map((item) => `${item.value}`).join(', ');
        setFilterJobFamily(data)
    }, [filterjobFamilyArray])

    const getJobsByFilter = () => {
        setLoading(true);

        //--

        setSearchText('');
        setSearchLocation('');
        setSearchJobSector('');
        setSearchJobFamily('');
        setSearchStakeholders('');
        setSearchWorkPlace('');
        setSearchSeniorityLevel('');
        setSearchEmployeementType('');
        setSearchSalary('');
        setSearchDatePosted('');

        //--

        const data = {}
        data.pageno = pageNumber;
        if (perPage) { data.perpage = perPage }
        if (sortBy) { data.sortby = sortBy }
        if (keyword) {
            data.text = filterData?.searchtext || keyword;
            setSearchText(keyword);
        }

        if (filterData?.searchLocation?.length || filterLocation) {
        data.location = filterData?.searchLocation?.map((item) => `${item.value}`).join(', ') || filterLocation;
            setSearchLocation("Location (" + filterData?.searchLocation?.length || filterLocationArray.length + ")");
        }
        if (filterData?.searchJobSector?.length || filterJobSector) {
            var jobsectorid = location?.state?.jobsector?.map((item) => `${item.value}`).join(', ')
            data.subsectors = jobsectorid ? jobsectorid : filterData?.searchJobSector?.map((item) => `${item.value}`).join(', ') || filterJobSector;
            if (filterJobSectorArray.length === 0) {
                setSearchJobSector("Job Sector (1)");
            }
            else {
                setSearchJobSector("Job Sector (" + filterData?.searchJobSector?.length || filterJobSectorArray.length + ")");
            }
        }
        if (filterData?.searchJobFamily?.length || filterJobFamily) {
            data.jobfamilies = filterData?.searchJobFamily?.map((item) => `${item.value}`).join(', ')  || filterJobFamily;
            setSearchJobFamily("Profession (" + filterData?.searchJobFamily?.length || filterjobFamilyArray.length + ")");
        }
        if (empTypeValues) {
            data.employmenttypes = empTypeValues;
            setSearchEmployeementType("Employment Type (" + empTypeArray.length + ")");
        }
        if (workplaceValues) {
            data.workplaces = workplaceValues;
            setSearchWorkPlace("Work Place (" + workplaceArray.length + ")");
        }
        if (senioritylevelValues) {
            data.seniorities = senioritylevelValues;
            setSearchSeniorityLevel("Seniority Level (" + senioritylevelArray.length + ")");
        }
        if (filterData?.searchStakeholders?.length || stakeholdersValues) {
            data.stakeholders = filterData?.searchStakeholders?.map((item) => `${item.value}`).join(', ') || stakeholdersValues;
            setSearchStakeholders("Stakeholder (" + filterData?.searchStakeholders?.length || stakeholdersArray.length + ")");
        }
        if (datePosted) {
            data.dateposted = datePosted;
            if (datePosted === "LastHour") {
                setSearchDatePosted("Date Posted (Last Hour)");
            }
            else if (datePosted === "Last24Hours") {
                setSearchDatePosted("Date Posted (Last 24 Hours)");
            }
            else if (datePosted === "Last7Days") {
                setSearchDatePosted("Date Posted (Last 7 Days)");
            }
            else if (datePosted === "Last14Days") {
                setSearchDatePosted("Date Posted (Last 14 Days)");
            }
            else if (datePosted === "Last30Days") {
                setSearchDatePosted("Date Posted (Last 30 Days)");
            }
        }
        if (minimunSalary != Infinity && minimunSalary != "" && minimunSalary != "undefined" && minimunSalary != undefined) {
            data.minSalary = minimunSalary.replaceAll(",", "").replaceAll("$", "").replaceAll("+", "");
            setSearchSalary("Salary (" + minimunSalary + ")");
        }

        const candidateId = CandidateIdGet();
        if (candidateId) { data.candidateId = candidateId }

        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            data: JSON.stringify(data),
            url: `${Apiurl()}/jobpost/getjobposts`,
        }
        axios(option)
            .then((e) => {
                if (e?.status === 200) {
                    if (e?.data?.length > 0) {
                        setJobsLength(e?.data[0]?.totalItems);
                        setTotalPages(e?.data[0]?.totalPages)
                        setJobs(e?.data);
                    } else {
                        setJobs([]);
                        setJobsLength(0);
                        setTotalPages(0);
                    }
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    const pagination = () => {
        let arr = [];
        for (let i = 1; i <= totalPages; i++) {
            arr.push(i);
        }
        setPageArray(arr)
    }
    useEffect(() => {
        pagination();
    }, [totalPages])

    const getperpage = () => {
        let arr = [];
        if (jobsLength <= 10) { return }
        else {
            let len = Math.ceil(jobsLength / 10);
            for (let i = 1; i <= len; i++) {
                arr.push(i);
            }
            setPerpageArray(arr)
        }
    }
    useEffect(() => {
        getperpage();
    }, [jobsLength])

    const perPageChange = (e) => {
        setPerPage(Number(e.target.value));
        setPageNumber(1)
        setShowingFrom(1);
        setShowingTo(Number(e.target.value))
        setPerPage(Number(e.target.value));
    }

    const changePage = (e) => {
        if (e <= 0) { return }
        if (e > pageArray?.length) { return }
        setPageNumber(e);
        setShowingFrom((perPage * e) - (perPage - 1));
        setShowingTo((perPage * e))
        if (pageNumber != e) { goToTop() }
    }

    const resetFilters = (key) => {

        if (key === 'keyword') {
            setKeyword('');
            setSearchText('');
            const data = {
                searchtext: "",
                searchLocation: filterLocationArray,
                searchJobSector: filterJobSectorArray,
                searchJobFamily: filterjobFamilyArray,
                searchStakeholders: stakeholdersArray,
                stakeholdersValues: stakeholdersValues,
            }
            SetLocalStorage("filters", JSON.stringify(data));
        }
        else if (key === 'location') {
            setSearchLocation('');
            setFilterLocation('');
            setFilterLocationArray([]);
            selectLocationInputRef.current.clearValue();
            const data = {
                searchtext: keyword?.trim(),
                searchLocation: [],
                searchJobSector: filterJobSectorArray,
                searchJobFamily: filterjobFamilyArray,
                searchStakeholders: stakeholdersArray,
                stakeholdersValues: stakeholdersValues,
            }
            SetLocalStorage("filters", JSON.stringify(data));
        }
        else if (key === 'jobsector') {
            setSearchJobSector('');
            setFilterJobSector('');
            setFilterJobSectorArray([]);
            selectJobSectorInputRef.current.clearValue();
            const data = {
                searchtext: keyword?.trim(),
                searchLocation: filterLocationArray,
                searchJobSector: [],
                searchJobFamily: filterjobFamilyArray,
                searchStakeholders: stakeholdersArray,
                stakeholdersValues: stakeholdersValues,
            }
            SetLocalStorage("filters", JSON.stringify(data));
        }
        else if (key === 'jobfamily') {
            setSearchJobFamily('');
            setFilterJobFamily('');
            setFilterJobFamilyArray([]);
            const data = {
                searchtext: keyword?.trim(),
                searchLocation: filterLocationArray,
                searchJobSector: filterJobSectorArray,
                searchJobFamily: [],
                searchStakeholders: stakeholdersArray,
                stakeholdersValues: stakeholdersValues,
            }
            SetLocalStorage("filters", JSON.stringify(data))
            selectJobFamilyInputRef.current.clearValue();
        }
        else if (key === 'stakeholder') {
            setSearchStakeholders('');
            setStakeholdersValues('');
            setStakeholdersArray([]);
            selectStakeholderInputRef.current.clearValue();
            const data = {
                searchtext: keyword?.trim(),
                searchLocation: filterLocationArray,
                searchJobSector: filterJobSectorArray,
                searchJobFamily: filterjobFamilyArray,
                searchStakeholders: [],
                stakeholdersValues: "",
            }
            SetLocalStorage("filters", JSON.stringify(data));
        }
        else if (key === 'workplace') {
            setSearchWorkPlace('');
            setWorkplaceValues('');
            setWorkplaceArray([]);
        }
        else if (key === 'senioritylevel') {
            setSearchSeniorityLevel('');
            setSenioritylevelValues('');
            setSenioritylevelArray([]);
        }
        else if (key === 'employmenttype') {
            setSearchEmployeementType('');
            setEmpTypeValues('');
            setEmpTypeArray([]);
        }
        else if (key === 'salary') {
            setSearchSalary('');
            setMinSalary('');
        }
        else if (key === 'dateposted') {
            setSearchDatePosted('');
            setDatePosted('');
        }
        else {
            //clear all filters
            setKeyword('');
            setSearchText('');

            setSearchLocation('');
            setFilterLocation('');
            setFilterLocationArray([]);
            selectLocationInputRef.current.clearValue();

            setSearchJobSector('');
            setFilterJobSector('');
            setFilterJobSectorArray([]);
            selectJobSectorInputRef.current.clearValue();

            setSearchJobFamily('');
            setFilterJobFamily('');
            setFilterJobFamilyArray([]);
            selectJobFamilyInputRef.current.clearValue();

            setSearchStakeholders('');
            setStakeholdersValues('');
            setStakeholdersArray([]);
            selectStakeholderInputRef.current.clearValue();

            setSearchWorkPlace('');
            setWorkplaceValues('');
            setWorkplaceArray([]);

            setSearchSeniorityLevel('');
            setSenioritylevelValues('');
            setSenioritylevelArray([]);

            setSearchEmployeementType('');
            setEmpTypeValues('');
            setEmpTypeArray([]);

            setSearchSalary('');
            setMinSalary('');

            setSearchDatePosted('');
            setDatePosted('');

            RemoveLocalStorage("filters");
        }
        removeSearchFilter(!searchfilter);
    };

    useEffect(() => {
        document.title = `${GetPortalTitle('Jobs')}`;
        getallFilterData();
        goToTop();
        RemoveLocalStorage("InternShipfilters");
        RemoveLocalStorage("Coursesfilters");
    }, [])

    const onFindJobs = () => {
        const data = {
            searchtext: keyword?.trim(),
            searchLocation: filterLocationArray,
            searchJobSector: filterJobSectorArray,
            searchJobFamily: filterjobFamilyArray,
            searchStakeholders: stakeholdersArray,
            stakeholdersValues: stakeholdersValues,
        }
        SetLocalStorage("filters", JSON.stringify(data))
    }

    useEffect(() => {
        if (filterData) {
            const localsearchText = filterData?.searchtext;
            if (localsearchText) {
                setKeyword(localsearchText);
            }
            const localsearchLocation = filterData?.searchLocation;
            if (localsearchLocation?.length) {
                setFilterLocationArray(localsearchLocation);
            }
            const localsearchJobSector = filterData?.searchJobSector;
            if (localsearchJobSector?.length) {
                setFilterJobSectorArray(localsearchJobSector);
            }
            const localsearchJobFamily = filterData?.searchJobFamily;
            if (localsearchJobFamily?.length) {
                setFilterJobFamilyArray(localsearchJobFamily);
            }
            const localsearchStakeholders = filterData?.searchStakeholders;
            if (localsearchStakeholders?.length) {
                setStakeholdersArray(localsearchStakeholders);
            }
        }
    }, [])

    useEffect(() => {
        getJobsByFilter();
    }, [reloadList, empTypeValues, workplaceValues, senioritylevelValues, minimunSalary, pageNumber, perPage, sortBy, datePosted,
        searchfilter, searchLocation, searchJobSector, searchJobFamily, searchStakeholders, searchWorkPlace, searchSeniorityLevel, searchEmployeementType, searchSalary, searchDatePosted
    ])

    return (

        <div>
            {
                loading ? <Loader /> : null
            }

            <Header setReloadList={setReloadList} loading={loading} />

            <div id="jobSearchWidget" className="alternative-section">
                <Container>
                    <Row>
                        <Col className="col-sm-12">
                            <Form className="job-search job-resp-search">
                                <Row className="row-cols-lg-4 g-4 align-items-center">
                                    <Col className="field-wrapper coltextwidth">
                                        <span className="search-field-icon">
                                            <Input value={keyword} onChange={(e) => setKeyword(e?.target?.value)} onKeyDown={(e) => handleKeyDown(e)} id="jobSearchField" name="search" placeholder="Job Title, Keywords..." type="text" />
                                        </span>
                                    </Col>
                                    <Col className="colwidth">
                                        <ReactSelect
                                            ref={selectLocationInputRef}
                                            instanceId="300"
                                            options={dropdownLocation}
                                            isMulti
                                            isSearchable
                                            isClearable
                                            hideSelectedOptions={false}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            placeholder={'Location'}
                                            controlShouldRenderValue={false}
                                            components={{
                                                Option
                                            }}
                                            className={searchLocation != '' ? 'selected-select-control' : ''}
                                            onChange={handleLocationChange}
                                            allowSelectAll={true}
                                            defaultValue={filterLocationArray}
                                            value={filterLocationArray}
                                        //checked={filterLocationArray.includes(filterLocation?.text)}
                                        //value={filterLocation?.text}
                                        />
                                    </Col>
                                    <Col className="colwidth">
                                        <ReactSelect
                                            ref={selectJobSectorInputRef}
                                            instanceId="300"
                                            options={dropdownJobSector}
                                            isMulti
                                            isSearchable
                                            isClearable
                                            hideSelectedOptions={false}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            placeholder={'Job Sector'}
                                            controlShouldRenderValue={false}
                                            components={{
                                                Option
                                            }}
                                            onChange={handleJobSectorChange}
                                            allowSelectAll={true}
                                            className={searchJobSector != '' ? 'selected-select-control' : ''}
                                            //checked={filterJobSectorArray.includes(filterJobSector?.text)}
                                            defaultValue={filterJobSectorArray}
                                            value={filterJobSectorArray}
                                        //menuIsOpen={true}
                                        //onSelectResetsInput={true}
                                        //isOptionDisabled={(dropdownJobSector) => dropdownJobSector.count === 0}
                                        />
                                    </Col>
                                    <Col className="colwidth">
                                        <ReactSelect
                                            ref={selectJobFamilyInputRef}
                                            instanceId="550"
                                            options={dropdownJobFamily}
                                            isMulti
                                            isSearchable
                                            isClearable
                                            hideSelectedOptions={false}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            placeholder={'Profession'}
                                            controlShouldRenderValue={false}
                                            components={{
                                                Option
                                            }}
                                            className={searchJobFamily != '' ? 'selected-select-control' : ''}
                                            onChange={handleJobFamilyChange}
                                            allowSelectAll={true}
                                            checked={filterjobFamilyArray.includes(filterJobFamily?.text)}
                                            value={filterjobFamilyArray}
                                        />
                                    </Col>
                                    <Col className="colwidth">
                                        <ReactSelect
                                            ref={selectStakeholderInputRef}
                                            instanceId="350"
                                            options={dropdownStakeholders}
                                            isMulti
                                            isSearchable
                                            isClearable
                                            hideSelectedOptions={false}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            placeholder={'Stakeholder'}
                                            controlShouldRenderValue={false}
                                            components={{
                                                Option
                                            }}
                                            className={searchStakeholders != '' ? 'selected-select-control' : ''}
                                            onChange={handleStakeholderChange}
                                            allowSelectAll={true}
                                            value={stakeholdersArray}
                                        />
                                    </Col>
                                    <Col className="field-wrapper1 d-flex justify-content-md-end align-middle colwidth ps-0">
                                        <Button onClick={() => { getJobsByFilter(); onFindJobs() }} className="find-jobs-btn1" title="Find Jobs"> Find Jobs </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>


            <div id="jobsFilter" className="jobsFilter">
                <Container>
                    <Row>
                        <Col className="col-12 col-md-7 order-md-first order-last showing-results">
                            {loading ? '' : <p>Showing {jobsLength === 0 ? 0 : showingFrom} – {showingTo < jobsLength ? showingTo : jobsLength} of {jobsLength} <b>{searchtext}</b> jobs</p>}


                        </Col>
                        <Col className="col-12 col-md-5 select justify-content-end">
                            <FormGroup>
                                <Input id="jobSort" className="jobSort" name="jobsort" type="select" value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                                    <option disabled value="">Sort by (Date)</option>
                                    <option value="created_desc">Newest</option>
                                    <option value="created_asc">Oldest</option>
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Input value={perPage} onChange={(e) => perPageChange(e)} id="perPage" className="noOfPage perpage-resp ms-4" name="perPage" type="select">
                                    <option value="10">10 per page</option>
                                    <option value="20">20 per page</option>
                                    <option value="30">30 per page</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                </Container>
            </div>

            <div id="jobsList1">
                <Container>
                    <Row>
                        <Col className="col-12 col-md-3">
                            {
                                searchtext || searchLocation || searchJobSector || searchJobFamily || searchStakeholders || searchWorkPlace || searchSeniorityLevel || searchEmployeementType || searchSalary || (searchDatePosted != '' && searchDatePosted != 'All' && searchDatePosted != undefined) ?
                                    <div className="applied-filter"><span>Applied filters</span>
                                        <a id="filter-keyword" className="clearallfilter" onClick={() => { resetFilters(''); }}>Clear All</a></div>
                                    : null
                            }
                            {
                                searchtext ?
                                    <div className="jobs-search-filter" title={searchtext.length > 10 ? searchtext : ''}>
                                        <span>{searchtext.length > 10 ? searchtext.substring(0, 10) + '...' : searchtext}</span>
                                        <a id="filter-keyword" onClick={() => { resetFilters('keyword'); }}>
                                            <span>x</span></a>
                                    </div> : null
                            }
                            {
                                searchLocation ?
                                    <div className="jobs-search-filter" title={searchLocation.length > 10 ? searchLocation : ''}>
                                        <span>{searchLocation}</span>
                                        <a id="filter-keyword" onClick={() => { resetFilters('location'); }}>
                                            <span>x</span></a>
                                    </div>
                                    : null
                            }
                            {
                                searchJobSector ?
                                    <div className="jobs-search-filter" title={searchJobSector.length > 10 ? searchJobSector : ''}>
                                        <span>{searchJobSector.length > 10 ? searchJobSector.substring(0, 10) + '...' : searchJobSector}</span>
                                        <a id="filter-keyword" onClick={() => { resetFilters('jobsector'); }}>
                                            <span>x</span></a>
                                    </div>
                                    : null
                            }
                            {
                                searchJobFamily ?
                                    <div className="jobs-search-filter" title={searchJobFamily.length > 10 ? searchJobFamily : ''}>
                                        <span>{searchJobFamily.length > 10 ? searchJobFamily.substring(0, 10) + '...' : searchJobFamily}</span>
                                        <a id="filter-keyword" onClick={() => { resetFilters('jobfamily'); }}>
                                            <span>x</span></a>
                                    </div>
                                    : null
                            }
                            {
                                searchStakeholders ?
                                    <div className="jobs-search-filter" title={searchStakeholders.length > 10 ? searchStakeholders : ''}>
                                        <span>{searchStakeholders.length > 10 ? searchStakeholders.substring(0, 10) + '...' : searchStakeholders}</span>
                                        <a id="filter-keyword" onClick={() => { resetFilters('stakeholder'); }}>
                                            <span>x</span></a>
                                    </div>
                                    : null
                            }
                            {
                                (searchDatePosted != '' && searchDatePosted != 'All' && searchDatePosted != undefined) ?
                                    <div className="jobs-search-filter" title={searchDatePosted.length > 10 ? searchDatePosted : ''}>
                                        <span>{searchDatePosted.length > 10 ? searchDatePosted.substring(0, 10) + '...' : searchDatePosted}</span>
                                        <a id="filter-keyword" onClick={() => { resetFilters('dateposted'); }}>
                                            <span>x</span></a>
                                    </div>
                                    : null
                            }
                            {
                                searchSalary ?
                                    <div className="jobs-search-filter" title={searchSalary.length > 10 ? searchSalary : ''}>
                                        <span>{searchSalary.length > 10 ? searchSalary.substring(0, 10) + '...' : searchSalary}</span>
                                        <a id="filter-keyword" onClick={() => { resetFilters('salary'); }}>
                                            <span>x</span></a>
                                    </div>
                                    : null
                            }
                            {
                                searchSeniorityLevel ?
                                    <div className="jobs-search-filter" title={searchSeniorityLevel.length > 10 ? searchSeniorityLevel : ''}>
                                        <span>{searchSeniorityLevel.length > 10 ? searchSeniorityLevel.substring(0, 10) + '...' : searchSeniorityLevel}</span>
                                        <a id="filter-keyword" onClick={() => { resetFilters('senioritylevel'); }}>
                                            <span>x</span></a>
                                    </div>
                                    : null
                            }
                            {
                                searchEmployeementType ?
                                    <div className="jobs-search-filter" title={searchEmployeementType.length > 10 ? searchEmployeementType : ''}>
                                        <span>{searchEmployeementType.length > 10 ? searchEmployeementType.substring(0, 10) + '...' : searchEmployeementType}</span>
                                        <a id="filter-keyword" onClick={() => { resetFilters('employmenttype'); }}>
                                            <span>x</span></a>
                                    </div>
                                    : null
                            }
                            {
                                searchWorkPlace ?
                                    <div className="jobs-search-filter" title={searchWorkPlace.length > 10 ? searchWorkPlace : ''}>
                                        <span>{searchWorkPlace.length > 10 ? searchWorkPlace.substring(0, 10) + '...' : searchWorkPlace}</span>
                                        <a id="filter-keyword" onClick={() => { resetFilters('workplace'); }}>
                                            <span>x</span></a>
                                    </div>
                                    : null
                            }
                            {
                                searchtext || searchLocation || searchJobSector || searchJobFamily || searchStakeholders || searchWorkPlace || searchSeniorityLevel || searchEmployeementType || searchSalary || (searchDatePosted != '' && searchDatePosted != 'All' && searchDatePosted != undefined) ?
                                    <div className="marginbottom10"></div>
                                    : null
                            }
                            <div className="sidebar advanced-search">

                                <div className="salary-offer">
                                    <h4 className="expand-title" id="date-posted">Date Posted</h4>
                                    <UncontrolledCollapse className="salary-section" toggler="#date-posted">
                                        {
                                            dropdownDatePosted?.map((item, i) => (
                                                <FormGroup check className="box-checked" key={i}>
                                                    <Label check className="label-input-checker">
                                                        <span className="filter-check-label"><Input id={"salary" + i} value={datePosted} checked={datePosted === item?.value} onChange={(e) => setDatePosted(item?.value)} type="radio" name="date" className="date-radio" />{item?.text}</span>
                                                        <span>({item?.count})</span></Label>
                                                </FormGroup>
                                            ))
                                        }
                                    </UncontrolledCollapse>
                                </div>

                                <div className="salary-offer">
                                    <h4 className="expand-title" id="offered-salary">Salary</h4>
                                    <UncontrolledCollapse className="salary-section" toggler="#offered-salary">
                                        {
                                            dropdownOfferedSalary?.map((item, i) => (
                                                <FormGroup check className="box-checked" key={i}>
                                                    <Label check className="label-input-checker">
                                                        <span className="filter-check-label"><Input id={"salary" + i} value={item?.value} onChange={(e) => filterOfferedSalary(e)} type="radio" name="salary" className="date-radio" checked={minimunSalary === item?.value} />{item?.text}</span>
                                                        <span>({item?.count})</span></Label>
                                                    {
                                                        (i === (dropdownOfferedSalary.length - 1))
                                                            ? <br /> : null
                                                    }
                                                </FormGroup>
                                            ))
                                        }
                                    </UncontrolledCollapse>
                                </div>

                                <div className="salary-offer">
                                    <h4 className="expand-title" id="seniority">Seniority Level</h4>
                                    <UncontrolledCollapse className="salary-section" toggler="#seniority">
                                        {
                                            dropdownSeniorityLevel?.map((item, i) => (
                                                <FormGroup check className="box-checked" key={i}>
                                                    <Label check className="label-input-checker">
                                                        <span className="filter-check-label"><Input type="checkbox" onChange={(e) => filterSeniorityLevel(e)} checked={senioritylevelArray.includes(item?.text)} value={item?.text} />{item?.text}</span>
                                                        <span>({item?.count})</span></Label>
                                                    {
                                                        (i === (dropdownSeniorityLevel.length - 1))
                                                            ? <br /> : null
                                                    }
                                                </FormGroup>
                                            ))
                                        }
                                    </UncontrolledCollapse>
                                </div>

                                <div className="salary-offer">
                                    <h4 className="expand-title" id="employment-type">Employment Type</h4>
                                    <UncontrolledCollapse className="salary-section" toggler="#employment-type">
                                        {
                                            dropdownEmploymentype?.map((item, i) => (
                                                <FormGroup check className="box-checked" key={i}>
                                                    <Label check className="label-input-checker">
                                                        <span className="filter-check-label"><Input type="checkbox" onChange={(e) => filterEmployeementType(e)} checked={empTypeArray.includes(item?.value)} value={item?.value} />{item?.text}</span>
                                                        <span>({item?.count})</span>
                                                    </Label>
                                                    {
                                                        (i === (dropdownEmploymentype.length - 1))
                                                            ? <br /> : null
                                                    }
                                                </FormGroup>
                                            ))
                                        }
                                    </UncontrolledCollapse>
                                </div>

                                <div className="salary-offer">
                                    <h4 className="expand-title" id="workplace">Workplace</h4>
                                    <UncontrolledCollapse className="salary-section" toggler="#workplace">
                                        {
                                            dropdownWorkplace?.map((item, i) => (
                                                <FormGroup check className="box-checked" key={i}>
                                                    <Label check className="label-input-checker">
                                                        <span className="filter-check-label"><Input type="checkbox" onChange={(e) => filterWorkplaces(e)} checked={workplaceArray.includes(item?.value)} value={item?.value} />{item?.text}</span>
                                                        <span>({item?.count})</span></Label>
                                                    {
                                                        (i === (dropdownWorkplace.length - 1))
                                                            ? <br /> : null
                                                    }
                                                </FormGroup>
                                            ))
                                        }
                                    </UncontrolledCollapse>
                                </div>

                            </div>
                        </Col>

                        <Col className="col-12 col-md-9">

                            <CardColumns>
                                {jobs?.map((item, i) => (
                                    <NavLink to={item?.url} className="cardDiv" key={item?.id} >
                                        <Card className={(item?.candidateApplied === true) ? "background-grey" : (i % 2 === 0 ? "odd" : "even")}>
                                            <CardBody title={item?.title}>
                                                {
                                                    item?.stakeholder?.imageUrl ? <CardImg alt="Card image cap" title={item?.stakeholder?.shortName} src={item?.stakeholder?.imageUrl || "assets/images/logos/dummy-logo.webp"} onError={(e) => onError(e)} width="145" /> :
                                                        <span className="image-initials-popular jobs-img-initials"><span title={item?.stakeholder?.shortName}>{item?.stakeholder?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{item?.stakeholder?.shortName?.split(' ').length > 1 ? item?.stakeholder?.shortName?.split(' ')[item?.stakeholder?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</span></span>
                                                }

                                                <CardTitle tag="h3">{item?.title?.split(".")[0]}{item?.candidateApplied}
                                                    <div className="badges-wrapper">
                                                        <span className="badge green align-middle">{item?.employmentTypeDescription}</span>
                                                        {item?.isUrgent ? <span className="badge gold align-middle">Urgent</span> : null}
                                                    </div>
                                                </CardTitle>
                                                <div className="card-text">
                                                    <div className="icons listing"><i className="fa-solid fa-oil-well"></i><strong>Job Sector: </strong>{item?.subSector?.parent?.name}</div>
                                                    <div className="icons listing"><i className="fa-regular fa-oil-well"></i><strong>Sub Sector: </strong>{item?.subSector?.name}</div>
                                                    <div className="icons job-location listing"><i className="fa-solid fa-location-crosshairs"></i><strong>Location: </strong>{item?.countryDescription}, {item?.cityText}</div>
                                                    <br /><div className="icons job-family listing"><i className="fa-regular fa-briefcase"></i><strong>Profession: </strong>{item?.jobFamily?.name}</div>
                                                    <div className="icons job-experience listing"><i className="fa-regular fa-ranking-star"></i><strong>Experience: </strong>{item?.relevantExperience} years</div>
                                                    <br /><div className="icons job-salary listing"><i className="fa-solid fa-money-bills"></i><strong>Salary: </strong>{item?.currencyDescription} {item?.minSalary.toLocaleString()} - {item?.maxSalary.toLocaleString()}</div>
                                                    <div className="icons job-date-added listing"><i className="fa-solid fa-clock"></i><strong>Posted: </strong>{moment(item?.created).format("DD MMM YYYY")}</div>
                                                    <div className="icons job-career-level listing"><i className="fa-light fa-people-roof"></i><strong>Seniority Level: </strong>{item?.seniority?.name}</div>
                                                    <div className="job-desc">
                                                        <p>{item?.responsibilities || item?.performance || item?.competency}</p>
                                                    </div>
                                                    {item?.candidateApplied === true ? <div className="job-desc">
                                                        <p>You applied for this job on {moment(item?.latestApplication_Created).format("DD MMM YYYY")}</p></div> : null}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </NavLink>
                                ))}
                            </CardColumns>

                            <div className="outerdiv">
                                {
                                    (searchtext || searchLocation || searchJobSector || searchJobFamily || searchStakeholders || searchWorkPlace || searchSeniorityLevel || searchEmployeementType || searchSalary || (searchDatePosted != '' && searchDatePosted != 'All' && searchDatePosted != undefined)) && jobsLength == 0 ?
                                        (!loading ? <div className="nodata">There are no job posts matching the search filters</div> : "")
                                        : null
                                }
                                {
                                    jobsLength > 0 ? (<p>Showing {jobsLength === 0 ? 0 : showingFrom} – {showingTo < jobsLength ? showingTo : jobsLength} of {jobsLength} <b>{searchtext}</b> jobs</p>)
                                        : ""
                                }
                                {
                                    pageArray?.length ?
                                        <Pagination className="mt-2 mb-5">
                                            <PaginationItem>
                                                <PaginationLink className={pageNumber === 1 ? "next-prev disabled" : "next-prev"} onClick={() => changePage(pageNumber - 1)}>
                                                    <span className="fa fa-angle-left"></span> Previous
                                                </PaginationLink>
                                            </PaginationItem>
                                            {
                                                pageArray?.map((item, i) => (
                                                    <PaginationItem key={i}>
                                                        <PaginationLink className={pageNumber === item ? "active" : ""} onClick={() => changePage(item)}>{item}</PaginationLink>
                                                    </PaginationItem>
                                                ))
                                            }
                                            <PaginationItem>
                                                <PaginationLink className={pageNumber === totalPages ? "next-prev disabled" : "next-prev"} onClick={() => changePage(pageNumber + 1)} >
                                                    Next <span className="fa fa-angle-right"></span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                        : null
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer loading={loading} />

        </div>
    );
}
export default Jobs;