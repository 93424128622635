import React, { useEffect } from "react";
import "./Home.css";
import CompanyCovered from "../../components/Home/CompanyCovered/CompanyCovered";
import HomeBanner from "../../components/Home/HomeBanner/HomeBanner";
import Guidance from "../../components/Home/Guidance/Guidance";
import Header from "../../components/Header/Header";
import InformationHome from "../../components/Home/InformationHome/InformationHome";
import Footer from "../../components/Footer/Footer";
import IndustrySlider from "../../components/Home/IndustrySlider/IndustrySlider";
import Hiring from "../../components/Home/hiring/hiring";
import FeaturedCandidate from "../../components/Home/featuredCandidate/featuredCandidate";
import { GetPortalTitle, RemoveLocalStorage } from '../../utils/utils'
const Home = () => {
    useEffect(() => {
        document.title = `${GetPortalTitle('')}`;
        RemoveLocalStorage("filters");
        RemoveLocalStorage("InternShipfilters");
        RemoveLocalStorage("Coursesfilters");
    }, [])
    return (
        <div>
            <Header/>
            <HomeBanner />
            <CompanyCovered />
            <Guidance />
            <IndustrySlider />
            <Hiring/>
            <InformationHome />
            <FeaturedCandidate />
            <Footer />
        </div>
        )
};

export default Home;