import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, List } from 'reactstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import Headers from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Apiurl, CandidateIdGet, GetPortalTitle, GetToken, XApiKey, RemoveLocalStorage } from '../../utils/utils';


const InternshipDetails = () => {
    const [loading, setLoading] = useState(false);
    const url = window.location.pathname.toLowerCase();
    const navigate = useNavigate();
    const [InternData, setInternData] = useState([]);
    const [applyBtn, setApplyBtn] = useState(false);
    const [loginIsApply, setLoginIsApply] = useState(false);
    const candidateId = CandidateIdGet();
    const candidateToken = GetToken()
    const [applyDetails, setApplyDetails] = useState('')

    const applyNow = () => {
        if (!candidateToken) {
            setApplyDetails("intern")
            setLoginIsApply(true);
        } else {
            //if (userStatus) {
            navigate(`/internshipapplication/${url.split('/internship/')[1]}`)
            //}
        }
    }

    const goToTop = () => {
        window.scrollTo(0, 0);
    }
    
    const GetInternByUrl = () => {
        setLoading(true);
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/Internship/getbyurl?url=${url}&CandidateId=${candidateId}`,

        }
        axios(option)
            .then((e) => {
                if (e?.status === 200) {
                    setInternData(e?.data)
                    if (e?.data?.applied) {
                        setApplyBtn(false)
                        toast.success("You have already applied for this internship");
                    } else {
                        const deadline = moment(e?.data?.deadline).format('L');
                        const today = moment(new Date()).format('L');
                        if (today <= deadline && e?.data?.status === 'Open') {
                            setApplyBtn(true)
                        }
                    }
                }
                setLoading(false)
                if (e?.status === 204) {
                    navigate('404')
                }
            })
            .catch((err) => {
                setLoading(false);
                navigate('404')
            })
    }

    useEffect(() => {
        GetInternByUrl();
        goToTop();
        RemoveLocalStorage("filters");
        RemoveLocalStorage("Coursesfilters");
    }, [])

    useEffect(() => {
        document.title = `${GetPortalTitle(InternData?.title)}`;
    }, [InternData])

    const navigateToStakeholder = () => {
        var type = "";
        if (InternData?.company?.type === "International Oil Company") {
            type = "ioc"
        } else if (InternData?.company?.type === "Refinery") {
            type = "refinery"
        } else if (InternData?.company?.type === "Pipeline Operator") {
            type = "pipelineoperator"
        } else if (InternData?.company?.type === "Oilfield Service Company") {
            type = "osc"
        }
        navigate(`/stakeholder/${type}/${InternData?.company?.shortName}`, { state: { stakeholder: InternData?.company } })
    }

    return (

        <div>

            {
                loading ? <Loader /> : null
            }

            <Headers setApplyBtn={setApplyBtn} loading={loading} setLoginIsApply={setLoginIsApply} loginIsApply={loginIsApply} InternData={InternData} applyDetails={applyDetails} internurl={url.split('/internship')[1]} />

            <div id="jobDetailsIntro" className="alternative-section">
                <Container>
                    <Row>
                        <Col className="col-sm-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="col-12 col-sm-2 col img-col">
                                            {
                                                InternData?.company?.imageUrl ? <CardImg className="cardImg" alt="logo" src={InternData?.company?.imageUrl || 'assets/images/logos/dummy-logo.webp'} title={InternData?.company?.shortName} /> :
                                                    <div className="image-initials" title={InternData?.company?.shortName}>{InternData?.company?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{InternData?.company?.shortName?.split(' ').length > 1 ? InternData?.company?.shortName?.split(' ')[InternData?.company?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</div>
                                            }
                                        </Col>
                                        <Col className="col-12 col-sm-10 col">
                                            <CardTitle tag="h3">{InternData?.title}</CardTitle>
                                            <div className="card-text">
                                                <div className="top-section-job-details">
                                                    <div className="top-family-location">
                                                        {InternData?.city ? <div>
                                                            <i className='fa-solid fa-location-crosshairs'></i><span className="job-location"><strong> Role Location: </strong>{InternData?.countryDescription ? InternData?.countryDescription + ", " : null} {InternData?.city}</span>
                                                        </div> : null}
                                                        {InternData?.deadline ? <div>
                                                            <i className='fa-solid fa-clock'></i><span className="job-date-added"><strong>Deadline: </strong>{moment(InternData?.deadline).format("DD MMM YYYY")}</span></div> : null}
                                                    </div>
                                                </div>
                                                {
                                                    applyBtn ? <button className="btn btn-primary apply apply-now-view-dtls" title="Apply Now" onClick={() => applyNow()}>Apply Now</button>
                                                        : null
                                                }

                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="jobDetails" className="mt-5 mb-5">
                <Container>
                    <Row>
                        <Col className="col-12 col-md-8">
                            {InternData?.natureOfWork ? <div className="other-para">
                                <h3>Nature of the Work:</h3>
                                {InternData?.natureOfWork.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}

                            {InternData?.deliverables ? <div className="other-para">
                                <h3>Deliverables:</h3>
                                {InternData?.deliverables.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}

                            {InternData?.learningOpportunities ? <div className="other-para">
                                <h3>Learning opportunities from this assignment:</h3>
                                {InternData?.learningOpportunities.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}

                            {InternData?.workingConditions ? <div className="other-para">
                                <h3>Working Conditions:</h3>
                                {InternData?.workingConditions.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}
                        </Col>

                        <Col className="col-12 col-md-4">
                            <div className="side-bar mb-4">
                                {InternData?.workPlaceDescription ?
                                    <div className="job-detail job-experience">
                                    <i className="fa-solid fa-chair-office jobdetails-inner-icons"></i>
                                        <h4>Workplace</h4>
                                        <p>{InternData?.workPlaceDescription}</p>
                                    </div> : null}
                                {InternData?.status ?
                                    <div className="job-detail job-experience">
                                        <i className="fa-solid fa-signal jobdetails-inner-icons"></i>
                                        <h4>Status</h4>
                                        <p>{InternData?.status}</p>
                                    </div> : null}
                                {InternData?.durationFrom && InternData?.durationTo ?
                                    <div className="job-detail job-experience">
                                        <i className="fa-solid fa-calendar-days jobdetails-inner-icons"></i>
                                        <h4>Duration</h4>
                                        <p>{moment(InternData?.durationFrom).format("DD MMM YYYY")} to {moment(InternData?.durationTo).format("DD MMM YYYY")}</p>
                                    </div> : null}
                                {InternData?.noOfPlacements ?
                                    <div className="job-detail job-experience">
                                        <i className="fa fa-list-ol jobdetails-inner-icons"></i>
                                        <h4>No of Placements Available</h4>
                                        <p>{InternData?.noOfPlacements}</p>
                                    </div> : null}
                            </div>

                            <div className="side-bar employer employer-company mb-4">
                                <div className='logo-section mb-4'>
                                    {
                                        InternData?.company?.imageUrl ? <img src={InternData?.company?.imageUrl || "assets/images/logos/dummy-logo.webp"} width="145" title={InternData?.company?.shortName} /> :
                                            <div className="image-initials job-view-initials" title={InternData?.company?.shortName}>{InternData?.company?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{InternData?.company?.shortName?.split(' ').length > 1 ? InternData?.company?.shortName?.split(' ')[InternData?.company?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</div>
                                    }
                                    <h4><span>{InternData?.company?.shortName}</span></h4>
                                </div>
                                <ul>
                                    {InternData?.company?.type === "Oilfield Service Company" && InternData?.company?.categories ?
                                        <li>Categories: <span className="paddingbottom10"> {InternData?.company?.categories}</span></li> : null}
                                    {InternData?.company?.establishment ?
                                        <li>Founded Date: <span>{InternData?.company?.establishment.slice(0, 4)}</span></li> : null}
                                    {InternData?.company?.country?.name ?
                                        <li>Location: <span>{InternData?.company?.country?.name}</span></li> : null}
                                    {!(InternData?.company?.enquiriesTel === "-" || InternData?.company?.enquiriesTel === "" || InternData?.company?.enquiriesTel === null)  ?
                                        <li> Phone Number: <span>{InternData?.company?.enquiriesTelCountry?.diallingCode}{InternData?.company?.enquiriesTel}</span></li> : null}
                                    {InternData?.company?.enquiriesEmail ?
                                        <li>Email: <span title={InternData?.company?.enquiriesEmail }><a href={'mailto:' + InternData?.company?.enquiriesEmail}>{InternData?.company?.enquiriesEmail}</a></span></li> : null}
                                </ul>
                                <div className="btn btn-primary apply" onClick={() => navigateToStakeholder()} title="View Company Profile">View Company Profile</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer loading={loading} />

        </div>


    );
}
export default InternshipDetails;
