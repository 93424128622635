import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

class Maps extends Component {
    render() {
        return (
            <Map google={this.props.google} zoom={14} initialCenter={{ lat: this.props.setAddressLat, lng: this.props.setAddressLng }}>
                <Marker position={{ lat: this.props.setAddressLat, lng: this.props.setAddressLng }} />
            </Map>

        )
    }
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyBzcLJDtQLNwjJp611Dx6ZF_e646pJzr0c'
})(Maps)
