import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import   ReactDOM  from 'react-dom/client';
import { BrowserRouter} from 'react-router-dom';
import './styles/styles.css';
import './styles/responsive.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
/*const rootElement = document.getElementById('root');*/
//const root = createRoot(rootElement);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    //<BrowserRouter basename={baseUrl}>
    //  <App />
    //</BrowserRouter>);
    <React.StrictMode>
        <BrowserRouter>
            {/*<Routes>*/}
            {/*    <Route path="/" element={<App />}>*/}
            {/*    </Route>*/}
            {/*</Routes>*/}
            <App  />
        </BrowserRouter>
    </React.StrictMode>
    )
