import React, { useEffect } from 'react';
import { Apiurl, CandidateId, PasswordtokenSet, XApiKey } from '../utils/utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const Password = () => {

    const navigate = useNavigate();
    const GetPathName = window?.location?.search?.split("t=");
    const getToken = GetPathName?.[1];

    const ResetPassword = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/candidate/getpasswordreset?token=${getToken}`,
        }
        axios(option)
            .then((e) => {
                if (e?.data?.statusCode === 500) {
                    toast.error(e?.data?.errorMessage);
                    navigate('/')
                }
                if (e?.data?.statusCode === 200) {
                    CandidateId(e?.data?.id);
                    PasswordtokenSet(getToken)
                    navigate('/resetpassword');
                }
            })
            .catch(() => {

            })
    }

    useEffect(() => {
        ResetPassword();
    }, [getToken])
    return (
        <>
            
        </>
    )
}
export default Password;