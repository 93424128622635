import React, { Fragment, useEffect, useState } from 'react';
import './QualificationInfoProfile.css';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Apiurl, GetToken, RemoveUserSession, XApiKey } from '../../../utils/utils';
import axios from 'axios';
import Select from 'react-select';
import { AiOutlineDownload } from 'react-icons/ai';
import { saveAs } from 'file-saver';
import { FiChevronDown } from "react-icons/fi"


const QualificationInfoProfile = ({ onChange, formValues, setformValues, candidate }) => {
    const navigate = useNavigate();
    const [jobFamilydata, setJobFamilydata] = useState([]);
    const [seniorityLevel, setSeniorityLevel] = useState([]);
    const [defValues, setDefValues] = useState();
    var selecteOptions = "";
    const totaloptions = jobFamilydata.map((item) => {
        return {
            value: item?.id,
            label: item?.name,
        }
    })
    const data = Object.values(formValues?.jobFamily)
    const filterByReference = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
            return arr2.find(element => {
                return element === el.value;
            });
        });
        return res;
    }
    useEffect(() => {
        if (!defValues || defValues?.length === 0) {
            setDefValues(Object.values(filterByReference(totaloptions, data)))
        }
    }, [formValues?.jobFamily])

    const onChangeNew = (e) => {
        setDefValues(e);
        const length = e?.length;
        for (var i = 0; i < length; i++) {
            selecteOptions = selecteOptions + "," + e[i]?.value;
        }
        setformValues({
            ...formValues,
            jobFamily: selecteOptions.substring("1")
        })

    }

    const candidateToken = GetToken()
    const getJobFamily = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            url: `${Apiurl()}/candidate/jobfamilies`
        };
        axios(option)
            .then(e => {
                setJobFamilydata(e?.data);
            })
            .catch((err) => {
            });
    }

    const getSeniorityLevel = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            url: `${Apiurl()}/candidate/senioritylevels`
        };
        axios(option)
            .then(e => {
                setSeniorityLevel(e?.data);
            })
            .catch((err) => {
            });
    }
    useEffect(() => {
        getSeniorityLevel();
        getJobFamily();
    }, [])

    const downLoadFile = (item,id) => {
        const CandidateId = candidate?.id;
        var fileType = "";
        var fileName = "";
        if (item === "highestQual") {
            fileType = "D";
            fileName = candidate?.highestQualificationCertificate;
        } else if (item === "cvFile") {
            fileType = "C";
            fileName = candidate?.cv;
        } else if (item !== "highestQual" && item !== "cvFile"){
            fileType = "E";
            fileName = item;
        }
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/pdf',
                'XApiKey': `${XApiKey()}`,
                'Authorization': `Bearer ${GetToken()}`
            },
            url: `${Apiurl()}/downloadfile?id=${CandidateId}&docid=${id}&type=${fileType}`
        };
        axios(option)
            .then(async (e) => {
                const downloadLink = document.createElement("a");
                downloadLink.href = e?.data;
                downloadLink.download = fileName;
                downloadLink.click();
            })
            .catch((err) => {

            })
    }

    return (
        <>
            <Container>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3 general_info_input">
                            <Form.Label>Seniority Level<span>*</span></Form.Label>
                            <div className="side-line-signup">
                            <span className="arrow-down-signup">&nbsp;</span>
                            <Form.Select aria-label="Default select example"
                                onChange={onChange}
                                value={formValues?.seniorityLevel}
                                name="seniorityLevel" >
                                <option className="option-dropdown" value=""  disabled>--Select Seniority Level--</option>
                                {
                                    seniorityLevel?.map((item,i) => {
                                        return (
                                            <Fragment key={i}>
                                            <option value={item?.id}>{item?.name}</option>
                                            </Fragment>
                                        )
                                    })
                                }
                                </Form.Select>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3 general_info_input">
                            <Form.Label>Profession<span>*</span></Form.Label>
                            <Select
                                defaultValue={defValues?.map((item) => item)}
                                value={defValues}
                                isMulti
                                name="jobFamily"
                                options={totaloptions}
                                className="basic-multi-select multi-selectjob"
                                classNamePrefix="select"
                                onChange={(e) => onChangeNew(e)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3 general_info_input">
                            <Form.Label>Relevant Experience<span>*</span></Form.Label>
                            <div className="side-line-signup">
                            <span className="arrow-down-signup">&nbsp;</span>
                            <Form.Select aria-label="Default select example"
                                onChange={onChange}
                                value={formValues?.relevantExperience}
                                    name="relevantExperience" >
                                    <option className="option-dropdown" value="" disabled>--Select Relevant Experience--</option>
                                <option value="0-2">0-2</option>
                                <option value="3-5">3-5</option>
                                <option value="6-9">6-9</option>
                                <option value="10+">10+</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3 general_info_input">
                            <Form.Label>Highest Academic Qualification<span>*</span></Form.Label>
                            <div className="side-line-signup">
                            <span className="arrow-down-signup">&nbsp;</span>
                            <Form.Select aria-label="Default select example"
                                onChange={onChange}
                                value={formValues?.highestQualification}
                                name="highestQualification" >
                                    <option className="option-dropdown" value="" disabled>--Highest Academic Qualification--</option>
                                <option value="None">None</option>
                                <option value="Non-Academic">Non-Academic</option>
                                <option value="Diploma">Diploma</option>
                                <option value="Bachelor">Bachelor</option>
                                <option value="Master">Master</option>
                                <option value="Doctor">Doctor</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                {
                    formValues?.highestQualification === "None" || formValues?.highestQualification === "" ? null : <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3 general_info_input">
                                <Form.Label>Upload Highest Academic Qualification<span>*</span></Form.Label>
                                <div className='input-file-qualification'>
                                    <Form.Control type="file" accept="application/pdf" onChange={onChange} name="highestQualdocument" className="passport-upload-second-tab" />
                                </div>
                            </Form.Group>
                            {
                                candidate?.highestQualificationCertificate ?
                                    <div className="main-section-label-download">

                                        <div className="label-section-after-submit-uploaders">
                                            <input type="text" className="form-control input-label-show" value={candidate?.highestQualificationCertificate} disabled />
                                        </div>

                                        <div className="download-button-labels">
                                            <button onClick={() => downLoadFile("highestQual", 0)} title="Download">Download<AiOutlineDownload className="icons-download-uploaders" /></button>
                                           
                                        </div>
                                    </div>
                                    : null
                            }
                        </Col>
                    </Row>
                }

                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3 general_info_input">
                            <Form.Label>Upload CV<span>*</span></Form.Label>
                            <div className='input-file-qualification'>
                                <Form.Control type="file" accept="application/pdf" onChange={onChange} name="cv" className="passport-upload-second-tab" />
                            </div>
                        </Form.Group>

                        {
                            candidate?.cv ?
                                <div className="main-section-label-download cv-mob">

                                    <div className="label-section-after-submit-uploaders">
                                        <input type="text" className="form-control input-label-show" value={candidate?.cv} disabled />
                                    </div>

                                    <div className="download-button-labels">
                                        <button onClick={() => downLoadFile("cvFile", 0)} title="Download">Download<AiOutlineDownload className="icons-download-uploaders" /></button>
                                        
                                    </div>
                                </div>
                                : null
                        }
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3 general_info_input">
                            <Form.Label>Upload  Other Certificates</Form.Label>
                            <div className='input-file-qualification'>
                                <Form.Control type="file" accept="application/pdf" multiple onChange={onChange} name="otherCertificates"
                                    className="passport-upload-second-tab" />

                            </div>
                        </Form.Group>
                        {
                            candidate?.otherDocuments?.length ?
                                candidate?.otherDocuments?.map((item,i) => {
                                    return (
                                        <div key={i} className="main-section-label-download">

                                            <div className="label-section-after-submit-uploaders">
                                                <input type="text" className="form-control input-label-show" value={item?.documentName} disabled />
                                            </div>

                                            <div className="download-button-labels">
                                                <button onClick={() => downLoadFile(item?.documentName, item?.id)} title="Download">Download<AiOutlineDownload className="icons-download-uploaders" /></button>
                                                
                                            </div>
                                        </div>
                                        )
                                })
                               
                                : null
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default QualificationInfoProfile;