import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Container, Row, Col, Button, Card, CardImg, CardBody, CardTitle, Table, CardText, List } from 'reactstrap';
import './CandidateProfile.css'
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Apiurl, CandidateIdGet, GetToken, XApiKey, RemoveUserSession, GetPortalTitle, RemoveLocalStorage } from '../../utils/utils';
import axios from 'axios';
import moment from "moment";
import { AiOutlineDownload } from "react-icons/ai";
import Loader from '../../components/Loader/loader';
const CandidateProfile = () => {
    const location = useLocation();
    const applicationJob = location?.state || localStorage.getItem('source-page');
    const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [candidate, setCandidate] = useState('');
    const [jobApplication, setjobApplication] = useState([]);
    const [countries, setCountries] = useState([]);
    const [jobFamilydata, setJobFamilydata] = useState([]);
    const [seniorityLevel, setSeniorityLevel] = useState([]);
    const nationality = countries.find(item => item?.id === candidate?.nationalityId)
    const residentCountry = countries.find(item => item?.id === candidate?.residentCountryId)
    const localPhonecode = countries.find(item => item?.id === candidate?.localPhoneNoCountryId)
    const internationalPhonecode = countries.find(item => item?.id === candidate?.internationalPhoneNoCountryId)
    const seniority = seniorityLevel.find(item => item?.id === candidate?.levelId)
    const jobFamily = jobFamilydata.filter(item => candidate?.jobFamily?.find(i => i?.jobFamilyId === item?.id))
    const candidateId = CandidateIdGet();
    const candidateToken = GetToken()
    const [profilePage, setProfilePage] = useState(false)
    const [internApplication, setInternApplication] = useState([]);
    const [courseApplication, setCourseApplication] = useState([]);
    useEffect(() => {
        document.title = `${GetPortalTitle('Candidate Profile')}`;
    }, [candidate])

    const jobSection = useRef(null);
    const internSection = useRef(null);
    const courseSection = useRef(null);
   
    useEffect(() => {
        if (!candidateToken) {
            navigate('/?redirecturl=candidateprofile')
        }
        RemoveLocalStorage("filters");
        RemoveLocalStorage("InternShipfilters");
        RemoveLocalStorage("Coursesfilters");
    }, [])

    const getCandidateData = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            url: `${Apiurl()}/candidate/get?id=${candidateId}`
        };
        axios(option)
            .then(e => {
                setCandidate(e?.data?.body);
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    RemoveUserSession();
                    navigate('/');
                } else {
                    //navigate('*')
                }
            });
    }

    useEffect(() => {
        if (candidateId && candidateToken) {
            getCandidateData();
        }
        
    }, [candidateId, candidateToken])

    useEffect(() => {
        if (applicationJob === "application") {
            setTimeout(() => {
                jobSection.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            },500)
            
        } else {
            window.scrollTo(0, 0);
        }

    }, [candidate, applicationJob])

    useEffect(() => {
        if (applicationJob === "internapplication") {
            setTimeout(() => {
                internSection.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }, 500)

        } else {
            window.scrollTo(0, 0);
        }

    }, [candidate, internApplication])

    useEffect(() => {
        if (applicationJob === "courseapplication") {
            setTimeout(() => {
                courseSection.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }, 500)

        } else {
            window.scrollTo(0, 0);
        }

    }, [candidate, courseApplication])

    const getCountries = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`
            },
            url: `${Apiurl()}/candidate/countries`
        };
        axios(option)
            .then(e => {
                setCountries(e?.data);
            })
            .catch((err) => {
            });
    }

    const getJobApplications = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            url: `${Apiurl()}/jobapplications/getjobapplications?CandidateId=${candidateId}`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                setjobApplication(e?.data);
            })
            .catch((err) => {
                setLoading(false)
            });
    }

    const getInternApplications = () => {
        setLoading(true);
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            url: `${Apiurl()}/internshipapplications/getlist?candidateid=${candidateId}`
        };
        axios(option)
            .then(e => {
                setInternApplication(e?.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }

    const getCourseApplications = () => {
        if (!candidateId) { return; }
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            url: `${Apiurl()}/courseAttendees/getlist?uid=${candidateId}`
        };
        axios(option)
            .then(e => {
                setCourseApplication(e?.data);
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            });
    }

    const getJobFamily = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            url: `${Apiurl()}/candidate/jobfamilies`
        };
        axios(option)
            .then(e => {
                setJobFamilydata(e?.data);
            })
            .catch((err) => {
            });
    }

    const getSeniorityLevel = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${candidateToken}`
            },
            url: `${Apiurl()}/candidate/senioritylevels`
        };
        axios(option)
            .then(e => {
                setSeniorityLevel(e?.data);
            })
            .catch((err) => {
            });
    }
    useEffect(() => {
        getJobApplications();
        getCountries();
        getSeniorityLevel();
        getJobFamily();
        getInternApplications();
        getCourseApplications();
    }, [candidateId, candidateToken])

    const downLoadFile = (item, id) => {
        const CandidateId = candidate?.id;
        let fileType = "";
        let fileName = "";
        if (item === "nfoos") {
            fileType = "A";
            fileName = candidate?.nfoos;
        } else if (item === "passport") {
            fileType = "B";
            fileName = candidate?.passport;
        } else if (item === "highestQual") {
            fileType = "D";
            fileName = candidate?.highestQualificationCertificate;
        } else if (item === "cvFile") {
            fileType = "C";
            fileName = candidate?.cv;
        } else if (item !== "highestQual" && item !== "cvFile" && item !== "nfoos" && item !== "passport") {
            fileType = "E";
            fileName = item;
        }
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/pdf',
                'XApiKey': `${XApiKey()}`,
                'Authorization': `Bearer ${GetToken()}`
            },
            url: `${Apiurl()}/downloadfile?id=${CandidateId}&docid=${id}&type=${fileType}`
        };
        axios(option)
            .then(async (e) => {
                const downloadLink = document.createElement("a");
                downloadLink.href = e?.data;
                downloadLink.download = fileName;
                downloadLink.click();
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    RemoveUserSession();
                    navigate('/');
                }
            })
    }


    const viewJob = (item) => {
        navigate(`${item?.jobPost?.url}`)
    }

    const viewInternship = (item) => {
        navigate(`${item?.internship?.url}`)
    }

    const viewCourses = (item) => {
        navigate(`${item?.course?.url}`)
    }
    const navigateToStakeholder = (item) => {
        var type = "krg";
        if (item?.jobPost?.stakeholder?.type === "International Oil Company") {
            type = "ioc"
        } else if (item?.jobPost?.stakeholder?.type === "Refinery") {
            type = "refinery"
        } else if (item?.jobPost?.stakeholder?.type === "Pipeline Operator") {
            type = "pipelineoperator"
        } else if (item?.jobPost?.stakeholder?.type === "Oilfield Service Company") {
            type = "osc"
        } else if (item?.jobPost?.stakeholder?.type === "KRG") {
            type = "krg"
        }
        if (item?.jobPost?.stakeholder?.shortName.indexOf('/') !== -1) {
            navigate(`/stakeholder/${type}/${item?.jobPost?.stakeholder?.shortName.replace('/', '||')}`, { state: { stakeholder: item?.jobPost?.stakeholder } })
        }
        else {
            navigate(`/stakeholder/${type}/${item?.jobPost?.stakeholder?.shortName}`, { state: { stakeholder: item?.jobPost?.stakeholder } })
        }
    }
    const navigateToInternship = (item) => {
        var type = "krg";
        if ( item?.internship?.company?.type === "International Oil Company") {
            type = "ioc"
        } else if (item?.internship?.company?.type === "Refinery") {
            type = "refinery"
        } else if (item?.internship?.company?.type === "Pipeline Operator") {
            type = "pipelineoperator"
        } else if (item?.internship?.company?.type === "Oilfield Service Company") {
            type = "osc"
        } else if (item?.internship?.company?.type === "KRG") {
            type = "krg"
        }
        if (item?.internship?.company?.shortName.indexOf('/') !== -1) {
            navigate(`/stakeholder/${type}/${item?.internship?.company?.shortName.replace('/', '||')}`, { state: { stakeholder: item?.internship?.company } })
        }
        else {
            navigate(`/stakeholder/${type}/${item?.internship?.company?.shortName}`, { state: { stakeholder: item?.internship?.company } })
        }
    }

    const navigateToCourses = (item) => {
        var type = "";
        if (item?.stakeholder?.type === "International Oil Company") {
            type = "ioc"
        } else if (item?.stakeholder?.type === "Refinery") {
            type = "refinery"
        } else if (item?.stakeholder?.type === "Pipeline Operator") {
            type = "pipelineoperator"
        } else if (item?.stakeholder?.type === "Oilfield Service Company") {
            type = "osc"
        } else if (item?.stakeholder?.type === "KRG") {
            type = "krg"
        }
        if (item?.stakeholder?.shortName.indexOf('/') !== -1) {
            navigate(`/stakeholder/${type}/${item?.stakeholder?.shortName.replace('/', '||')}`, { state: { stakeholder: item?.stakeholder } })
        }
        else {
            navigate(`/stakeholder/${type}/${item?.stakeholder?.shortName}`, { state: { stakeholder: item?.stakeholder } })
        }
    }

    const navigateStep1 = () => {
        navigate('/profile', { state: { tab: "GeneralInfo" } })
    }
    const navigateStep2 = () => {
        navigate('/profile', { state: { tab: "Citizenship" } })
    }
    const navigateStep3 = () => {
        navigate('/profile', { state: { tab: "Qualifications" } })
    }
    return (
        <div>
            {
                loading ? <Loader /> : null
            }
            <Header loading={loading} setProfilePage={setProfilePage} profilePage={profilePage} candidateProfile="yes"/>
            <div id="jobDetailsIntro" className={loading ? "alternative-section profile-details-section-new" : "alternative-section profile-details-section"}>

                <Container>
                    <Row>
                        <Col className="col-sm-12">
                            <Card>
                                <CardBody>
                                    {/*<CardImg alt="logo" src={ 'assets/images/logos/dummy-logo.webp'}  width="145" />*/}
                                    <CardTitle tag="h3">{candidate?.firstName} {candidate?.lastName}<span className="edit-profile-step1 edit-button-cprofile general-edit-btn" onClick={() => { navigateStep1() }} title="Edit">Edit<i className="fa-regular fa-pen-to-square "></i></span></CardTitle>
                                    <div className="card-text">
                                        {candidate?.email ? <><i className="fa-light fa-envelope"></i><span className="job-location"><b>Email: </b>{candidate?.email}</span></> : null}

                                        {candidate?.localPhoneNo ? <>
                                            <i className="fa-light fa-phone"></i><span className="job-location"><b>Local Phone: </b>{localPhonecode?.diallingCode} {candidate?.localPhoneNo}</span>
                                        </> : null}

                                        {candidate?.internationalPhoneNo ? <>
                                            <i className="fa-light fa-phone"></i><span className="job-date-added"><b>International Phone: </b>{internationalPhonecode?.diallingCode} {candidate?.internationalPhoneNo}</span></> : null}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="jobDetails" className="mt-5 mb-5 candidate-profile-details">
                <Container>
                    <Row>
                        <Col className="col-12 col-md-8">
                            <div className="profile-tabs-heading">General Info </div>
                            <h3>Sex</h3>
                            <p>{candidate?.sex}</p>

                            <h3>Date of Birth</h3>
                            <p>{moment(candidate?.birthDate).format("DD MMM YYYY")}</p>

                            <h3>Nationality</h3>
                            <p>{nationality?.name}</p>

                            <h3>Resident Address</h3>
                            <p>{candidate?.residentAddress}</p>

                            <h3>Resident Country</h3>
                            <p ref={jobSection}>{residentCountry?.name}</p>
                            {
                                candidate?.nationalityId === 104 ? <>
                                    <h3>Do you have Iraqi Citizenship issued in the Kurdistan Region or province of Kirkuk?</h3>
                                    <p>{candidate?.hasCitizenship === true ? "Yes" : "No"}</p>
                                </> : null
                            }

                            {
                                jobApplication?.length ? <> <hr /><div className="job-application-candidate" >
                                    <h3 id="#candidateapplications">Job Applications</h3>

                                    <div className="application-data-profile"  >
                                        {
                                            jobApplication?.map((item, i) => {
                                                return (
                                                    <div key={i}>
                                                        <div className="profile-navigation"><h4 title={item?.jobPost?.title} onClick={() => viewJob(item)}>{item?.jobPost?.title} </h4> <span title={item?.jobPost?.stakeholder?.shortName } onClick={() => navigateToStakeholder(item)}>{item?.jobPost?.stakeholder?.shortName}</span></div>
                                                        <div className="application-date-candidate">{moment(item?.created).format("DD MMM YYYY")}</div>
                                                        <div className="application-date-candidate">Status : {item?.statusDescription}</div>
                                                        <p><a href={item?.cvUrl} download={item?.cv} className="profile-apply-cv " title="Download">{item?.cv}</a></p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> </>: null
                            }
                           
                            <p ref={internSection}> {
                                internApplication?.length ? <> <hr /><div className="job-application-candidate" >
                                    <h3 id="#candidateapplications">Internship Applications</h3>

                                    <div className="application-data-profile"  >
                                        {
                                            internApplication?.map((item, i) => {
                                                return (
                                                    <div key={i}>
                                                        <div className="profile-navigation"><h4 title={item?.internship?.title} onClick={() => viewInternship(item)}>{item?.internship?.title} </h4> <span title={item?.internship?.company?.shortName} onClick={() => navigateToInternship(item)}>{item?.internship?.company?.shortName}</span></div>
                                                        <div className="application-date-candidate">{moment(item?.created).format("DD MMM YYYY")}</div>
                                                        <div className="application-date-candidate">Status : {item?.statusString}</div>
                                                        <p><a href={item?.cvUrl} download={item?.cv} title="Download" className="profile-apply-cv ">{item?.cv}</a></p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div></> : null
                            }</p>
                            
                            <p ref={courseSection}> {
                                courseApplication?.length ? <><hr /><div className="job-application-candidate" >
                                    <h3 id="#candidateapplications">Course Registrations</h3>

                                    <div className="application-data-profile"  >
                                        {
                                            courseApplication?.map((item, i) => {
                                                return (
                                                    <div key={i}>
                                                        <div className="profile-navigation"><h4 title={item?.course?.title} onClick={() => viewCourses(item)}>{item?.course?.title} </h4> <span title={item?.stakeholder?.shortName} onClick={() => navigateToCourses(item)}>{item?.stakeholder?.shortName}</span></div>
                                                        <div className="application-date-candidate course-start-date"><b>Start Date: </b>{moment(item?.course?.startDate).format("DD MMM YYYY")}</div>
                                                        <div className="application-date-candidate"><b>End Date: </b>{moment(item?.course?.endDate).format("DD MMM YYYY")}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> </> : null
                            }</p>
                         

                        </Col>
                        <Col className="col-12 col-md-4">
                            <div className="side-bar mb-4">

                                <div className="profile-tabs-heading">Position & Qualifications<span className="edit-profile-step1 edit-button-cprofile" onClick={() => { navigateStep3() }} title="Edit">Edit<i className="fa-regular fa-pen-to-square "></i></span></div>

                                <div className="job-detail">
                                    <i className='fa-light fa-users profile-inner-icons'></i>
                                    <h4>Seniority Level</h4>
                                    <p>{seniority?.name}</p>
                                </div>
                                <div className="job-detail">
                                    <i className="fa-light fa-people-roof profile-inner-icons"></i>
                                    <h4>Profession</h4>
                                    {
                                        jobFamily?.map((item, i) => {
                                            return (
                                                <li key={i} className="jobfamily-profile">
                                                    {item?.name}
                                                </li>
                                            )
                                        })
                                    }
                                </div>
                                <div className="job-detail">
                                    <i className='fa-light fa-triangle-person-digging profile-inner-icons'></i>
                                    <h4>Relevant Experience</h4>
                                    <p>{candidate?.relevantExperience}</p>
                                </div>

                                <div className="job-detail">
                                    <i className='fa-light fa-diploma profile-inner-icons'></i>
                                    <h4>Highest Academic Qualification</h4>
                                    <p>{candidate?.highestQualification}</p>
                                </div>
                                {
                                    candidate?.highestQualificationCertificate && candidate?.highestQualification !== "None" ? <div className="job-detail">
                                        <i className='fa-light fa-diploma profile-inner-icons'></i>
                                        <h4>Highest Academic Qualification Certificate</h4>
                                        <p>{candidate?.highestQualificationCertificate}</p>
                                        <div className="download-button-clabels">
                                            <button className="download-button-cprofile" title="Download" onClick={() => downLoadFile("highestQual", 0)}>Download<AiOutlineDownload className="icons-cdownload-uploaders" /></button>
                                        </div>
                                    </div> : null
                                }

                                <div className="job-detail">
                                    <i className='fa-light fa-file-user profile-inner-icons'></i>
                                    <h4>CV</h4>
                                    <p>{candidate?.cv}</p>
                                    <div className="download-button-clabels">
                                        {
                                            candidate?.cv ? <button className="download-button-cprofile" title="Download" onClick={() => downLoadFile("cvFile", 0)}>Download<AiOutlineDownload className="icons-cdownload-uploaders" /></button> : null
                                        }
                                        
                                    </div>
                                </div>
                                {
                                    candidate?.otherDocuments?.length ?
                                        <div className="job-detail">
                                            <i className='fa-light fa-file-certificate profile-inner-icons'></i>
                                            <h4>Other Certificates</h4>
                                            {
                                                candidate?.otherDocuments?.map((item, i) => {
                                                    return (<Fragment key={i}>
                                                        <p>{item?.documentName}</p>
                                                        <div key={i} className="main-section-label-download">
                                                            <div className="download-button-cabels">
                                                                <button className="download-button-cprofile" tile="Download" onClick={() => downLoadFile(item?.documentName, item?.id)}>Download<AiOutlineDownload className="icons-cdownload-uploaders" /></button>

                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                    )
                                                })
                                            }

                                        </div> : null
                                }

                            </div>

                            <div className="side-bar mb-4">
                                <div className="profile-tabs-heading">Citizenship/Passport Info<span className="edit-profile-step1 edit-button-cprofile" onClick={() => { navigateStep2() }} title="Edit">Edit<i className="fa-regular fa-pen-to-square "></i></span></div>
                                {
                                    candidate?.nationalityId === 104 ? <> {candidate?.citizenshipNo && <div className="job-detail">
                                            <i className='fa-light fa-passport profile-inner-icons'></i>
                                            <h4>Citizenship No</h4>
                                            <p>{candidate?.citizenshipNo}</p>
                                        </div>}
                                        {candidate?.nfoos && <div className="job-detail">
                                            <i className='fa-light fa-passport profile-inner-icons'></i>
                                            <h4> Iraqi Citizenship (Nfoos)</h4>
                                            <p>{candidate?.nfoos}</p>
                                            <div className="download-button-clabels">
                                                <button className="download-button-cprofile" onClick={() => downLoadFile("nfoos", 0)} title="Download">Download<AiOutlineDownload className="icons-cdownload-uploaders" /></button>

                                            </div>
                                        </div>}
                                    </> : <>
                                            <div className="job-detail">
                                                <i className='fa-light fa-passport profile-inner-icons'></i>
                                                <h4>Passport Number</h4>
                                                <p>{candidate?.passportNumber}</p>
                                            </div>
                                            {
                                                candidate?.passport ? <div className="job-detail">
                                                    <i className='fa-light fa-passport profile-inner-icons'></i>
                                                    <h4>Passport</h4>
                                                    <p>{candidate?.passport}</p>
                                                    <div className="download-button-clabels">
                                                        <button className="download-button-cprofile" title="Download" onClick={() => downLoadFile("passport", 0)}>Download<AiOutlineDownload className="icons-cdownload-uploaders" /></button>
                                                    </div>
                                                </div> : null
                                            }
                                           
                                        </>
                                }
                                    </div>
                               
                        </Col>
                    </Row>
                </Container>
            </div>


            <Footer loading={loading} />
        </div>
    )
}

export default CandidateProfile;