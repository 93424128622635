import React from "react";
import { useEffect, useState } from "react";
import axios from 'axios';
import { Col, Row, Container } from "react-bootstrap";
import { Apiurl, XApiKey } from '../../../utils/utils';
import "./CompanyCovered.css";
import { useNavigate } from "react-router-dom";
import exploration from "../../../img/exploration.svg";
import equipment from "../../../img/equipment.svg";
import pipeline from "../../../img/pipeline.svg";
import renewable from "../../../img/renewable.svg";
import conventional from "../../../img/conventional.svg";
import transmission from "../../../img/transmission.svg";

const CompanyCovered = () => {
    const [searchOptions,setSearchOptions] = useState([])
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);


    const navigateCoveredSection = (item) => {        
        navigate("/jobs", { state: { jobsector: getSearchItemByValue(item) } })
    }
    const getSearchItemByValue = (item) => {
        return searchOptions.filter(itm=> itm.value === item.value && itm.type === item.type) || []
    }

    const getSearchOptions = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/jobpost/getsearchoptions`,
        }
        axios(option)
            .then((e) => {
                if (e?.status === 200) {
                    if (e?.data) {
                        setSearchOptions(e.data || [])
                    }
                }
            })
            .catch((err) => {
                setLoading(false)
                navigate('404')
            })
    }
    useEffect(() => {
        getSearchOptions()
    },[])

    return (
        <div className="company-section">
            <Container>
                <Row className="company-covered-row">
                    <Col md={12}>
                        <h1 className="company-covered-head mb-5 pt-4">Sectors Covered</h1>
                    </Col>
                    <Col md={6}>
                        <div className="company-inner-head mb-4">Oil & Gas</div>
                        <div className="company-list" onClick={() => navigateCoveredSection({ value: '3', type: 'JobSector', })} title="Exploration & Production">
                            <div className="company-img"><img src={exploration} alt="company" /></div>
                            <div className="company-list-head">Exploration & Production</div>
                        </div>
                        <div className="company-list" onClick={() => navigateCoveredSection({ value: '4', type: 'JobSector', })} title="Equipment & Services" >
                            <div className="company-img"><img src={equipment} alt="company" /></div>
                            <div className="company-list-head">Equipment & Services</div>
                        </div>
                        <div className="company-list" onClick={() => navigateCoveredSection({ value: '5', type: 'JobSector', })} title="Pipeline & Refining" >
                            <div className="company-img"><img src={pipeline} alt="company" /></div>
                            <div className="company-list-head">Pipeline & Refining</div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="company-inner-head mb-4">Power</div>
                        <div className="company-list" onClick={() => navigateCoveredSection({ value: '6', type: 'JobSector', })} title="Renewable Energy">
                            <div className="company-img"><img src={renewable} alt="company" /></div>
                            <div className="company-list-head">Renewable Energy</div>
                        </div>
                        <div className="company-list" onClick={() => navigateCoveredSection({ value: '7', type: 'JobSector', })} title="Conventional Energy">
                            <div className="company-img"><img src={conventional} alt="company" /></div>
                            <div className="company-list-head">Conventional Energy</div>
                        </div>
                        <div className="company-list" onClick={() => navigateCoveredSection({ value: '8', type: 'JobSector', })} title="Transmission & Distribution">
                            <div className="company-img"><img src={transmission} alt="company" /></div>
                            <div className="company-list-head">Transmission & Distribution</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default CompanyCovered;