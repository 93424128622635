import React, { useEffect, useState } from "react";
import "./IndustrySlider.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { Apiurl, GetToken, XApiKey } from "../../../utils/utils";

const IndustrySlider = () => {
    const navigate = useNavigate();
    const [stakeholderImage, setStakeHolderImage] = useState([]);
    const stakeholderLogo = stakeholderImage.filter(item => item?.imageUrl !== null);
    const getstakeholderImage = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/stakeholders`
        };
        axios(option)
            .then(e => {
                setStakeHolderImage(e?.data);
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                }
            });
    }
    useEffect(() => {
        getstakeholderImage()
    }, [])

    const navigateStakeholder = (item) => {
        var type = "";
        if (item?.type === "International Oil Company") {
            type = "ioc"
        } else if (item?.type === "Refinery") {
            type ="refinery"
        } else if (item?.type === "Pipeline Operator") {
            type = "pipelineoperator"
        } else if (item?.type === "Oilfield Service Company") {
            type = "osc"
        }
        navigate(`/stakeholder/${type}/${item?.shortName}`, { state: {stakeholder:item}})
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed:4000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

 

    return (
        <div className="industrySlider-section pt-5 my-5">
            <Container>
                <Row className="industrySlider-row">
                    <Slider {...settings} className="industrySlider-slider">
                        {
                            stakeholderLogo.map((item,i) => {
                                return (
                                    <div key={i}  className="industrySlider-card" onClick={()=>navigateStakeholder(item)}>
                                       
                                        {
                                            item?.imageUrl ? <img className="industrySlider-img" src={item?.imageUrl} title={item?.longName ? item?.longName : item?.shortName} /> :
                                                <div className="image-initials slider-initials">{item?.longName?.split(' ')[0].charAt(0).toUpperCase()}{item?.longName?.split(' ').length > 1 ? item?.longName?.split(' ')[item?.longName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </Row>
            </Container>
        </div>
    )
};

export default IndustrySlider;