import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { matchPath } from 'react-router';
import { CandidateIdGet, GetProfileTab } from '../utils/utils'

function IdleMonitor() {
    const navigate = useNavigate()
    //Modal
    const [idleModal, setIdleModal] = useState(false);

    let idleLogout = 1000 * 60 * 5 //5 Minutes
    let idleLogoutEvent;
    /**
     * Add any other events listeners here
     */
    const events = [
        'mousemove',
        'click',
        'keypress'
    ];

    /**
     * @method sessionTimeout
     * This function is called with each event listener to set a timeout or clear a timeout.
     */
    const routes = [
        {
            key: 'jobs',
            exact: true,
            path: '/jobs'
        },
        {
            key: 'resetpassword',
            exact: true,
            path: '/resetpassword'
        },
        {
            key: 'jobdetail',
            exact: true,
            path: '/job/:name'
        },
        {
            key: 'jobapplication',
            exact: true,
            path: '/application/:jobtitle'
        },
        {
            key: 'homepage',
            exact: true,
            path: '/'
        },
        {
            key: 'signup',
            exact: true,
            path: '/signup'
        },
        {
            key: 'profile',
            exact: true,
            path: '/profile'
        },
        {
            key: 'candidateprofile',
            exact: true,
            path: '/candidateprofile'
        },
        {
            key: 'password',
            exact: true,
            path: '/password'
        },
        {
            key: 'courses',
            exact: true,
            path: '/courses'
        },
        {
            key: 'coursedetail',
            exact: true,
            path: '/course/:name'
        },
        {
            key: 'courseapplication',
            exact: true,
            path: '/courseapplication/:coursetitle'
        },
        {
            key: 'internshipapplication',
            exact: true,
            path: '/internshipapplication/:interntitle'
        },
        {
            key: 'internships',
            exact: true,
            path: '/internships'
        },
        {
            key: 'internshipdetail',
            exact: true,
            path: '/internship/:name'
        },
        {
            key: 'stakeholder',
            exact: true,
            path: '/stakeholder/:name/:other'
        },
        {
            key: 'GenerateError',
            exact: true,
            path: '/generateerror'
        },
        {
            key: 'Error404',
            exact: true,
            path: '/404'
        },
        {
            key: 'PrivacyPolicy',
            exact: true,
            path: '/PrivacyPolicy'
        },
        {
            key: 'SignupSuccess',
            exact: true,
            path: '/SignupSuccess'
        },
        {
            key: 'EmailValidate',
            exact: true,
            path: '/EmailValidate'
        },
        {
            key: 'Terms',
            exact: true,
            path: '/Terms'
        },
        {
            key: 'Error404',
            exact: true,
            path: '*'
        }
    ]
    const currentRoute = routes.find(
        route => matchPath(route.path, window.location.pathname)
    )

    const sessionTimeout = () => {
        if (CandidateIdGet() === null
            && window.location.pathname !== '/'
            && window.location.pathname.toLowerCase() !== '/jobs'
            && !window.location.pathname.toLowerCase().includes('/job/')
            && window.location.pathname.toLowerCase() !== '/internships'
            && !window.location.pathname.toLowerCase().includes('/internship/')
            && window.location.pathname.toLowerCase() !== '/courses'
            && !window.location.pathname.toLowerCase().includes('/course/')
            && !window.location.pathname.toLowerCase().includes('/stakeholder/')
            && window.location.pathname.toLowerCase() !== '/signup'
            && window.location.pathname.toLowerCase() !== '/signupsuccess'
            && window.location.pathname.toLowerCase() !== '/emailvalidate'
            && window.location.pathname.toLowerCase() !== '/generateerror'
            && window.location.pathname.toLowerCase() !== '/privacypolicy'
            && window.location.pathname.toLowerCase() !== '/terms'
            && currentRoute.key !== "Error404") {
            clearTimeout(idleLogoutEvent)
            if (window.location.pathname.toLowerCase() === '/profile') {
                navigate('/?redirecturl=profile&tab=' + GetProfileTab())
            }
            else {
                navigate('/?redirecturl=' + window.location.pathname)
            }
        }
        else if (CandidateIdGet() === null
            && window.location.pathname !== '/'
            && currentRoute.key !== "Error404") {
            clearTimeout(idleLogoutEvent)
            //navigate(window.location.pathname)
        }
        if (!!idleLogoutEvent) clearTimeout(idleLogoutEvent);
        localStorage.setItem("time", new Date());
        idleLogoutEvent = setTimeout(() => logOut(), idleLogout); //Call logged out on session expire.
    };

    /**
     * @method extendSession
     * This function will extend current user session.
     */
    const extendSession = () => {
        console.log('user wants to stay logged in');
    }


    /**
     * @method logOut
     * This function will destroy current user session.
     */
    const logOut = () => {
        var addtenminutes = new Date(localStorage.getItem('time'));
        addtenminutes.setHours(addtenminutes.getHours(), addtenminutes.getMinutes() + 1, 0, 0);

        if (CandidateIdGet() > 0 && addtenminutes < new Date()) {
            localStorage.removeItem('candidateid');
            localStorage.removeItem('@token');
            if (window.location.pathname !== '/'
                && window.location.pathname.toLowerCase() !== '/jobs'
                && !window.location.pathname.toLowerCase().includes('/job/')
                && window.location.pathname.toLowerCase() !== '/internships'
                && !window.location.pathname.toLowerCase().includes('/internship/')
                && window.location.pathname.toLowerCase() !== '/courses'
                && !window.location.pathname.toLowerCase().includes('/course/')
                && !window.location.pathname.toLowerCase().includes('/stakeholder/')
                && window.location.pathname.toLowerCase() !== '/signup'
                && window.location.pathname.toLowerCase() !== '/signupsuccess'
                && window.location.pathname.toLowerCase() !== '/emailvalidate'
                && window.location.pathname.toLowerCase() !== '/generaterror'
                && window.location.pathname.toLowerCase() !== '/privacypolicy'
                && window.location.pathname.toLowerCase() !== '/terms'
                && currentRoute.key !== "Error404") {
                if (window.location.pathname.toLowerCase() === '/profile') {
                    navigate('/?redirecturl=profile&tab=' + GetProfileTab())
                }
                else {
                    navigate('/?redirecturl=' + window.location.pathname.toLowerCase())
                }
            }
        }
    }

    useEffect(() => {
        for (let e in events) {
            window.addEventListener(events[e], sessionTimeout);
        }

        return () => {
            for (let e in events) {
                window.removeEventListener(events[e], sessionTimeout);
            }
        }
    }, []);


    return (

        <Modal isOpen={idleModal} toggle={() => setIdleModal(false)}>
            <ModalHeader toggle={() => setIdleModal(false)}>
                Session expire warning
            </ModalHeader>
            <ModalBody>
                your session will expire in {idleLogout / 60 / 1000} minutes. Do you want to extend the session?
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-info" onClick={() => logOut()}>Logout</button>
                <button className="btn btn-success" onClick={() => extendSession()}>Extend session</button>

            </ModalFooter>
        </Modal>
    )

}

export default IdleMonitor;