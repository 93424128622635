import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardColumns } from 'reactstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import email from '../../img/stakeholder-email.png';
import './Stakeholder.css';
import { Apiurl, GetPortalTitle, XApiKey, CandidateIdGet, RemoveLocalStorage } from '../../utils/utils';
import axios from 'axios';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import Maps from '../../components/Map/Map';

const Stakeholder = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [stakeholder, setStakeholder] = useState();
    const [jobs, setJobs] = useState([]);
    const { name, other } = useParams();
    const stakeholdertype = location?.state?.stakeholder?.type;
    const stakeholderShortname = location?.state?.stakeholder?.shortName;
    let type = "";
    if (stakeholdertype === "International Oil Company" || name === "ioc") {
        type = "IOC"
    } else if (stakeholdertype === "Refinery" || name === "refinery") {
        type = "refinery"
    } else if (stakeholdertype === "Pipeline Operator" || name === "pipelineoperator") {
        type = "pipeline operator"
    } else if (stakeholdertype === "Oilfield Service Company" || name === "osc") {
        type = "OSC"
    } else if (stakeholdertype === "KRG") {
        type = "KRG"
    }
    const getStakeholderData = () => {
        setLoading(true);
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/stakeholders/GetByUrl?url=/Stakeholder/${type}/${stakeholderShortname || other}`,
        }
        axios(option)
            .then((e) => {
                setLoading(false);
                setStakeholder(e?.data)
                if (e?.status === 204) {
                    navigate('404');
                }

            })
            .catch((err) => {
                setLoading(false);
                navigate('404')

            })

    }

    const getStakeholderJobPost = () => {
        setLoading(true);
        const data = {}
        data.stakeholderid = stakeholder?.id;
        data.statuses = "Open";

        const candidateId = CandidateIdGet();
        if (candidateId) { data.candidateId = candidateId }

        const option = {
            method: 'Post', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            data: JSON.stringify(data),
            url: `${Apiurl()}/jobpost/getjobposts`,
        }
        axios(option)
            .then((e) => {
                if (e?.status === 200) {
                    setJobs(e?.data)
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                navigate('404')
            })
    }

    const viewJob = (item) => {
        navigate(`/Job/${item?.url.split("/Job/")[1]}`, { state: item })
    }

    const onError = (e) => {
        e.target.src = 'assets/images/logos/dummy-logo.webp'
    }

    useEffect(() => {
        getStakeholderData();
        RemoveLocalStorage("filters");
        RemoveLocalStorage("InternShipfilters");
        RemoveLocalStorage("Coursesfilters");
    }, [])
    useEffect(() => {
        if (stakeholder?.id ) {
            getStakeholderJobPost()
        }
      
    }, [stakeholder?.id])

    useEffect(() => {
        document.title = `${GetPortalTitle(stakeholderShortname)}`;
        window.scrollTo(0, 0);
    }, [])
    const [addressLat, setAddressLat] = useState('');
    const [addressLng, setAddressLng] = useState('');

    let Saddress = (stakeholder?.type === "Oilfield Service Company" && stakeholder?.oscCategory === "Local") || (stakeholder?.type === "KRG") ? stakeholder?.localAddress : stakeholder?.globalAddress;
    async function getLatLngFromAddress() {
        try {

            const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    address: Saddress,
                    key: 'AIzaSyBzcLJDtQLNwjJp611Dx6ZF_e646pJzr0c'
                }
            });

            const { lat, lng } = response.data.results[0].geometry.location;
            setAddressLat(lat);
            setAddressLng(lng);
            return { lat, lng };


        } catch (error) {

        }
    }

    useEffect(() => {
        if (Saddress) {
            getLatLngFromAddress(Saddress);
        }
    }, [Saddress])
    return (
        <div>
            {
                loading ? <Loader /> : null
            }
            <Header loading={loading} />
            <div id="stakeHolderIntro" className="alternative-section">
                <Container>
                    <Row>
                        <Col className="col-sm-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="col-12 col-md-2">
                                            {
                                                stakeholder?.imageUrl ? <CardImg className="cardImg" alt="logo" src={stakeholder?.imageUrl || 'assets/images/logos/dummy-logo.webp'} onError={(e) => onError(e)} /> :
                                                    <div className="image-initials">{stakeholder?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{stakeholder?.shortName?.split(' ').length > 1 ? stakeholder?.shortName?.split(' ')[stakeholder?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</div>
                                            }
                                        </Col>
                                        <Col className="col-12 col-md-10">
                                            <CardTitle tag="h3">{stakeholder?.longName}</CardTitle>
                                            <div className="card-text">
                                                <p><b>
                                                    {
                                                        stakeholder?.website ? <span className="stakeholder-links-resp" title={stakeholder?.website }>
                                                            <a className="stakeholder-header-icon" href={stakeholder?.website} target="_blank">{/*<img className="img1" src={link} alt="link" />*/}
                                                                <i className="fa-light fa-link"></i> Website</a>
                                                        </span> : null 
                                                    }
                                                    {
                                                        stakeholder?.website ? <span className="contacts-divider">|</span> : null
                                                    }
                                                   
                                                    {
                                                        stakeholder?.enquiriesEmail ? <span className="stakeholder-links-resp" title={stakeholder?.enquiriesEmail}>
                                                            <a className="stakeholder-header-icon" href={`mailto:${stakeholder?.enquiriesEmail}`}><img className="img2" src={email} alt="link" />{stakeholder?.enquiriesEmail}</a>
                                                        </span> : null 
                                                       
                                                    }
                                                    {
                                                        !(stakeholder?.enquiriesTel === "-" || stakeholder?.enquiriesTel === "" || stakeholder?.enquiriesTel === null) ? <span className="contacts-divider">|</span> : null
                                                    }
                                                    {
                                                        !(stakeholder?.enquiriesTel === "-" || stakeholder?.enquiriesTel === "" || stakeholder?.enquiriesTel === null) ? <span className="stakeholder-header-icon stakeholder-links-resp">
                                                            <i className="fa-light fa-phone"></i>{stakeholder?.enquiriesTelCountry?.diallingCode}{stakeholder?.enquiriesTel}</span> : null
                                                    }
                                                   
                                                </b></p>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="jobDetails" className="stakeholder-content-wrapper mt-5 mb-5">
                <Container>
                    <Row>
                        <Col className="col-12 col-md-8">

                            {
                                stakeholder?.aboutUs ? <>
                                    <h3 className="stakeholder-aboutus-head">About Us</h3>
                                    {stakeholder?.aboutUs.split('\r\n')?.map((item, i) => (
                                        <Fragment key={i}>
                                            {item !== "" ?
                                                <> {item.includes(':') ? <p key={i}>{item}</p> : <p key={i}>{item}</p>}</>
                                                : null}

                                        </Fragment>
                                    ))}
                                </> : null
                            }

                            <div id="similarJobsIntro" className="open-positions">
                                <Row>
                                    <Col className="col-sm-12">
                                        <h3>Open positions</h3>
                                    </Col>
                                </Row>
                            </div>
                            {
                                jobs?.length === 0 ? <p>There are no open position at the moment.</p> : null
                            }
                            <div id="jobsList1">
                                <Row>
                                    <Col className="col-12">
                                        <CardColumns>
                                            {jobs.map((item) => (
                                                <div onClick={() => viewJob(item)} className="cardDiv" key={item?.id} title={item.title}>
                                                    <Card className={item?.id % 2 === 0 ? "even" : "odd"}>
                                                        <CardBody>
                                                            {
                                                                stakeholder?.imageUrl ? <CardImg alt="Card image cap" src={stakeholder?.imageUrl || "assets/images/logos/dummy-logo.webp"} onError={(e) => onError(e)} width="145" /> :
                                                                    <span className="image-initials-popular jobs-img-initials"><span>{stakeholder?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{stakeholder?.shortName?.split(' ').length > 1 ? stakeholder?.shortName?.split(' ')[stakeholder?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</span></span>
                                                            }
                                                            <CardTitle tag="h3" className="stake-long-name">{item?.title?.split(".")[0]}
                                                                <div className="badges-wrapper ms-0">
                                                                    <span className="badge green align-middle">{item?.employmentTypeDescription}</span>
                                                                    {item?.isUrgent ? <span className="badge gold align-middle">Urgent</span> : null}
                                                                </div>
                                                            </CardTitle>
                                                            <div className="card-text">
                                                                <div className="icons listing"><i className="fa-solid fa-oil-well"></i><strong>Job Sector: </strong>{item?.subSector?.parent?.name}</div>
                                                                <div className="icons listing"><i className="fa-regular fa-oil-well"></i><strong>Sub Sector: </strong>{item?.subSector?.name}</div>
                                                                <div className="icons job-location listing"><i className="fa-solid fa-location-crosshairs"></i><strong>Location: </strong>{item?.countryDescription}, {item?.cityText}</div>
                                                                <br /><div className="icons job-family listing"><i className="fa-regular fa-briefcase"></i><strong>Profession: </strong>{item?.jobFamily?.name}</div>
                                                                <div className="icons job-experience listing"><i className="fa-regular fa-ranking-star"></i><strong>Experience: </strong>{item?.relevantExperience} years</div>
                                                                <br /><div className="icons job-salary listing"><i className="fa-solid fa-money-bills"></i><strong>Salary: </strong>{item?.currencyDescription} {item?.minSalary.toLocaleString()} - {item?.maxSalary.toLocaleString()}</div>
                                                                <div className="icons job-date-added listing"><i className="fa-solid fa-clock"></i><strong>Posted: </strong>{moment(item?.created).format("DD MMM YYYY")}</div>
                                                                <div className="icons job-career-level listing"><i className="fa-light fa-people-roof"></i><strong>Seniority Level: </strong>{item?.seniority?.name}</div>
                                                                <div className="job-desc">
                                                                    <p>{item?.responsibilities || item?.performance || item?.competency}</p>
                                                                </div>
                                                                {item?.candidateApplied === true ? <div className="job-desc">
                                                                    <p>You applied for this job on {moment(item?.latestApplication_Created).format("DD MMM YYYY")}</p></div> : null}
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            ))}


                                        </CardColumns>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col className="col-12 col-md-4 side stake-new-margin">
                            <h3 className="stakeholder-aboutus-head">Company Overview</h3>
                            <div className="side-bar mb-4">
                                <div className="job-detail stakeholder-detail">
                                    <div className="sidebar-stake-icon">
                                        <i className="fa-light fa-industry-windows"></i>
                                    </div>
                                    <div className="sidebar-stake-data">
                                        <h4> Type </h4>
                                        <p>{stakeholder?.type}</p>
                                    </div>
                                </div>

                                <div className="job-detail stakeholder-detail">
                                    <div className="sidebar-stake-icon">
                                        <i className="fa-light fa-earth-asia"></i>
                                    </div>
                                    <div className="sidebar-stake-data">
                                        <h4>Nationality </h4>
                                        <p>{stakeholder?.country?.name}</p>
                                    </div>
                                </div>

                                <div className="job-detail stakeholder-detail">
                                    <div className="sidebar-stake-icon">
                                        <i className="fa-light fa-calendar-day"></i>
                                    </div>
                                    <div className="sidebar-stake-data">
                                        <h4>Year Founded</h4>
                                        <p>{stakeholder?.establishment ? moment(stakeholder?.establishment).format("YYYY") : ""}</p>
                                    </div>
                                </div>
                                <div className="job-detail stakeholder-detail">
                                    <div className="sidebar-stake-icon">
                                        <i className="fa-light fa-users"></i>
                                    </div>
                                    <div className="sidebar-stake-data">
                                        <h4>No. of Employees</h4>
                                        <p>{stakeholdertype === "KRG" ? "50+" : stakeholder?.employeeCount}</p>
                                    </div>
                                </div>

                            </div>

                            <h3>Headquarters</h3>
                            {
                                Saddress ? <>
                                    <div className="side-bar map-wrapper mb-4" style={{ width: '100%', height: '500px', marginBottom: '100px' }}>

                                        <p className="headquarters-stakeholder pb-3">{(stakeholder?.type === "Oilfield Service Company" && stakeholder?.oscCategory === "Local") || stakeholder?.type === "KRG" ? stakeholder?.localAddress : stakeholder?.globalAddress}</p>
                                        {
                                            addressLat && addressLng && <Maps className={"maps-stackholder"} setAddressLat={addressLat} setAddressLng={addressLng} />
                                        }
                                    </div>
                                </> : <p className="headquarters-stakeholder pb-3 pt-3">Headquarter Address not available.</p>
                            }



                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer loading={loading} />
        </div>
    );
}
export default Stakeholder;