import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, List } from 'reactstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import Headers from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import { Apiurl, CandidateIdGet, GetPortalTitle, GetToken, XApiKey, RemoveLocalStorage } from '../../utils/utils';


const CourseDetails = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [applyBtn, setApplyBtn] = useState(false);
    const url = window.location.pathname;
    const actualUrl = url?.split('/');
    const Token = GetToken();
    const [courseData, setCourseData] = useState([]);
    const Candidateid = CandidateIdGet();
    const [loginIsApply, setLoginIsApply] = useState(false);
    const [applyDetails, setApplyDetails] = useState('')
    const applyNow = () => {
        if (!Token) {
            setApplyDetails("Course");
            setLoginIsApply(true);

        } else {
            navigate(`/courseapplication/${actualUrl?.[2]}`, { state: courseData })
        }
    }

    const goToTop = () => {
        window.scrollTo(0, 0);
    }

    const GetCourseByUrl = () => {
        setLoading(true);
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/course/getbyurl?url=${url}&uId=${Candidateid}`,

        }
        axios(option)
            .then((e) => {
                setLoading(false)
                if (e?.status === 200) {
                    setCourseData(e?.data)
                    if (e?.data?.applied) {
                        setApplyBtn(false)
                        toast.success("You have already registered for this course");
                    } else {
                        const deadline = moment(e?.data?.deadline).format('L');
                        const today = moment(new Date()).format('L');
                        if (today <= deadline && e?.data?.status === "Open") {
                            setApplyBtn(true)
                        }
                    }
                }
                if (e?.status === 204) {
                    navigate('404')
                }
            })
            .catch((err) => {
                setLoading(false);
                navigate('404')
            })
    }

    useEffect(() => {
        GetCourseByUrl();
        goToTop();
        RemoveLocalStorage("filters");
        RemoveLocalStorage("InternShipfilters");
    }, [])

    useEffect(() => {
        document.title = `${GetPortalTitle(courseData?.title)}`;
    }, [courseData])

    const navigateToStakeholder = () => {
        var type = "";
        if (courseData?.company?.type === "International Oil Company") {
            type = "ioc"
        } else if (courseData?.company?.type === "Refinery") {
            type = "refinery"
        } else if (courseData?.company?.type === "Pipeline Operator") {
            type = "pipelineoperator"
        } else if (courseData?.company?.type === "Oilfield Service Company") {
            type = "osc"
        } else if (courseData?.company?.type === "KRG") {
            type = "krg"
        }
        if (courseData?.company?.shortName.indexOf('/') !== -1) {
            navigate(`/stakeholder/${type}/${courseData?.company?.shortName.replace('/', '||')}`, { state: { stakeholder: courseData?.company } })
        }
        else {
            navigate(`/stakeholder/${type}/${courseData?.company?.shortName}`, { state: { stakeholder: courseData?.company } })
        }
    }


    return (

        <div>

            {
                loading ? <Loader /> : null
            }
            
            <Headers setApplyBtn={setApplyBtn} loginIsApply={loginIsApply} setLoginIsApply={setLoginIsApply} applyDetails={applyDetails} courseData={courseData} loading={loading} />

            <div id="jobDetailsIntro" className="alternative-section">
                <Container>
                    <Row>
                        <Col className="col-sm-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="col-12 col-sm-2 col img-col">
                                            {
                                                courseData?.company?.imageUrl ? <CardImg className="cardImg" alt="logo" src={courseData?.company?.imageUrl || 'assets/images/logos/dummy-logo.webp'} title={courseData?.company?.shortName} /> :
                                                    <div className="image-initials" title={courseData?.company?.shortName}>{courseData?.company?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{courseData?.company?.shortName?.split(' ').length > 1 ? courseData?.company?.shortName?.split(' ')[courseData?.company?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</div>
                                            }
                                        </Col>
                                        <Col className="col-12 col-sm-10 col">
                                            <CardTitle tag="h3">{courseData?.title}</CardTitle>
                                            <div className="card-text">
                                                <div className="top-section-job-details">
                                                    <div className="top-family-location">
                                                        {courseData?.city ? <div>
                                                            <i className='fa-solid fa-location-crosshairs'></i><span className="job-location"><strong> Role Location: </strong>{courseData?.countryDescription ? courseData?.countryDescription + ", " : null} {courseData?.city}</span>
                                                        </div> : null}
                                                        {/*{courseData?.fee ? <div>*/}
                                                        {/*    <i className='fa-solid fa-money-bills'></i><span className="job-salary"><strong>Offered Salary: </strong>{courseData?.fee}</span></div> : null}*/}
                                                        {courseData?.deadline ? <div>
                                                            <i className='fa-solid fa-clock'></i><span className="job-date-added"><strong>Deadline: </strong>{moment(courseData?.deadline).format("DD MMM YYYY")}</span></div> : null}
                                                    </div>
                                                    {/*{courseData?.startDate ? <div>*/}
                                                    {/*    <i className='fa-solid fa-clock'></i><span className="job-date-added"><strong>Start Date: </strong>{moment(courseData?.startDate).format("LL")}</span></div> : null}*/}
                                                    {/*{courseData?.endDate ? <div>*/}
                                                    {/*    <i className='fa-solid fa-clock'></i><span className="job-date-added"><strong>End Date: </strong>{moment(courseData?.deadline).format("LL")}</span></div> : null}*/}

                                                </div>
                                                {
                                                    applyBtn ? <button onClick={() => applyNow()} className="btn btn-primary apply apply-now-view-dtls" title="Enroll Now" >Enroll Now</button>
                                                        : null
                                                }

                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="jobDetails" className="mt-5 mb-5">
                <Container>
                    <Row>
                        <Col className="col-12 col-md-8">

                            {courseData?.description ? <div className="other-para">
                                <h3>Description:</h3>
                                {courseData?.description.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}

                            {courseData?.objectives ? <div className="other-para">
                                <h3>Objectives:</h3>
                                {courseData?.objectives.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}


                            {courseData?.learningOutcomes ? <div className="other-para">
                                <h3>Learning Outcomes:</h3>
                                {courseData?.learningOutcomes.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}

                            {courseData?.trainingMethodology ? <div className="other-para">
                                <h3>Training Methodology:</h3>
                                {courseData?.trainingMethodology.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}

                            {courseData?.agenda ? <div className="other-para">
                                <h3>Agenda:</h3>
                                {courseData?.agenda.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}

                            {courseData?.aboutTheTrainer ? <div className="other-para">
                                <h3>About the Trainer:</h3>
                                {courseData?.aboutTheTrainer.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}</div> : null}

                            {courseData?.timeSchedule ? <div className="other-para">
                                <h3>Time Schedule:</h3>
                                {courseData?.timeSchedule.split('\r\n')?.map((item, i) => (
                                    <Fragment key={i}>
                                        {
                                            item !== "" ? <p>{item}</p> : null
                                        }
                                    </Fragment>
                                ))}
                            </div> : null}
                        </Col>

                        <Col className="col-12 col-md-4">
                            <div className="side-bar mb-4">
                                    <div className="job-detail job-experience">
                                        <i className="fa-solid fa-money-bills jobdetails-inner-icons"></i>
                                    <h4>Fee </h4>
                                    <p>{courseData?.fee ? "$" + courseData.fee : 'Free'}</p>
                                    </div> 
                                {courseData?.startDate ?
                                    <div className="job-detail job-experience">
                                        <i className="fa-solid fa-calendar-days jobdetails-inner-icons"></i>
                                        <h4>Start Date</h4>
                                        <p>{moment(courseData?.startDate).format("DD MMM YYYY")}</p>
                                    </div> : null}
                                {courseData?.endDate ?
                                    <div className="job-detail job-experience">
                                        <i className="fa-solid fa-calendar-days jobdetails-inner-icons"></i>
                                        <h4>End Date</h4>
                                        <p>{moment(courseData?.endDate).format("DD MMM YYYY")}</p>
                                    </div> : null}
                                {courseData?.status ?
                                    <div className="job-detail job-experience">
                                        <i className="fa-solid fa-signal jobdetails-inner-icons"></i>
                                        <h4>Status</h4>
                                        <p>{courseData?.status}</p>
                                    </div> : null}
                                {/*{courseData?.attendeeCount ?*/}
                                {/*    <div className="job-detail job-experience">*/}
                                {/*        <i className="fa fa-list-ol jobdetails-inner-icons"></i>*/}
                                {/*        <h4>No of Attendees</h4>*/}
                                {/*        <p>{courseData?.attendeeCount}</p>*/}
                                {/*    </div> : null}*/}

                            </div>

                            <div className="side-bar employer employer-company mb-4">
                                <div className='logo-section mb-4'>
                                    {
                                        courseData?.company?.imageUrl ? <img src={courseData?.company?.imageUrl || "assets/images/logos/dummy-logo.webp"} width="145" title={courseData?.company?.shortName} /> :
                                            <div className="image-initials job-view-initials" title={courseData?.company?.shortName}>{courseData?.company?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{courseData?.company?.shortName?.split(' ').length > 1 ? courseData?.company?.shortName?.split(' ')[courseData?.company?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</div>
                                    }

                                    <h4><span>{courseData?.company?.shortName}</span></h4>
                                </div>
                                <ul>
                                    {courseData?.company?.type === "Oilfield Service Company" && courseData?.company?.categories ?
                                        <li>Categories: <span className="paddingbottom10"> {courseData?.company?.categories}</span></li> : null}
                                    {courseData?.company?.establishment ?
                                        <li>Founded Date: <span>{courseData?.company?.establishment.slice(0, 4)}</span></li> : null}
                                    {courseData?.company?.country?.name ?
                                        <li>Location: <span>{courseData?.company?.country?.name}</span></li> : null}
                                    {!(courseData?.company?.enquiriesTel === "-" || courseData?.company?.enquiriesTel === "" || courseData?.company?.enquiriesTel === null) ?
                                        <li> Phone Number: <span>{courseData?.company?.enquiriesTelCountry?.diallingCode}{courseData?.company?.enquiriesTel}</span></li> : null}
                                    {courseData?.company?.enquiriesEmail ?
                                        <li>Email: <span title={courseData?.company?.enquiriesEmail }><a href={'mailto:' + courseData?.company?.enquiriesEmail}>{courseData?.company?.enquiriesEmail}</a></span></li> : null}
                                </ul>
                                <div className="btn btn-primary apply" onClick={() => navigateToStakeholder()} title="View Company Profile" >View Company Profile</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer loading={loading} />

        </div>


    );
}
export default CourseDetails;
