import React, { useState, useEffect } from 'react'
import { useLocation, Routes, Route } from 'react-router-dom';
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import guidance from "./img/important-info.svg";
import checked from "./img/checked.svg"
import ResetPassword from './pages/resetPassword/resetpassword';
import { ToastContainer } from 'react-toastify';
import Jobs from '../src/pages/Jobs'
import JobDetails from './pages/JobDetails';
import Application from './pages/Application';
import Home from './pages/Home/Home';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Terms from './pages/Terms/Terms';
import Signup from './pages/Signup/Signup';
import SignupSuccess from './pages/Signup/SignupSuccess';
import EmailValidate from './pages/Signup/EmailValidate';
import Profile from './pages/Profile/Profile';
import Password from './components/password';
import Courses from './pages/Courses/Courses';
import Internships from './pages/Internships/Internships';
import Stakeholder from './pages/Stakeholder/Stakeholder';
import GenerateError from './pages/GenerateError';
import Error404 from './pages/Error404/Error404';
import CandidateProfile from './pages/CandidateProfile/CandidateProfile';
import CourseDetails from './pages/Courses/CourseDetails';
import InternshipDetails from './pages/Internships/InternshipDetails';
import InternshipApplication from './pages/Internships/Applications/InternshipApplication';
import CourseApplication from './pages/Courses/Application/CourseApplication';
import IdleMonitor from './utils/IdleMonitor';
import { Apiurl, XApiKey } from "./utils/utils";
import axios from "axios";

function App() {

    return (
        <>

            <IdleMonitor />

            <div className="App">

                <ErrorBoundary>

                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />

                    <Routes>
                        <Route path='/jobs' element={<Jobs />}></Route>
                        <Route path='/resetpassword' element={<ResetPassword />} />
                        <Route path='/job/:name' element={<JobDetails />} />
                        <Route path='/application/:jobtitle' element={<Application />} />
                        <Route path='/' element={<Home />} />
                        <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
                        <Route path='/Terms' element={<Terms />} />
                        <Route path='/signup' element={<Signup />} />
                        <Route path='/signupsuccess' element={<SignupSuccess />} />
                        <Route path='/emailvalidate' element={<EmailValidate />} />
                        <Route path='/profile' element={<Profile />} />
                        <Route path='/candidateprofile' element={<CandidateProfile />} />
                        <Route path='/password' element={<Password />} />
                        <Route path='/courses' element={<Courses />} />
                        <Route path='/course/:name' element={<CourseDetails />} />
                        <Route path='/courseapplication/:coursetitle' element={<CourseApplication />} />
                        <Route path='/internshipapplication/:interntitle' element={<InternshipApplication />} />
                        <Route path='/internships' element={<Internships />} />
                        <Route path='/internship/:name' element={<InternshipDetails />} />
                        <Route path='/stakeholder/:name/:other' element={<Stakeholder />} />
                        <Route path='/GenerateError' element={<GenerateError />} />
                        <Route path='*' element={<Error404 />} />
                    </Routes>

                </ErrorBoundary>

            </div>          

        </>
    );
}

/**
 * NEW: The error boundary has a function component wrapper.
 */
function ErrorBoundary({ children }) {
    const [hasError, setHasError] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (hasError) {
            setHasError(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.key]);
    return (
        /**
         * NEW: The class component error boundary is now
         *      a child of the functional component.
         */
        <ErrorBoundaryInner
            hasError={hasError}
            setHasError={setHasError}
        >
            {children}
        </ErrorBoundaryInner>
    );
}

/**
 * https://dev.to/tylerlwsmith/error-boundary-causes-react-router-links-to-stop-working-50gb
 * 
 * NEW: The class component accepts getters and setters for
 *      the parent functional component's error state.
 */
class ErrorBoundaryInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error) {
        return { hasError: true };
    }

    componentDidUpdate(prevProps, _previousState) {
        if (!this.props.hasError && prevProps.hasError) {
            this.setState({ hasError: false });
        }
    }

    componentDidCatch(error, errorInfo) {

        const url = window?.location?.href.split("/*")[0];
        if (!url.includes('http://localhost')) {
            const option = {
                method: 'GET', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'application/json',
                    'XApiKey': `${XApiKey()}`,
                },
                url: `${Apiurl()}/pageerror?site=Job Portal&url=${url}&error=${error && error.toString()}&componentstack=${errorInfo.componentStack}`
            };

            axios(option)
                .then(e => {
                })
                .catch((err) => {
                });

            //--

            this.props.setHasError(true);
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <Header />
                    <div className="informationHome-section error-section-container">
                        <Container>
                            <Row className="informationHome-row">
                                <Col md={12} className="mt-3">
                                    <h2 className="informationHome-head mb-4">Website Error</h2>

                                </Col>
                                <Col md="5">
                                    <img src={guidance} alt="banner" />
                                </Col>
                                <Col md="7" className="ps-0">
                                    <h5 className="error-section">The webpage you are trying to reach can't be found, possible reasons are:</h5>
                                    <li className="informationHome-img-checked error-data"><img src={checked} alt="checked" />
                                        <span>The page has been moved or deleted.</span>
                                    </li>
                                    <li className="informationHome-img-checked error-data"><img src={checked} alt="checked" />
                                        <span>The URL has been entered incorrectly. </span>
                                    </li>
                                    <li className="informationHome-img-checked error-data"><img src={checked} alt="checked" />
                                        <span>You have followed an out of date bookmark or link. </span>
                                    </li>
                                    <li className="informationHome-img-checked error-data"><img src={checked} alt="checked" />
                                        <span>An error has occurred. IT have been automatically notified and the issue will be resolved promptly. </span>
                                    </li>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Footer />
                </div>
            );
        }
        return this.props.children;
    }
}

export default App;