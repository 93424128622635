const CONFIG_URLS = {
    live: {
        privacyPolicy: 'https://www.mnronline.com/PrivacyPolicy.aspx',
        terms: 'https://www.mnronline.com/Terms.aspx',
        aboutUs: 'https://www.mnronline.com/Skynet/AboutUs.aspx',
        core:'https://core.mnronline.com/api'
    },
    test: {
        privacyPolicy: 'https://test.mnronline.com/PrivacyPolicy.aspx',
        terms: 'https://test.mnronline.com/Terms.aspx',
        aboutUs: 'https://test.mnronline.com/Skynet/AboutUs.aspx',
        core: 'https://test.core.mnronline.com/api'
    },
    local: {
        privacyPolicy: 'http://localhost/PrivacyPolicy.aspx',
        terms: 'http://localhost/Terms.aspx',
        aboutUs: 'http://localhost/Skynet/AboutUs.aspx',
        core: 'http://localhost:84/api'
    }
}
export default {CONFIG_URLS};