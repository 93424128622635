import axios from 'axios';
import { Col, Container, Form, Row } from 'react-bootstrap'
import { Apiurl, GetToken, XApiKey } from '../../../utils/utils';
import './CitizenshipInfoProfile.css';
import { AiOutlineDownload } from 'react-icons/ai'
const CitizenshipInfoProfile = ({ formValues, onChange, candidate }) => {
    const downLoadFile = (candidate) => {

        const CandidateId = candidate?.id;
        const SubmitType = candidate?.nfoos ? "A" : candidate?.passport ? "B" : null
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/pdf',
                'XApiKey': `${XApiKey()}`,
                'Authorization': `Bearer ${GetToken()}`
            },
            url: `${Apiurl()}/downloadfile?id=${CandidateId}&docid=${0}&type=${SubmitType}`
        };
        axios(option)
            .then((e) => {
                const downloadLink = document.createElement("a");
                downloadLink.href = e?.data;
                downloadLink.download = candidate?.nfoos ? candidate?.nfoos : candidate?.passport;
                downloadLink.click();
            })
            .catch((err) => {

            })
    }
    
    return (
        <>
            <Container>
                <Row>
                    {
                        formValues?.nationality === "104" || formValues?.nationality === 104 ? <Col md={6}>
                            <Form.Group className="mb-3 citizenship_input">
                                <Form.Label className="citizenship_input">Citizenship No<span>*</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter Citizenship No" onChange={onChange} name="citizenNumber" value={formValues?.citizenNumber} />
                            </Form.Group>
                        </Col> : <Col md={6}>
                            <Form.Group className="mb-3 citizenship_input">
                                <Form.Label>Passport Number<span>*</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter Passport Number" onChange={onChange} name="passportNumber" value={formValues?.passportNumber} />
                            </Form.Group>
                        </Col>
                    }


                </Row>
                <Row>

                    {
                        formValues?.nationality === "104" || formValues?.nationality === 104 ?
                            <Col md={6}>
                                <Form.Group className="mb-3 citizenship_input" >
                                    <Form.Label>Upload Iraqi Citizenship (Nfoos)<span>*</span></Form.Label>
                                    <div className='input-file-qualification nfoos-mobile'>
                                        <Form.Control id="iraqiCitizenship" accept="application/pdf" name="citizenshipDocument" type="file" onChange={onChange} />

                                    </div>
                                </Form.Group>

                                {
                                    candidate?.nfoos ?
                                        <div className="main-section-label-download">
                                            <div className="label-section-after-submit-uploaders">
                                                <input type="text" className="form-control input-label-show" defaultValue={candidate?.nfoos} />
                                            </div>

                                            <div className="download-button-labels">
                                                <button onClick={() => downLoadFile(candidate)} title="Download">Download<AiOutlineDownload className="icons-download-uploaders" /></button>
                                                
                                            </div>
                                        </div>
                                        : null
                                }

                            </Col> :
                            <Col md={6}>
                                <Form.Group className="mb-3 citizenship_input" >
                                    <Form.Label>Upload Passport<span>*</span></Form.Label>
                                    <div className='input-file-qualification nfoos-mobile'>
                                        <Form.Control id="passportfile" type="file" accept="application/pdf" onChange={onChange} name="passportFile" className="passport-upload-second-tab" />
                                    </div>
                                </Form.Group>

                                {
                                    candidate?.passport ?
                                        <div className="main-section-label-download">

                                            <div className="label-section-after-submit-uploaders">
                                                <input type="text" className="form-control input-label-show" defaultValue={candidate?.passport} disabled />
                                            </div>

                                            <div className="download-button-labels">
                                                <button onClick={() => downLoadFile(candidate)} title="Download">Download<AiOutlineDownload className="icons-download-uploaders" /></button>
                                                
                                            </div>
                                        </div>
                                        : null
                                }
                            </Col>
                    }
                </Row>
            </Container>
        </>
    )
}

export default CitizenshipInfoProfile;