import React, { useState, useRef, useEffect } from 'react';
import { default as ReactSelect } from "react-select";
import {
    Container, Row, Col, Button, Form, Label, Input, FormGroup, CardColumns,
    Card, CardImg, CardBody, CardTitle, Pagination, PaginationItem, PaginationLink, UncontrolledCollapse
} from 'reactstrap';
import moment from 'moment';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { Apiurl, XApiKey, GetPortalTitle, CandidateIdGet, SetLocalStorage, GetLocalStorage, RemoveLocalStorage } from '../../utils/utils';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Loader from '../../components/Loader/loader';
import Option from '../../components/Option';

const Courses = () => {
    const selectLocationInputRef = useRef();
    const [loading, setLoading] = useState(true);
    const [allFilterData, setAllFilterData] = useState([])
    const dropdownLocation = allFilterData.filter(item => item?.type === "Country")
    const dropdownDatePosted = allFilterData.filter(item => item?.type === "Created")
    const dropdownStakeholders = allFilterData.filter(item => item?.type === "Stakeholders")
    const [course, setCourse] = useState([]);
    const [coursesLength, setCoursesLength] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [searchLocation, setSearchLocation] = useState();
    const [filterLocation, setFilterLocation] = useState();
    const [filterLocationArray, setFilterLocationArray] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [showingFrom, setShowingFrom] = useState(1);
    const [showingTo, setShowingTo] = useState(perPage);
    const [sortBy, setSortBy] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [recentCourses, setRecentCourses] = useState([]);
    const [popularCourses, setPopularCourses] = useState([]);
    const [pageArray, setPageArray] = useState([]);
    const [perpageArray, setPerpageArray] = useState([]);
    const [datePosted, setDatePosted] = useState("");
    const [reloadList, setReloadList] = useState(false);
    const [searchtext, setSearchText] = useState();
    const filterData = JSON.parse(GetLocalStorage("Coursesfilters"));

    const goToTop = () => {
        window.scrollTo(0, 0);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            getCoursesList();
        }
    }

    const getallFilterData = () => {
        setLoading(true);
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/course/getsearchoptions`
        };
        axios(option)
            .then(e => {
                setAllFilterData(e?.data);
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                }
            });
    }

    const getRecentCoursesList = () => {
        setLoading(true);
        const data = {}
        data.pageno = 1;
        data.perpage = 4;
        data.sortby = "created_desc";
        const candidateId = CandidateIdGet();
        if (candidateId) { data.candidateId = candidateId }
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            data: JSON.stringify(data),
            url: `${Apiurl()}/course/list`
        };
        axios(option)
            .then((e) => {
                if (e?.status === 200) {
                    if (e?.data?.length > 0) {
                        setRecentCourses(e?.data)
                    }
                }
            })
            .catch((err) => {
            })
    }

    const getPopularCoursesList = () => {
        setLoading(true);
        const data = {}
        data.pageno = 1;
        data.perpage = 4;
        data.sortby = "attendeecount_desc";
        const candidateId = CandidateIdGet();
        if (candidateId) { data.candidateId = candidateId }
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            data: JSON.stringify(data),
            url: `${Apiurl()}/course/list`
        };
        axios(option)
            .then((e) => {
                if (e?.status === 200) {
                    if (e?.data?.length > 0) {
                        setPopularCourses(e?.data)
                    }
                }
            })
            .catch((err) => {
            })
    }

    useEffect(() => {
        setLoading(true);
        document.title = `${GetPortalTitle('Courses')}`;
        getallFilterData();
        goToTop();
        getPopularCoursesList()
        getRecentCoursesList();
        RemoveLocalStorage("filters");
        RemoveLocalStorage("InternShipfilters");
    }, [])

    //filter function starts from here
    //filter by stakeholders
    const [stakeholdersArray, setStakeholdersArray] = useState([]);
    const [stakeholdersValues, setStakeholdersValues] = useState("");
    const filterStakeholders = (e) => {
        if (e?.target?.checked) {
            setStakeholdersArray([...stakeholdersArray, e.target.value])
        } else {
            const data = stakeholdersArray?.filter((item) => item != e?.target?.value)
            setStakeholdersArray(data);
        };
    }
    useEffect(() => {
        const data = stakeholdersArray?.map((item) => `${item}`).join(', ');
        setStakeholdersValues(data)
    }, [stakeholdersArray])

    const getCoursesList = () => {
        setLoading(true);
        const data = {}
        setSearchLocation('');
        setSearchText('');

        data.pageno = pageNumber;
        if (perPage) { data.perpage = perPage }
        if (sortBy) { data.sortby = sortBy }
        if (filterData?.searchtext || keyword) {
            data.text = filterData?.searchtext || keyword;
            setSearchText(filterData?.searchtext || keyword);
        }
        if (filterData?.searchLocation?.length || filterLocation) {
            data.country = filterData?.searchLocation?.map((item) => `${item.value}`).join(', ') || filterLocation
            setSearchLocation(filterData?.searchLocation?.map((item) => `${item.value}`).join(', ') || filterLocation);
        }
        if (datePosted) { data.dateposted = datePosted }
        if (stakeholdersValues) { data.stakeholders = stakeholdersValues }

        const candidateId = CandidateIdGet();
        if (candidateId) { data.candidateId = candidateId }

        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            data: JSON.stringify(data),
            url: `${Apiurl()}/course/list`
        };
        axios(option)
            .then((e) => {
                if (e?.status === 200) {
                    if (e?.data?.length > 0) {
                        setCoursesLength(e?.data[0]?.totalItems);
                        setTotalPages(e?.data[0]?.totalPages)
                        setCourse(e?.data);
                    } else {
                        setCourse([]);
                        setCoursesLength(0);
                        setTotalPages(0);
                    }
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    const handleLocationChange = (selected, event) => {
        if (event.action === 'deselect-option' && event.option.groupItem === '0') {
            selected = selected.filter(x => x.groupItem != event.option.value);
        }
        if (event.action === 'deselect-option' && event.option.groupItem !== '0') {
            selected = selected.filter(x => x.value != event.option.groupItem);
        }
        else if (event.action === 'select-option' && event.option.groupItem === '0') {
            selected = selected.concat(dropdownLocation.filter(x => x.groupItem === event.option.value));
        }
        else if (event.action === 'select-option' && event.option.groupItem !== '0') {
            var selectedcount = selected.filter(x => x.groupItem === event.option.groupItem).length;
            var defaultcount = dropdownLocation.filter(x => x.groupItem === event.option.groupItem).length;
            if (selectedcount === defaultcount) {
                selected = selected.concat(dropdownLocation.filter(x => x.value === event.option.groupItem));
            }
        }
        const result = [];
        const map = new Map();
        for (const item of selected) {
            if (!map.has(item.value)) {
                map.set(item.value, true);    // set any value to Map
                result.push(item);
            }
        }
        setFilterLocationArray(result);
    };

    useEffect(() => {
        const data = filterLocationArray?.map((item) => `${item.value}`).join(', ');
        setFilterLocation(data)
    }, [filterLocationArray])

    const searchButton = () => {
        getCoursesList();
        onFindCourses();
    }

    const pagination = () => {
        let arr = [];
        for (let i = 1; i <= totalPages; i++) {
            arr.push(i);
        }
        setPageArray(arr)
    }
    useEffect(() => {
        pagination();
    }, [totalPages])

    const getperpage = () => {
        let arr = [];
        if (coursesLength <= 10) { return }
        else {
            let len = Math.ceil(coursesLength / 10);
            for (let i = 1; i <= len; i++) {
                arr.push(i);
            }
            setPerpageArray(arr)
            //setPerPage(20)
        }
    }
    useEffect(() => {
        getperpage();
    }, [coursesLength])

    const perPageChange = (e) => {
        setPerPage(Number(e.target.value));
        setPageNumber(1)
        setShowingFrom(1);
        setShowingTo(Number(e.target.value))
        setPerPage(Number(e.target.value));
    }

    const changePage = (e) => {
        if (e <= 0) { return }
        if (e > pageArray?.length) { return }
        setPageNumber(e);
        setShowingFrom((perPage * e) - (perPage - 1));
        setShowingTo((perPage * e))
        if (pageNumber != e) { goToTop() }
    }

    const resetFilters = (key) => {

        if (key === 'keyword') {
            setKeyword('');
            setSearchText('');
            const data = {
                searchtext: "",
                searchLocation: filterLocationArray,
            }
            SetLocalStorage("Coursesfilters", JSON.stringify(data))
        }

    };

    useEffect(() => {
        getCoursesList();
    }, [searchtext, reloadList, pageNumber, perPage, sortBy, datePosted, stakeholdersValues])

    const onFindCourses = () => {
        const data = {
            searchtext: keyword?.trim(),
            searchLocation: filterLocationArray,
        }
        SetLocalStorage("Coursesfilters", JSON.stringify(data))
    }
    useEffect(() => {
        setLoading(true);
        if (filterData) {
            const localsearchText = filterData?.searchtext;
            if (localsearchText) {
                setKeyword(localsearchText);
            }
            const localsearchLocation = filterData?.searchLocation;
            if (localsearchLocation?.length) {
                setFilterLocationArray(localsearchLocation);
            }
        }
    }, [])

    return (
        <div>
            {
                loading ? <Loader /> : null
            }
            <Header setReloadList={setReloadList} loading={loading} />

            <div>
                <div id="jobSearchWidget" className="alternative-section">
                    <Container>
                        <Row>
                            <Col className="col-sm-12">
                                <Form className="job-search">
                                    <Row className="row g-4 align-items-center">
                                        <Col className="field-wrapper col-md-3">
                                            <span className="search-field-icon searchwithclose">
                                                <Input value={keyword} onChange={(e) => setKeyword(e?.target?.value)} id="jobSearchField" name="search" onKeyDown={(e) => handleKeyDown(e)} placeholder="Course Title, Keywords..." type="text" />
                                                {
                                                    searchtext ?
                                                        <a id="filter-keyword" className="search-close" onClick={() => { resetFilters('keyword'); }}>
                                                            <span>x</span></a>
                                                        : null
                                                }
                                            </span>
                                        </Col>

                                        <Col className="field-wrapper col-md-2">
                                            <ReactSelect
                                                ref={selectLocationInputRef}
                                                instanceId="300"
                                                options={dropdownLocation}
                                                isMulti
                                                isSearchable
                                                isClearable
                                                hideSelectedOptions={false}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                placeholder={'Location'}
                                                controlShouldRenderValue={false}
                                                components={{
                                                    Option
                                                }}
                                                className={searchLocation != '' ? 'selected-select-control' : ''}
                                                onChange={handleLocationChange}
                                                allowSelectAll={true}
                                                defaultValue={filterLocationArray}
                                                value={filterLocationArray}
                                            />
                                        </Col>

                                        <Col className="field-wrapper1 col-md-7 d-flex justify-content-md-end align-middle">
                                            <Button onClick={searchButton} className="find-jobs-btn1" title="Find Courses"> Find Courses </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="jobsFilter" className="jobsFilter">
                    <Container>
                        <Row>
                            <Col className="col-12 col-md-7 order-md-first order-last showing-results">
                                {loading ? '' : <p>Showing {coursesLength === 0 ? 0 : showingFrom} – {showingTo < coursesLength ? showingTo : coursesLength} of {coursesLength} courses</p>}
                            </Col>
                            <Col className="col-12 col-md-5 select justify-content-end">
                                <FormGroup>
                                    <Input value={sortBy} onChange={(e) => setSortBy(e?.target?.value)} id="jobSort" className="jobSort" name="jobsort" type="select">
                                        <option disabled value="">Sort by (Date)</option>
                                        <option value="created_desc">Newest</option>
                                        <option value="created_asc">Oldest</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Input value={perPage} onChange={(e) => perPageChange(e)} id="perPage" className="noOfPage perpage-resp ms-4" name="perPage" type="select">
                                        <option value="10">10 per page</option>
                                        <option value="20">20 per page</option>
                                        <option value="30">30 per page</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="jobsList1">
                    <Container>
                        <Row>
                            <Col className="col-12 col-md-3">
                                <div className="sidebar advanced-search">
                                    <div className="date-section pb-4 ps-2">
                                        <h4>Date Posted</h4>
                                        {
                                            dropdownDatePosted?.map((item, i) => (
                                                <FormGroup check key={i}>
                                                    <Label check>
                                                        <input id="Date-posted" value={datePosted} onChange={(e) => setDatePosted(item?.value)} type="radio" name="date" className="date-radio" defaultChecked={item?.value === "All"} />
                                                        {item?.text}</Label>
                                                </FormGroup>
                                            ))
                                        }
                                    </div>
                                    <div className="salary-offer courses-stakeholder">
                                        <h4 className="expand-title" id="stake-holder">Trainer </h4>
                                        <UncontrolledCollapse className="salaryection pt-3 pb-3 " toggler="#stake-holder">
                                            {
                                                dropdownStakeholders.map((item, i) => (
                                                    <FormGroup check className="box-checked" key={i}>

                                                        <Label check className="label-input-checker"> <span className="filter-check-label"><Input type="checkbox" onChange={(e) => filterStakeholders(e)} value={item?.value} />
                                                            {item?.text}</span><span>({item?.count})</span></Label>
                                                    </FormGroup>
                                                ))
                                            }
                                        </UncontrolledCollapse>
                                    </div>

                                    <div className="courses-section">
                                        <h4>Popular Courses</h4>

                                        {
                                            popularCourses?.map((item, i) => (
                                                <div className={(item?.applied === true) ? "background-grey coursesleft" : "even coursesleft"}>
                                                    <NavLink to={item?.url} key={i} title={item?.title}>

                                                        {
                                                            item?.company?.imageUrl ? <img title={item?.company?.shortName} src={item?.company?.imageUrl || "assets/images/logos/dummy-logo.webp"} className="d-block img-fluid" alt="Popular courses" loading="lazy" /> :
                                                                <span className="image-initials-popular"><span title={item?.company?.shortName}>{item?.company?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{item?.company?.shortName?.split(' ').length > 1 ? item?.company?.shortName?.split(' ')[item?.company?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</span></span>
                                                        }

                                                        <div className="marginleft80">
                                                            <p className="title">{item?.title}</p>
                                                            <p className="desc"><strong>Location: </strong>{item?.countryDescription}, {item?.city}</p>
                                                            <p className="desc">{item?.description}</p>
                                                            {item?.applied === true ? <div className="desc">
                                                                <p>You enrolled for this course on {moment(item?.courseAttendeeEnrolledDate).format("DD MMM YYYY")}</p></div> : null}
                                                        </div>

                                                    </NavLink>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="courses-section recent-courses">
                                        <h4>Recent Courses</h4>
                                        {

                                            recentCourses?.map((item, i) => (
                                                <div className={(item?.applied === true) ? "background-grey coursesleft" : "even coursesleft"}>
                                                    <NavLink to={item?.url} key={i} title={item?.title} className={(item?.applied === true) ? "background-grey" : "even"}>

                                                        {
                                                            item?.company?.imageUrl ? <img title={item?.company?.shortName} src={item?.company?.imageUrl || "assets/images/logos/dummy-logo.webp"} className="d-block img-fluid" alt="Popular courses" loading="lazy" /> :
                                                                <span className="image-initials-popular"><span title={item?.company?.shortName}>{item?.company?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{item?.company?.shortName?.split(' ').length > 1 ? item?.company?.shortName?.split(' ')[item?.company?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</span></span>
                                                        }
                                                        <div className="marginleft80">
                                                            <p className="title">{item?.title}</p>
                                                            <p className="desc"><strong>Location: </strong>{item?.countryDescription}, {item?.city}</p>
                                                            <p className="desc">{item?.description}</p>
                                                            {item?.applied === true ? <div className="desc">
                                                                <p>You enrolled for this course on {moment(item?.courseAttendeeEnrolledDate).format("DD MMM YYYY")}</p></div> : null}

                                                        </div>
                                                    </NavLink>
                                                </div>


                                            ))
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-12 col-md-9 course">
                                <CardColumns>
                                    {
                                        course?.map((item, i) => (
                                            <NavLink to={item?.url} className="cardDiv" key={i} title={item?.title}>
                                                <Card className={(item?.applied === true) ? "background-grey" : "even"}>
                                                    <CardBody title={item?.title}>
                                                        <Row>
                                                            <Col className="col-12 col-sm-3">
                                                                {
                                                                    item?.company?.imageUrl ? <CardImg alt="Card image cap" title={item?.company?.shortName} src={item?.company?.imageUrl || "assets/images/logos/dummy-logo.webp"} width="250" /> :
                                                                        <div className="image-initials" title={item?.company?.shortName}>{item?.company?.shortName?.split(' ')[0].charAt(0).toUpperCase()}{item?.company?.shortName?.split(' ').length > 1 ? item?.company?.shortName?.split(' ')[item?.company?.shortName?.split(' ').length - 1].charAt(0).toUpperCase() : null}</div>
                                                                }
                                                            </Col>
                                                            <Col className="col-12 col-sm-9">
                                                                <CardTitle tag="h3">{item?.title}
                                                                    <div className="badges-wrapper badges-wrapper-courses">
                                                                        {/*<span className="badge align-middle paid">Paid</span>*/}
                                                                    </div>
                                                                </CardTitle>
                                                                <div className="card-text courses-card-text">
                                                                    <div className="icons job-location listing"><i className="fa-solid fa-location-crosshairs"></i><strong>Location: </strong>{item?.countryDescription}, {item?.city}</div>
                                                                    <div className="icons job-salary listing"><i className="fa-solid fa-money-bills"></i><strong>Fee: </strong>${item?.fee}</div>
                                                                    <div className="icons job-date-added listing"><i className="fa-solid fa-clock"></i><strong>Posted: </strong>{moment(item?.created).format("DD MMM YYYY")}</div><br />
                                                                    <div className="icons job-date-added listing"><i className="fa-solid fa-clock"></i><strong>Start Date: </strong>{moment(item?.startDate).format("DD MMM YYYY")}</div>
                                                                    <div className="icons job-date-added listing"><i className="fa-solid fa-clock"></i><strong>End Date: </strong>{moment(item?.endDate).format("DD MMM YYYY")}</div>
                                                                    <div className="job-desc">
                                                                        <p>{item?.description}</p>
                                                                    </div>
                                                                </div>

                                                                {item?.applied === true ? <div className="job-desc">
                                                                    <p>You enrolled for this course on {moment(item?.courseAttendeeEnrolledDate).format("DD MMM YYYY")}</p></div> : null}

                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </NavLink>
                                        ))
                                    }


                                </CardColumns>
                                <div className="outerdiv">
                                    {
                                        coursesLength == 0 ?
                                            (!loading ? <div className="nodata">There are no courses matching the search filters</div> : "")
                                            : null
                                    }
                                    {
                                        coursesLength > 0 ? (<p>Showing {coursesLength === 0 ? 0 : showingFrom} – {showingTo < coursesLength ? showingTo : coursesLength} of {coursesLength} courses</p>)
                                            : ""
                                    }
                                    {
                                        pageArray?.length ?
                                            <Pagination className="mt-2 mb-5">
                                                <PaginationItem>
                                                    <PaginationLink className={pageNumber === 1 ? "next-prev disabled" : "next-prev"} onClick={() => changePage(pageNumber - 1)}>
                                                        <span className="fa fa-angle-left"></span> Previous
                                                    </PaginationLink>
                                                </PaginationItem>
                                                {
                                                    pageArray?.map((item, i) => (
                                                        <PaginationItem key={i}>
                                                            <PaginationLink className={pageNumber === item ? "active" : ""} onClick={() => changePage(item)}>{item}</PaginationLink>
                                                        </PaginationItem>
                                                    ))
                                                }
                                                <PaginationItem>
                                                    <PaginationLink className={pageNumber === totalPages ? "next-prev disabled" : "next-prev"} onClick={() => changePage(pageNumber + 1)} >
                                                        Next <span className="fa fa-angle-right"></span>
                                                    </PaginationLink>
                                                </PaginationItem>
                                            </Pagination>
                                            : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer loading={loading} />
        </div>
    );
}

export default Courses;
